.enquete {
	$this: &;
	$gutter-h: 25px;
	$gutter-v: 15px;
	$gutter-hMin: 8px;
	$gutter-vMin: 8px;

	margin: 20px 0 0;
	img {
		max-width: 100%;
	}
	[type="text"],
	[type="password"],
	[type="email"],
	select {
		width: 100%;
	}
	&_table {
		table-layout: fixed;
		& > thead,
		& > tbody {
			& > tr {
				& > th,
				& > td {
					text-align: left;
					vertical-align: middle;
				}
			}
		}
		th,
		td {
		}
		td {
			& > :first-child {
				margin-top: 0;
			}
			& > #{$this}_grid:first-child {
				margin-top: $gutter-v*-1;
			}
			& > #{$this}_grid-borderDot:first-child {
				margin-top: $gutter-v*-1;
				&:before {
					content: none;
				}
			}
		}
		th {
			& > span {
				font-weight: normal;
				font-size: .88em;
			}
		}
	}
	&_must {
		padding-left: calc(34px + .75rem + 10px) !important;
		text-indent: calc(-34px - .75rem);
		&:before {
			text-indent: 0;
			content: '必須';
			display: inline-block;
			vertical-align: .1em;
			letter-spacing: normal;
			margin-right: 1em;
			font-weight: normal;
			background: $red;
			font-size: 12px;
			color: #fff;
			line-height: 1;
			padding: 4px 5px;
		}
	}
	&_grid {
		display: flex;
		display: -webkit-flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		margin: 0 0 0 $gutter-h*-1;
		&-v {
			display: block;
			overflow: hidden;
		}
		&-vat {
			align-items: flex-start;
		}
		&-borderDot {
			margin-top: 10px;
			position: relative;
			&:before {
				content: '';
				display: block;
				// width: 100%;
				height: 1px;
				border-top: 1px dotted $gray-border;
				position: absolute;
				top: 0;
				left: $gutter-h;
				right: 0;
			}
		}
	}
	&_col {
		margin: $gutter-v 0 0 $gutter-h;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		line-height: 1;
		& > :first-child {
			margin-top: 0;
		}
		& > * {
			line-height: normal;
			& + * {
				margin-left: $gutter-hMin;
			}
		}
		& > span {
			white-space: nowrap;
		}
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				width: calc(100%/12 * #{$i} - #{$gutter-h});
			}
		}
		&-btn {
			align-self: stretch;
		}
	}
	&_note {
		margin: $gutter-vMin 0 0;
		line-height: normal;
	}
	&_error {
		margin: $gutter-vMin 0 0;
		line-height: normal;
		color: #a00;
	}

	/*  enquete_btn
	----------------------------------------------------------------------*/
	&_btn {
		display: flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		text-align: center;
		text-decoration: none;
		color: #fff;
		border-radius: 4px;
		background: $gray;
		padding: 0 20px;
		font-size: 16px;
	}

	/*  enquete_otherInput
	----------------------------------------------------------------------*/
	&_otherInput {
		text-indent: 0;
		display: inline-block;
		vertical-align: middle;
		letter-spacing: normal;
		margin-left: $gutter-hMin;
		vertical-align: 0;
	}

	/* enquete _imgCheck
	----------------------------------------------------------------------*/
	&_imgCheck {
		text-align: center;
		width: 150px;
		display: flex;
		display: -webkit-flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		flex-direction: row;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_imgCheckName {
		margin: 5px 0 0;
		width: 100%;
		flex-grow: 0;
		flex-shrink: 0;
	}
	&_imgCheckInner {
		margin: 5px auto 0;
		display: flex;
		display: -webkit-flex;
	}
	&_imgCheckSide {
		flex-grow: 0;
		flex-shrink: 0;
		margin: 5px 0 0;
		width: 150px;
		text-align: center;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_imgCheckImg {
		margin: 5px auto 0;
		width: 100%;
		& > img {
			max-height: 150px;
		}
	}
	&_imgCheckBody {
		width: calc(100% - 150px - #{$gutter-hMin});
		flex-grow: 0;
		flex-shrink: 0;
		margin: 5px 0 0 $gutter-hMin;
		align-self: center;
		& > :first-child {
			margin-top: 0;
		}
	}

	&_grid-v {
		#{$this}_imgCheck {
			width: auto;
			text-align: left;
		}
		#{$this}_imgCheckInner {
			margin: 5px 0 0;
			display: flex;
			display: -webkit-flex;
		}
	}

	/*  &_fileForm
	----------------------------------------------------------------------*/
	&_fileForm {
		& > :first-child {
			margin-top: 0;
		}
	}

	&_fileFormBtn {
		margin: 0;
		& > a {
			display: flex;
			display: -webkit-flex;
			justify-content: center;
			align-items: center;
			border-style: solid;
			border-width: 1px;
			border-color: rgb(229, 229, 229);
			border-radius: 5px;
			background-image: -moz-linear-gradient( 0, rgb(238,238,238) 0%, rgb(255,255,255) 100%);
			background-image: -webkit-linear-gradient( 0, rgb(238,238,238) 0%, rgb(255,255,255) 100%);
			background-image: -ms-linear-gradient( 0, rgb(238,238,238) 0%, rgb(255,255,255) 100%);
			background-image: linear-gradient( 0, rgb(238,238,238) 0%, rgb(255,255,255) 100%);
			width: 184px;
			height: 40px;
			text-decoration: none;
			color: inherit;
			font-weight: bold;
			font-size: .93em;
		}
	}

	&_fileFormList {
		margin: 0;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_fileFormItem {
		margin: 10px 0 0;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		font-weight: bold;
		position: relative;
		overflow: hidden;
		word-break: break-all;
		[type="file"] {
			position: absolute;
			top: -100%;
			opacity: 0;
		}
		&-template {
			display: none;
		}
	}
	&_fileFormName {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-decoration: none;
		color: inherit;
	}
	&_fileFormDel {
		flex-grow: 0;
		flex-shrink: 0;
		display: block;
		text-decoration: none;
		color: #fff;
		text-align: center;
		width: 27px;
		height: 27px;
		line-height: 27px;
		font-size: 24px;
		background: $gray;
		margin-right: 8px;
		// align-self: flex-start;
		font-weight: normal;
	}
	&_fileFormComment {
		margin: 0 0 0 8px;
		flex-grow: 0;
		flex-shrink: 0;
		width: 440px;
	}


}

@media screen and (min-width: $breakpoint-sp + 1){
	.enquete {
		$this: &;
		$gutter-h: 15px;
		$gutter-v: 15px;
		$gutter-hMin: 8px;
		$gutter-vMin: 8px;
		&_col {
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					width: calc(100%/12 * #{$i} - #{$gutter-h});
				}
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.enquete {
		$this: &;
		$gutter-h: 10px;
		$gutter-v: 18px;
		$gutter-hMin: 8px;
		$gutter-vMin: 8px;
		&_table {
			display: block;
			border: none;
			& > thead,
			& > tbody {
				display: block;
				& > :first-child {
					margin-top: 0;
				}
				& > tr {
					& > :first-child {
						margin-top: 0;
					}
					display: block;
					margin: 20px 0 0;
					& > th,
					& > td {
						display: block;
						border: none;
						padding: 0;
					}
					& > th {
						margin: 20px 0 0;
						padding: 5px;
					}
					& > td {
						margin: 10px 0 0;
						& > :first-child {
							margin-top: 0;
						}
						& > #{$this}_grid:first-child {
							margin-top: $gutter-v*-1;
						}
					}
				}
			}
		}
		&_must {
		}
		&_grid {
			margin: $gutter-v*-1+$gutter-vMin 0 0 $gutter-h*-1;
			&-borderDot {
				margin-top: 10px;
			}
		}
		&_col {
			margin: $gutter-v 0 0 $gutter-h;
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					width: calc(100%/12 * #{$i} - #{$gutter-h});
				}
			}
		}
		&_note {
			margin: $gutter-vMin 0 0;
		}
		&_error {
			margin: $gutter-vMin 0 0;
		}

		/* enquete _imgCheck
		----------------------------------------------------------------------*/
		&_imgCheck {
			width: 100px;
		}
		&_imgCheckName {
		}
		&_imgCheckInner {
		}
		&_imgCheckSide {
			margin: 10px auto 0;
			width: 100px;
		}
		&_imgCheckImg {
			& > img {
				max-height: 100px;
			}
		}
		&_imgCheckBody {
			margin: 5px 0 0;
			width: calc(100% - 100px - #{$gutter-hMin});
		}

		&_grid-v {
			#{$this}_imgCheck {
			}
			#{$this}_imgCheckName {
				text-align: left;
			}
			#{$this}_imgCheckInner {
				display: block;
			}
		}

		/* &_fileForm
		----------------------------------------------------------------------*/
		&_fileForm {
			$this: &;
		}
		&_fileFormBtn {
		}
		&_fileFormList {
		}
		&_fileFormItem {
			flex-wrap: wrap;
			-webkit-flex-wrap: wrap;
			flex-direction: row;
			&-template {
			}
			& > span {
				width: calc(100% - 35px);
			}
		}
		&_fileFormDel {
		}
		&_fileFormComment {
			margin: 5px 0 0;
			width: 100%;
		}
	}
}
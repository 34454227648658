// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon-arrow-next-name: 'icon_arrow-next';
$icon-arrow-next-x: 266px;
$icon-arrow-next-y: 50px;
$icon-arrow-next-offset-x: -266px;
$icon-arrow-next-offset-y: -50px;
$icon-arrow-next-width: 26px;
$icon-arrow-next-height: 40px;
$icon-arrow-next-total-width: 310px;
$icon-arrow-next-total-height: 248px;
$icon-arrow-next-image: '../images/sprite.png';
$icon-arrow-next: (266px, 50px, -266px, -50px, 26px, 40px, 310px, 248px, '../images/sprite.png', 'icon_arrow-next', );
$icon-arrow-prev-name: 'icon_arrow-prev';
$icon-arrow-prev-x: 266px;
$icon-arrow-prev-y: 100px;
$icon-arrow-prev-offset-x: -266px;
$icon-arrow-prev-offset-y: -100px;
$icon-arrow-prev-width: 26px;
$icon-arrow-prev-height: 40px;
$icon-arrow-prev-total-width: 310px;
$icon-arrow-prev-total-height: 248px;
$icon-arrow-prev-image: '../images/sprite.png';
$icon-arrow-prev: (266px, 100px, -266px, -100px, 26px, 40px, 310px, 248px, '../images/sprite.png', 'icon_arrow-prev', );
$icon-arrow-name: 'icon_arrow';
$icon-arrow-x: 302px;
$icon-arrow-y: 50px;
$icon-arrow-offset-x: -302px;
$icon-arrow-offset-y: -50px;
$icon-arrow-width: 8px;
$icon-arrow-height: 8px;
$icon-arrow-total-width: 310px;
$icon-arrow-total-height: 248px;
$icon-arrow-image: '../images/sprite.png';
$icon-arrow: (302px, 50px, -302px, -50px, 8px, 8px, 310px, 248px, '../images/sprite.png', 'icon_arrow', );
$icon-blank-name: 'icon_blank';
$icon-blank-x: 294px;
$icon-blank-y: 214px;
$icon-blank-offset-x: -294px;
$icon-blank-offset-y: -214px;
$icon-blank-width: 14px;
$icon-blank-height: 14px;
$icon-blank-total-width: 310px;
$icon-blank-total-height: 248px;
$icon-blank-image: '../images/sprite.png';
$icon-blank: (294px, 214px, -294px, -214px, 14px, 14px, 310px, 248px, '../images/sprite.png', 'icon_blank', );
$icon-blockquote-left-name: 'icon_blockquote-left';
$icon-blockquote-left-x: 136px;
$icon-blockquote-left-y: 0px;
$icon-blockquote-left-offset-x: -136px;
$icon-blockquote-left-offset-y: 0px;
$icon-blockquote-left-width: 54px;
$icon-blockquote-left-height: 48px;
$icon-blockquote-left-total-width: 310px;
$icon-blockquote-left-total-height: 248px;
$icon-blockquote-left-image: '../images/sprite.png';
$icon-blockquote-left: (136px, 0px, -136px, 0px, 54px, 48px, 310px, 248px, '../images/sprite.png', 'icon_blockquote-left', );
$icon-blockquote-right-name: 'icon_blockquote-right';
$icon-blockquote-right-x: 136px;
$icon-blockquote-right-y: 58px;
$icon-blockquote-right-offset-x: -136px;
$icon-blockquote-right-offset-y: -58px;
$icon-blockquote-right-width: 54px;
$icon-blockquote-right-height: 48px;
$icon-blockquote-right-total-width: 310px;
$icon-blockquote-right-total-height: 248px;
$icon-blockquote-right-image: '../images/sprite.png';
$icon-blockquote-right: (136px, 58px, -136px, -58px, 54px, 48px, 310px, 248px, '../images/sprite.png', 'icon_blockquote-right', );
$icon-calendar-name: 'icon_calendar';
$icon-calendar-x: 266px;
$icon-calendar-y: 182px;
$icon-calendar-offset-x: -266px;
$icon-calendar-offset-y: -182px;
$icon-calendar-width: 22px;
$icon-calendar-height: 22px;
$icon-calendar-total-width: 310px;
$icon-calendar-total-height: 248px;
$icon-calendar-image: '../images/sprite.png';
$icon-calendar: (266px, 182px, -266px, -182px, 22px, 22px, 310px, 248px, '../images/sprite.png', 'icon_calendar', );
$icon-chat-name: 'icon_chat';
$icon-chat-x: 200px;
$icon-chat-y: 56px;
$icon-chat-offset-x: -200px;
$icon-chat-offset-y: -56px;
$icon-chat-width: 48px;
$icon-chat-height: 48px;
$icon-chat-total-width: 310px;
$icon-chat-total-height: 248px;
$icon-chat-image: '../images/sprite.png';
$icon-chat: (200px, 56px, -200px, -56px, 48px, 48px, 310px, 248px, '../images/sprite.png', 'icon_chat', );
$icon-checkbox-off-name: 'icon_checkbox-off';
$icon-checkbox-off-x: 170px;
$icon-checkbox-off-y: 146px;
$icon-checkbox-off-offset-x: -170px;
$icon-checkbox-off-offset-y: -146px;
$icon-checkbox-off-width: 44px;
$icon-checkbox-off-height: 44px;
$icon-checkbox-off-total-width: 310px;
$icon-checkbox-off-total-height: 248px;
$icon-checkbox-off-image: '../images/sprite.png';
$icon-checkbox-off: (170px, 146px, -170px, -146px, 44px, 44px, 310px, 248px, '../images/sprite.png', 'icon_checkbox-off', );
$icon-checkbox-on-name: 'icon_checkbox-on';
$icon-checkbox-on-x: 0px;
$icon-checkbox-on-y: 204px;
$icon-checkbox-on-offset-x: 0px;
$icon-checkbox-on-offset-y: -204px;
$icon-checkbox-on-width: 44px;
$icon-checkbox-on-height: 44px;
$icon-checkbox-on-total-width: 310px;
$icon-checkbox-on-total-height: 248px;
$icon-checkbox-on-image: '../images/sprite.png';
$icon-checkbox-on: (0px, 204px, 0px, -204px, 44px, 44px, 310px, 248px, '../images/sprite.png', 'icon_checkbox-on', );
$icon-edit-name: 'icon_edit';
$icon-edit-x: 0px;
$icon-edit-y: 146px;
$icon-edit-offset-x: 0px;
$icon-edit-offset-y: -146px;
$icon-edit-width: 48px;
$icon-edit-height: 48px;
$icon-edit-total-width: 310px;
$icon-edit-total-height: 248px;
$icon-edit-image: '../images/sprite.png';
$icon-edit: (0px, 146px, 0px, -146px, 48px, 48px, 310px, 248px, '../images/sprite.png', 'icon_edit', );
$icon-home-name: 'icon_home';
$icon-home-x: 266px;
$icon-home-y: 214px;
$icon-home-offset-x: -266px;
$icon-home-offset-y: -214px;
$icon-home-width: 18px;
$icon-home-height: 18px;
$icon-home-total-width: 310px;
$icon-home-total-height: 248px;
$icon-home-image: '../images/sprite.png';
$icon-home: (266px, 214px, -266px, -214px, 18px, 18px, 310px, 248px, '../images/sprite.png', 'icon_home', );
$icon-mail-name: 'icon_mail';
$icon-mail-x: 266px;
$icon-mail-y: 150px;
$icon-mail-offset-x: -266px;
$icon-mail-offset-y: -150px;
$icon-mail-width: 28px;
$icon-mail-height: 22px;
$icon-mail-total-width: 310px;
$icon-mail-total-height: 248px;
$icon-mail-image: '../images/sprite.png';
$icon-mail: (266px, 150px, -266px, -150px, 28px, 22px, 310px, 248px, '../images/sprite.png', 'icon_mail', );
$icon-mail02-name: 'icon_mail02';
$icon-mail02-x: 68px;
$icon-mail02-y: 0px;
$icon-mail02-offset-x: -68px;
$icon-mail02-offset-y: 0px;
$icon-mail02-width: 58px;
$icon-mail02-height: 48px;
$icon-mail02-total-width: 310px;
$icon-mail02-total-height: 248px;
$icon-mail02-image: '../images/sprite.png';
$icon-mail02: (68px, 0px, -68px, 0px, 58px, 48px, 310px, 248px, '../images/sprite.png', 'icon_mail02', );
$icon-pencil-name: 'icon_pencil';
$icon-pencil-x: 0px;
$icon-pencil-y: 0px;
$icon-pencil-offset-x: 0px;
$icon-pencil-offset-y: 0px;
$icon-pencil-width: 58px;
$icon-pencil-height: 58px;
$icon-pencil-total-width: 310px;
$icon-pencil-total-height: 248px;
$icon-pencil-image: '../images/sprite.png';
$icon-pencil: (0px, 0px, 0px, 0px, 58px, 58px, 310px, 248px, '../images/sprite.png', 'icon_pencil', );
$icon-point01-name: 'icon_point01';
$icon-point01-x: 54px;
$icon-point01-y: 204px;
$icon-point01-offset-x: -54px;
$icon-point01-offset-y: -204px;
$icon-point01-width: 44px;
$icon-point01-height: 44px;
$icon-point01-total-width: 310px;
$icon-point01-total-height: 248px;
$icon-point01-image: '../images/sprite.png';
$icon-point01: (54px, 204px, -54px, -204px, 44px, 44px, 310px, 248px, '../images/sprite.png', 'icon_point01', );
$icon-point02-name: 'icon_point02';
$icon-point02-x: 266px;
$icon-point02-y: 0px;
$icon-point02-offset-x: -266px;
$icon-point02-offset-y: 0px;
$icon-point02-width: 44px;
$icon-point02-height: 40px;
$icon-point02-total-width: 310px;
$icon-point02-total-height: 248px;
$icon-point02-image: '../images/sprite.png';
$icon-point02: (266px, 0px, -266px, 0px, 44px, 40px, 310px, 248px, '../images/sprite.png', 'icon_point02', );
$icon-point03-name: 'icon_point03';
$icon-point03-x: 200px;
$icon-point03-y: 0px;
$icon-point03-offset-x: -200px;
$icon-point03-offset-y: 0px;
$icon-point03-width: 56px;
$icon-point03-height: 46px;
$icon-point03-total-width: 310px;
$icon-point03-total-height: 248px;
$icon-point03-image: '../images/sprite.png';
$icon-point03: (200px, 0px, -200px, 0px, 56px, 46px, 310px, 248px, '../images/sprite.png', 'icon_point03', );
$icon-radio-off-name: 'icon_radio-off';
$icon-radio-off-x: 108px;
$icon-radio-off-y: 204px;
$icon-radio-off-offset-x: -108px;
$icon-radio-off-offset-y: -204px;
$icon-radio-off-width: 44px;
$icon-radio-off-height: 44px;
$icon-radio-off-total-width: 310px;
$icon-radio-off-total-height: 248px;
$icon-radio-off-image: '../images/sprite.png';
$icon-radio-off: (108px, 204px, -108px, -204px, 44px, 44px, 310px, 248px, '../images/sprite.png', 'icon_radio-off', );
$icon-radio-on-name: 'icon_radio-on';
$icon-radio-on-x: 162px;
$icon-radio-on-y: 204px;
$icon-radio-on-offset-x: -162px;
$icon-radio-on-offset-y: -204px;
$icon-radio-on-width: 44px;
$icon-radio-on-height: 44px;
$icon-radio-on-total-width: 310px;
$icon-radio-on-total-height: 248px;
$icon-radio-on-image: '../images/sprite.png';
$icon-radio-on: (162px, 204px, -162px, -204px, 44px, 44px, 310px, 248px, '../images/sprite.png', 'icon_radio-on', );
$icon-search-name: 'icon_search';
$icon-search-x: 200px;
$icon-search-y: 114px;
$icon-search-offset-x: -200px;
$icon-search-offset-y: -114px;
$icon-search-width: 18px;
$icon-search-height: 18px;
$icon-search-total-width: 310px;
$icon-search-total-height: 248px;
$icon-search-image: '../images/sprite.png';
$icon-search: (200px, 114px, -200px, -114px, 18px, 18px, 310px, 248px, '../images/sprite.png', 'icon_search', );
$icon-setting-name: 'icon_setting';
$icon-setting-x: 58px;
$icon-setting-y: 146px;
$icon-setting-offset-x: -58px;
$icon-setting-offset-y: -146px;
$icon-setting-width: 48px;
$icon-setting-height: 48px;
$icon-setting-total-width: 310px;
$icon-setting-total-height: 248px;
$icon-setting-image: '../images/sprite.png';
$icon-setting: (58px, 146px, -58px, -146px, 48px, 48px, 310px, 248px, '../images/sprite.png', 'icon_setting', );
$icon-slickarrow-next-name: 'icon_slickarrow-next';
$icon-slickarrow-next-x: 0px;
$icon-slickarrow-next-y: 68px;
$icon-slickarrow-next-offset-x: 0px;
$icon-slickarrow-next-offset-y: -68px;
$icon-slickarrow-next-width: 40px;
$icon-slickarrow-next-height: 68px;
$icon-slickarrow-next-total-width: 310px;
$icon-slickarrow-next-total-height: 248px;
$icon-slickarrow-next-image: '../images/sprite.png';
$icon-slickarrow-next: (0px, 68px, 0px, -68px, 40px, 68px, 310px, 248px, '../images/sprite.png', 'icon_slickarrow-next', );
$icon-slickarrow-prev-name: 'icon_slickarrow-prev';
$icon-slickarrow-prev-x: 50px;
$icon-slickarrow-prev-y: 68px;
$icon-slickarrow-prev-offset-x: -50px;
$icon-slickarrow-prev-offset-y: -68px;
$icon-slickarrow-prev-width: 40px;
$icon-slickarrow-prev-height: 68px;
$icon-slickarrow-prev-total-width: 310px;
$icon-slickarrow-prev-total-height: 248px;
$icon-slickarrow-prev-image: '../images/sprite.png';
$icon-slickarrow-prev: (50px, 68px, -50px, -68px, 40px, 68px, 310px, 248px, '../images/sprite.png', 'icon_slickarrow-prev', );
$icon-tel-name: 'icon_tel';
$icon-tel-x: 116px;
$icon-tel-y: 146px;
$icon-tel-offset-x: -116px;
$icon-tel-offset-y: -146px;
$icon-tel-width: 44px;
$icon-tel-height: 48px;
$icon-tel-total-width: 310px;
$icon-tel-total-height: 248px;
$icon-tel-image: '../images/sprite.png';
$icon-tel: (116px, 146px, -116px, -146px, 44px, 48px, 310px, 248px, '../images/sprite.png', 'icon_tel', );
$spritesheet-width: 310px;
$spritesheet-height: 248px;
$spritesheet-image: '../images/sprite.png';
$spritesheet-sprites: ($icon-arrow-next, $icon-arrow-prev, $icon-arrow, $icon-blank, $icon-blockquote-left, $icon-blockquote-right, $icon-calendar, $icon-chat, $icon-checkbox-off, $icon-checkbox-on, $icon-edit, $icon-home, $icon-mail, $icon-mail02, $icon-pencil, $icon-point01, $icon-point02, $icon-point03, $icon-radio-off, $icon-radio-on, $icon-search, $icon-setting, $icon-slickarrow-next, $icon-slickarrow-prev, $icon-tel, );
$spritesheet: (310px, 248px, '../images/sprite.png', $spritesheet-sprites, );



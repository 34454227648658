@charset "UTF-8";
@import '_config.scss';

/*
article entry関連

article entry関連

Styleguide 8.
*/

/*
entry 大枠

.entry

Markup:
<div class="entry">
</div><!-- /.entry -->

Styleguide 8.1
============================================================ */
.entry {
	$this: &;
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
entry用 : ヘッダ

.entryHeader

Markup:
<header class="entryHeader">
	<div class="entryHeader_headLine">
		<p class="entryHeader_tags">
			<span class="tag tag-category" style="background: #244fa3; color: #fff;">特集記事</span>
			<span class="tag tag-category" style="background: #6d96d6; color: #fff;">カテゴリ</span>
			<span class="tag tag-category" style="background: #76c1e4; color: #fff;">〇〇〇〇</span>
		</p><!-- /.entryHeader_tags -->
		<h2 class="entryHeader_title"><%= meta.pageTitle %></h2>
		<div class="entryHeader_metas">
			<p class="entryHeader_dates"><time class="date">2020.00.00</time></p>
			<p class="entryHeader_tags entryHeader_tags-ads">
				<span class="tag tag-ad">広告</span>
			</p><!-- /.entryHeader_tags -->
		</div>
	</div><!-- /.entryHeader_headLine -->
</header><!-- /.entryHeader -->

Styleguide 8.2.1
============================================================ */
.entryHeader {
	$this: &;
	$gutter-h: 20px;

	$main-maxRatioWidth: 960;
	$main-maxRatioHeight: 400;
	$main-imgAspectRatio: calc( #{$main-maxRatioHeight} / #{$main-maxRatioWidth} * 100% ); // アスペクト比

	// _headLine area
	&_headLine {
		padding: 14px 20px 20px;
		border-top: 2px solid $baseColor;
		border-bottom: 2px solid $baseColor;
	}
	&_title {
		color: $baseColor;
		@include fs(24);
		font-weight: bold;
		line-height: 1.6;
		&:not(:first-child) {
			margin-top: 10px;
		}
	}
	&_sub {
		margin-top: 10px;
		@include fs(14);
		line-height: 1.3;
	}

	// _metas area
	&_metas {
		display: -webkit-flex;
		display: flex;
		align-items: center;
		gap: 5px 1em;
		margin: 0;
		&:not(:first-child) {
			margin-top: 10px;
		}
	}

	// _tags area
	&_tags {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 5px;
		margin: 0;
		.tag {
			min-width: 140px;
		}
		&-ads {
			.tag {
				min-width: 0;
			}
		}
	}

	&_dates ,
	&_duration {
		flex: 0 0 auto;
		@include fs(14);
		line-height: 1.25;
	}
	&_dates {

		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 5px;

		margin: 0;
		color: $gray;
		@include ff-Montserrat;
		font-weight: bold;
		white-space: nowrap;
	}
	&_duration {
	}

	// _hero area
	&_hero {
		margin-top: 25px;
	}
	// _figure area
	&_figure {
		@include aspectRatio(960, 400, contain);
		text-decoration: none;
		@include hoverImgStyle01;
		@include boxShadow01;
		// img {
		// 	@include transitionType02;
		// }
		&-noimage {
			@include noImageStyle;
		}
	}
	// &_figure {
	// 	position: relative;
	// 	display: block;
	// 	margin: 0 auto;
	// 	padding-top: #{$main-imgAspectRatio};
	// 	width: 100%;
	// 	max-width: #{$main-maxRatioWidth}px;
	// 	max-height: #{$main-maxRatioHeight}px;
	// 	overflow: hidden;
	// 	text-align: center;
	// 	// border: 1px solid $gray-border;
	// 	// background-color: $gray-bg;
	// 	-webkit-backface-visibility: hidden;
	// 			backface-visibility: hidden;
	// }
	// &_img {
	// 	position: absolute;
	// 	top: 0;
	// 	bottom: 0;
	// 	right: 0;
	// 	left: 0;
	// 	margin: auto;
	// 	max-width: 102%;
	// 	max-height: 102%;
	// 	// width: 100%; // 「 cover 」時設定追加
	// 	// height: 100%; // 「 cover 」時設定追加
	// 	object-fit: contain;
	// 	font-family: 'object-fit: contain;';
	// 	-webkit-transition: .3s;
	// 			transition: .3s;
	// }

	// _movie area
	&_movie {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		width: 100%;
		height: 0;
	}
	&_movieFrame {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	// _lower area
	&_lower {
		padding: 12px 0 6px;
		& > * {
			margin: 0;
			&:not(:first-child) {
				margin-left: 1em
			}
		}
	}
	&_lowerUnit {
		&-tag {
		}
		&-sns {
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.entryHeader {
		$this: &;
		$gutter-h: 20px;

		$main-maxRatioWidth: 960;
		$main-maxRatioHeight: 400;
		$main-imgAspectRatio: calc( #{$main-maxRatioHeight} / #{$main-maxRatioWidth} * 100% ); // アスペクト比

		// _headLine area
		&_headLine {
			padding: 10px 5px 14px;
		}
		&_title {
			@include fs(20);
			line-height: 1.4;
		}
		&_sub {
			@include fs(14);
		}

		// _tags area
		&_tags {
			.tag {
				min-width: 90px;
			}
			&-ads {
				.tag {
					min-width: 0;
				}
			}
		}

		&_dates ,
		&_duration {
			@include fs(13);
		}

		// _hero area
		&_hero {
			margin-top: 20px;
		}

		// _lower area
		&_lower {
			margin-top: 10px;
		}
		&_lowerUnit {
			&-tag {
			}
			&-sns {
			}
		}
	}
}

/*
entry用 : entrySns

entrySns

Markup:

Styleguide 8.2.2
============================================================ */
.entrySns {
	$this: &;
	$gutter-h: 7px;

	text-align: right;
	&_list {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: flex-end;
				justify-content: flex-end;
		-webkit-align-items: center;
				align-items: center;
		-webkit-flex-wrap: wrap;
				flex-wrap: wrap;

		margin: 0;
		list-style-type: none;
		& > * {
			margin: 0;
			&:not(:first-child) {
				margin-left: #{$gutter-h};
			}
		}
	}
	&_item {
		// @include inline_block;
		// margin: 0 0 0 #{$gutter-h};
		// & > * {
		//	margin-left: 0;
		// }
		&-line {
		}
		&-tw {
		}
		&-fb {
			vertical-align: top;
			.fb-share-button {
				position: relative;
				// top: -4px;
				line-height: 1;
			}
			iframe {
				height: 22px;
			}
		}
		&-hatena {
		}
		&-pocket {
			iframe {
				height: 22px;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.entrySns {
		$this: &;
		$gutter-h: 5px;

		margin-top: 0;
		&_list {
			& > * {
				&:not(:first-child) {
					margin-left: #{$gutter-h};
				}
			}
		}
		&_item {
			iframe {
				width: auto;
			}
			&-line {
			}
			&-tw {
			}
			&-fb {
				.fb-share-button {
					top: -6px;
				}
				iframe {
					max-width: 150px;
				}
			}
			&-hatena {
			}
			&-pocket {
				iframe {
					max-width: 110px;
				}
			}
		}
	}
}

/*
entry用 : 記事エリア

.entryArticle

Markup:
<div class="entryArticle">
</div><!-- /.entryArticle -->

Styleguide 8.3
============================================================ */
.entryArticle {
	$this: &;

	margin-top: 40px;
	line-height: 1.6;
	p,ul,ol,table,dl,figure {
		&:first-of-type {
			margin-top: 0;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.entryArticle {
		$this: &;

		margin-top: 30px;
	}
}

/*
entry用 : フッダ

.entryFooter

Markup:
<footer class="entryFooter">
	<div class="entryFooter_btns">
		<p class="entryFooter_btn"><a href="#" class="btn btn-mid btn-border00 btn-arrowRight"><span>一覧へ戻る</span></a></p>
		<p class="entryFooter_btn"><a href="#" class="btn btn-mid btn-border00 btn-arrowRight"><span>一覧へ戻る</span></a></p>
	</div>
</footer><!-- /.entryFooter -->

Styleguide 8.4
============================================================ */
.entryFooter {
	$this: &;
	$gutter-h: 15px;

	margin: 45px auto 0;
	padding-top: 20px;
	border-top: 1px dotted $gray-border;

	// _btns area
	&_btns {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: flex-end;
				justify-content: flex-end;
		-webkit-align-items: center;
				align-items: center;
		-webkit-flex-wrap: wrap;
				flex-wrap: wrap;

		margin: -#{$gutter-h} 0 0 -#{$gutter-h};
		text-align: center;
		list-style-type: none;
		& > * {
			margin: #{$gutter-h} 0 0 #{$gutter-h};
		}
	}
	&_btn {
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.entryFooter {
		$this: &;
		$gutter-h: 15px;

		margin: 20px auto 0;
		padding-top: 10px;

		// _btns area
		&_btns {
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-flow: column wrap;
					flex-flow: column wrap;

			margin: 0;
			& > * {
				margin: 0;
				width: 100%;
				width: 100%\9;
				&:not(:first-child) {
					margin: #{$gutter-h} 0 0;
				}
			}
		}
	}
}

/*
entry用 : 見出し1

entryTitle1

Markup:
<h2 class="entryTitle1">見出し1</h2>

Styleguide 8.5.1
============================================================ */
@media screen and (max-width: $breakpoint-sp) {
}

/*
entry用 : 見出し2

entryTitle2

Markup:
<h2 class="entryTitle2">見出し2</h2>

Styleguide 8.5.2
============================================================ */
@media screen and (max-width: $breakpoint-sp) {
}

/*
entry用 : 見出し3

entryTitle3

Markup:
<h2 class="entryTitle3">見出し3</h2>

Styleguide 8.5.3
============================================================ */
@media screen and (max-width: $breakpoint-sp) {
}

/*
entry用 : 見出し4

entryTitle4

Markup:
<h2 class="entryTitle4">見出し4</h2>

Styleguide 8.5.4
============================================================ */
@media screen and (max-width: $breakpoint-sp) {
}

/*
entry用 : 見出し5

entryTitle5

Markup:
<h2 class="entryTitle5">見出し5</h2>

Styleguide 8.5.5
============================================================ */
@media screen and (max-width: $breakpoint-sp) {
}




// test CSS
.entrySampleTitle {
	margin: 40px 0 0;
	padding: 3px;
	border-bottom: 1px dotted #ccc;
	background: #333;
	color: $white;
}
@charset "UTF-8";
@import '../_config.scss';

/*
	dialogCard

Markup:
<div class="dialogCard">
	<div class="dialogCard_overlay"></div>
	<!-- <a class="dialogCard_overlay" href="javascript: void(0);"></a> -->
	<div class="dialogCard_dialogWrap">
		<div class="dialogCard_close"><button type="button">×</button></div>
		<div class="dialogCard_dialog">
			<div class="dialogCard_txt">' + options.text + '</div>
			<ul class="dialogCard_btns">
				<li class="dialogCard_btn dialogCard_btn-ok" data-dialog-ok=""><a href="javascript: void(0);">OK</a></li>
				<li class="dialogCard_btn dialogCard_btn-cancel" data-dialog-cancel=""><a href="javascript: void(0);">キャンセル</a></li>
			</ul>
		</div>
	</div>
</div><!-- dialogCard -->
------------------------------------------------------------------------ */
.dialogCard {
	$this: &;
	$closeSize: 40px;

	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
			justify-content: center;
	-webkit-align-items: center;
			align-items: center;

	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: default;
	z-index: -9999;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-animation-duration: .2s;
	        animation-duration: .2s;
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both;

	// _overlay area
	&_overlay {
		display: block;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// background: rgba(255,255,255,.8);
		background: rgba(0,0,0,.8);
		&:hover {
			opacity: 1;
		}
	}

	// _dialogWrap area
	&_dialogWrap {
		opacity: 0;
		position: relative;
		padding: 35px 30px;
		min-width: 400px;
		max-width: 80%;
		max-height: 80%;
		overflow-y: auto;
		background: $white;
		border: 1px solid #dcdcdc;
		-webkit-box-shadow: 0 0 5px rgba(0,0,0,.2);
		        box-shadow: 0 0 5px rgba(0,0,0,.2);
		-webkit-overflow-scrolling: touch;
	}
	&_dialog {
		-webkit-transition: .3s;
		        transition: .3s;
	}
	&_dialogWrap > :first-child {
		margin-top: 0;
	}

	&_txt {
		&:first-child {
			margin-top: 0;
		}
	}

	&_close + &_txt {
		margin-top: 0;
	}

	&_btns {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		margin: 20px 10px 0;
		list-style-type: none;
	}
	&_btn {
		margin: 0 15px;
		& > a {
			@extend %btnStyle;
			padding: 11px 10px 9px;
			min-width: 94px;
			color: $white;
			@include fs(13);
		}

		&-ok {
			& > a {
				background-color: lighten($red, 15%);
			}
		}

		&-cancel {
			& > a {
				background-color: lighten(#333, 25%);
				min-width: 84px;
			}
		}

		&-yes ,
		&-no {
			& > a {
				color: $baseColor;
			}
		}
	}

	&_close {
		position: absolute;
		top: 5px;
		right: 5px;
		& > a ,
		& > button {
			display: block;
			width: #{$closeSize};
			height: #{$closeSize};
			line-height: #{$closeSize};
			text-align: center;
			@include fs(20);
			color: $gray;
			background-color: $gray-exLight;
			text-decoration: none;
		}
	}

	&-message {
		#{$this}_dialogWrap {
			padding: 20px;
			text-align: center;
		}
		.em {
			font-weight: bold;
		}
	}

	//　開閉処理
	&-is-open {
		-webkit-animation-name: dialogCard-is-open;
		animation-name: dialogCard-is-open;
		#{$this}_dialogWrap {
			opacity: 1;
			-webkit-transition: .2s .4s;
			        transition: .2s .4s;
		}
	}
	&-is-close {
		-webkit-animation-name: dialogCard-is-close;
		animation-name: dialogCard-is-close;
	}
}
@-webkit-keyframes dialogCard-is-open {
	0% {
		opacity: 0;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		z-index: 3000;
	}
	100% {
		opacity: 1;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		z-index: 3000;
	}
}
@keyframes dialogCard-is-open {
	0% {
		opacity: 0;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		z-index: 3000;
	}
	100% {
		opacity: 1;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		z-index: 3000;
	}
}
@-webkit-keyframes dialogCard-is-close {
	0% {
		opacity: 1;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		z-index: 3000;
	}
	99% {
		z-index: 3000;
	}
	100% {
		opacity: 0;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		z-index: -9999;
	}
}
@keyframes dialogCard-is-close {
	0% {
		opacity: 1;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		z-index: 3000;
	}
	99% {
		z-index: 3000;
	}
	100% {
		opacity: 0;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		z-index: -9999;
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.dialogCard {
		$this: &;
		$closeSize: 26px;

		&_dialogWrap {
			padding: 35px 5px;
			min-width: 0;
			max-width: 95%;
			width: 95%;
		}

		&_btns {
			margin: 2em 5px 0;
		}
		&_btn {
			margin: 0 10px;
			& > a {
				padding-left: 5px;
				padding-right: 5px;
				min-width: 80px;
				@include fs(12);
			}
		}

		&_close {
			top: 2px;
			right: 2px;
			& > a ,
			& > button {
				width: #{$closeSize};
				height: #{$closeSize};
				line-height: #{$closeSize};
				@include fs(20);
			}
		}
	}
}

@charset "UTF-8";
@import '_config.scss';

/*
フォーム関連モジュール

フォーム関連モジュール

Styleguide 5.
*/

/*
フォーム関連 : 大枠

form

Markup:
<form action="<%= siteRoot %>contact/confirm.html" method="" data-validate="">
	<div class="form">
		<p>
			下記の項目をご入力いただき、「入力内容の確認画面へ」ボタンを押してください。
			<br><i class="formIcon-required">必須</i>項目は必ずご入力ください。
		</p>
		<%# ///________________ header ________________/// %>
		<header class="form_header">
			<h3 class="form_headLine"><%= meta.pageSubTitle %></h3>
			<p class="form_txt">
				テキストテキストテキストテキストテキストテキストテキスト
			</p>
		</header><!-- /.form_header -->
		<%# ///________________ contents ________________/// %>
		<div class="form_contents">
			<div class="enquete">
				<table class="enquete_table">
					<colgroup>
						<col style="width: 280px;">
						<col style="">
					</colgroup>
					<tbody>
						<tr>
							<th class="enquete_must">名前</th>
							<td>
								<div class="enquete_grid enquete_grid-v">
									<div class="enquete_col enquete_col-6-12pc enquete_col-12-12sp"><input type="text" name="" id="" placeholder="大"></div>
								</div>
								<div class="enquete_note indent10e">※あああああああああああ</div>
								<p class="enquete_error">エラーエラーエラーエラーエラー</p>
							</td>
						</tr>
						<tr>
							<th class="enquete_must">メールアドレス</th>
							<td>
								<div class="enquete_grid">
									<div class="enquete_col enquete_col-12-12pc enquete_col-12-12sp"><input type="text" name="" id=""></div>
									<div class="enquete_col enquete_col-12-12pc enquete_col-12-12sp"><input type="text" name="" id="" placeholder="確認のためもう一度入力してください"></div>
								</div>
								<div class="enquete_note indent10e">※あああああああああああ</div>
								<p class="enquete_error">エラーエラーエラーエラーエラー</p>
							</td>
						</tr>

					</tbody>
				</table>
			</div><!-- /.enquete -->
		</div><!-- /.form_contents -->
		<%# ///________________ footer ________________/// %>
		<footer class="form_footer">
			<!-- 規約 -->
			<%# __ 規約文章 normal ver. __ %><%- include(includeRoot + 'terms/_module/_terms-normal'); %>
			<%# __ 規約文章 scroll ver. __ %><%- include(includeRoot + 'terms/_module/_terms-scroll'); %>
			<%# __ 規約文章 iframe ver. __ %><%- include(includeRoot + 'terms/_module/_terms-iframe'); %>
			<!-- formAgree -->
			<div class="form_agree">
				<p class="form_agreeLink">
					<a href="xxx">個人情報保護方針</a>
				</p>
				<div class="form_agreeCheck">
					<label class="customInput"><input type="checkbox" name="" id="" data-agree-target="checked"><span class="customInput_inner">上記に同意する</span></label>
				</div>
				<p class="form_agreeTxt">
					上記内容をご確認の上、よろしけれ「確認画面へ進む」ボタンを押して下さい。
				</p>
			</div><!-- /.form_agree -->
			<div class="form_btns">
				<p class="form_btn">
					<button class="disabledBtn btn btn-confirmBtn" data-agree="checked"><span>入力内容の確認画面へ</span></button>
				</p>
			</div><!-- /.form_btns -->
		</footer><!-- /.form_footer -->
	</div><!-- /.form -->
</form>

Styleguide 5.1
============================================================ */
.form {
	$this: &;
	$gutter-block: 30px;
	$gutter-h: 20px;

	&_txt {
		&:first-child {
			margin-top: 0;
		}
	}

	// _header area
	&_header {
		&:not(:first-child) {
			margin-top: #{$gutter-block};
		}
	}
	&_headLine {
		position: relative;
		margin: 0 0 20px;
		padding: 4px 0 10px;
		@include fs(18);
		font-weight: bold;
		&::after {
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			left: 0%;
			width: 50px;
			height: 5px;
			background-color: $gray;
		}
	}

	// _contents area
	&_contents {
		margin: 38px 0;
		&:first-child {
			margin-top: 0;
		}
	}

	// _footer area
	&_footer {
		&:not(:first-child) {
			margin-top: #{$gutter-block};
		}
	}

	// _agree area
	&_agree {
		margin-top: 30px;
		text-align: center;
	}
	&_agreeLink {
		padding: 15px 0;
	}
	&_agreeTxt {
		margin: 0;
		font-weight: bold;
	}
	&_agreeCheck {
		margin: 25px auto 35px;
		padding: 1.8em 3em;
		max-width: 400px;
		font-weight: bold;
		border: 1px dotted $gray-dot;

		// radio ・ checkbox デザインver.
		.customInput {
			> [type="checkbox"] + span {
				display: block;
				padding: 0 calc( #{$icon-radio-off-width} / 2  + 5px );
			}
			//　チェックボックス / ラジオボタン
			> [type="radio"] + span::before ,
			> [type="radio"]:checked + span::after ,
			> [type="checkbox"] + span::before ,
			> [type="checkbox"]:checked + span::after {
				left: -3px;
			}
		}
	}

	// _btns area
	&_btns {
		margin-top: 55px;
		& > * {
			display: block;
			margin: 0;
			text-align: center;
			&:not(:first-child) {
				margin-top: #{$gutter-h};
			}
		}
		&-confirm {
			display: -webkit-flex;
			display: flex;
			-webkit-justify-content: center;
					justify-content: center;
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-wrap: wrap;
					flex-wrap: wrap;

			margin: 30px 0 0 -#{$gutter-h};
			list-style-type: none;
			& > * {
				margin: #{$gutter-h} 0 0 #{$gutter-h} !important;
			}
		}
	}
	&_btn {
		.btn {
			margin: auto;
		}
	}
}
// 共通エラーメッセージ
// --------------------------------------
.errorMsg {
	position: relative;
	display: block;
	margin-top: 10px;
	padding: .5em;
	padding-left: 2.3em;
	text-align: left;
	color: $red;
	@include fs(14);
	line-height: 1.2;
	background-color: #FFE6E9;
	&::before {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		        transform: translateY(-50%);
		left: 10px;
		color: inherit;
		@include fs(18);
		font-weight: normal;
		@include fa-old($exclamation-circle);
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.form {
		$this: &;
		$gutter-block: 30px;
		$gutter-h: 10px;

		&_txt {
			margin-top: 15px;
			&:first-child {
				margin-top: 0;
			}
		}

		// _header area
		&_header {
			margin-top: #{$gutter-block};
		}
		&_headLine {
			padding-bottom: 6px;
			@include fs(16);
			&::after {
				width: 40px;
				height: 3px;
			}
		}

		// _contents area
		&_contents {
			margin-top: #{$gutter-block};
		}

		// _footer area
		&_footer {
			margin-top: #{$gutter-block};
		}

		// _agree area
		&_agree {
			margin-top: 30px;
		}
		&_agreeCheck {
			margin: 25px auto;
			padding: 1em 3em;
			max-width: none;
		}

		// _btns area
		&_btns {
			margin-top: 25px;
			& > * {
				margin: 0;
				&:not(:first-child) {
					margin-top: #{$gutter-h};
				}
			}
			&-confirm {
				margin: 20px 0 0 -#{$gutter-h};
				& > * {
					-webkit-flex: 1;
					    -ms-flex: 1;
					        flex: 1;
					margin: #{$gutter-h} 0 0 #{$gutter-h} !important;
				}
			}
		}
		&_btn {
		}
	}

	// 共通エラーメッセージ
	// --------------------------------------
	.errorMsg {
		position: absolute;
		bottom: -2px;
		margin: 0;
		padding: 7px 3px 7px 2.3em;
		width: 100%;
		@include fs(13);
		font-weight: bold;
		background-color: rgba(#FFE6E9, .9);
	}
}

/*
enquete

enquete

Markup:

Styleguide 5.2
============================================================ */
// enqueteテンプレート部分デザイン調整
.enquete {
	$this: &;
	max-width: 1200px;
	&_table {
		width: 100%;
		th ,
		td {
			padding: 20px 14px;
		}
		th {
			font-weight: bold;
			background-color: $gray-bg;
		}
	}
	&_must {
	}
	&_btn {
		&:hover {
			opacity: 1;
		}
		&:focus {
			background-color: darken($gray, 30%);
		}
	}
	&_note {
		margin-top: 10px !important;
		text-indent: -1em !important;
		padding-left: 1em !important;
	}
	&_error {
		position: relative;
		margin-top: 10px !important;
		padding: 5px;
		padding-left: 1.6em;
		font-weight: bold;
		background: lighten($red, 50%);
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 5px;
			color: inherit;
			@include fa-old($exclamation-circle);
			-webkit-transform: translateY(-50%);
			        transform: translateY(-50%);
		}
	}
}
// for IE style
@media all and (-ms-high-contrast: none) {
	.enquete {
		$this: &;
		&_must {
			padding-left: 10px !important;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.enquete {
		$this: &;
		max-width: none;
		&_table {
			th {
				padding-top: 10px !important;
				padding-bottom: 10px !important;
				background-color: $gray-exLight;
			}
		}
	}
}

/*
フォーム関連 : 規約関連

formTerm

Markup:
<!-- 規約文章 -->
<div class="formTerm formTerm-normal">
	<div class="formTerm_inner">
		<p class="formTerm_title">【 規約関連表示エリア 】</p>
		<p class="formTerm_txt">
			テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
			<br>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
		</p>
		<ul class="markList markList-disc">
			<li>テキストテキストテキストテキスト</li>
			<li>テキストテキストテキストテキスト</li>
		</ul>
		<ul class="markList markList-markOut">
			<li>
				<label class="customInput"><input type="radio" name="" id=""><span class="customInput_inner">ラジオボタン確認項目</span></label>
			</li>
			<li>
				<label class="customInput"><input type="checkbox" name="" id=""><span class="customInput_inner">チェックボックス確認項目</span></label>
			</li>
		</ul>
	</div>
</div><!-- /.formTerm -->
<!-- 規約文章 scroll ver. -->
<div class="formTerm formTerm-scroll">
	<p class="formTerm_title">【 規約関連表示エリア scroll ver. 】</p>
	<p class="formTerm_txt">
		テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
		<br>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
	</p>
	<ul class="markList markList-disc">
		<li>テキストテキストテキストテキスト</li>
		<li>テキストテキストテキストテキスト</li>
	</ul>
</div><!-- /.formTerm -->
<!-- 規約文章 iframe ver. -->
<div class="formTerm formTerm-iframe">
	<p class="formTerm_title">【 規約関連表示エリア iframe ver. 】</p>
	<div class="formTerm_iframeWrap">
		<iframe src="<%= siteRoot %>terms/" frameborder="0" class="formTerm_iframe"></iframe>
	</div><!-- /.termsIframe -->
</div><!-- /.formTerm -->

Styleguide 5.3
============================================================ */
.formTerm {
	$this: &;
	$frameHeight: 188px;

	margin: 40px auto 0;
	padding: 14px 18px;
	// background-color: $gray-exLight;
	&:first-child {
		margin-top: 0;
	}

	&_title {
		margin: 0 0 8px;
		padding: 0 1.4em;
		color: $mainColor01;
		@include fs(18);
		font-weight: bold;
		-webkit-box-shadow: inset 4px 0 0 0 $mainColor01;
		        box-shadow: inset 4px 0 0 0 $mainColor01;
	}
	&_txt {
		margin-top: 12px;
		font-size: 110%;
		&:first-child {
			margin-top: 0;
		}
	}

	// ___ terms type @ normal
	&-normal {
		border: 1px solid $gray-border;
	}

	// ___ terms type @ scroll
	&-scroll {
		height: #{$frameHeight};
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		border: 1px solid $gray-border;
		@include scrollbar02;
	}

	// ___ terms type @ iframe
	&-iframe {
		padding: 0;
		border: 1px solid $gray-border;
	}
	&_iframeWrap {
		margin: 0;
		width: 100%;
		height: calc( #{$frameHeight} + 20px );
		background-color: $white;
		// border: 1px solid $gray-border;
		overflow: hidden;
		-webkit-overflow-scrolling: touch;
	}
	&_iframe {
		width: 100%;
		height: 100%;
		@include scrollbar02;
	}

	// ___ terms type @ notes
	&-notes {
		border: 1px solid $gray-border;
	}

}
// @media screen and (max-width: $breakpoint-pc) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.formTerm {
		$this: &;
		$frameHeight: 180px;

		padding: 10px 5px;

		&_title {
			padding: 0 1em;
			@include fs(16);
		}
		&_txt {
			font-size: 100%;
		}

		// ___ terms type @ normal
		&-normal {
		}

		// ___ terms type @ scroll
		&-scroll {
			height: #{$frameHeight};
		}

		// ___ terms type @ iframe
		&-iframe {
		}
		&_iframeWrap {
			height: calc( #{$frameHeight} - 20px );
		}

		// ___ terms type @ notes
		&-notes {
			border: 1px solid $gray-border;
			height: #{$frameHeight};
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
			@include scrollbar02;
		}

	}
}

/*
ご利用規約 内容

termsDetail

Markup:
<div class="termsDetail">
	<header class="termsDetail_header">
		<h2 class="termsDetail_headLine">会員規約</h2>
		<p class="termsDetail_currentDate">（ 2019.12.12 現在 ）</p>
	</header><!-- /.termsDetail_header -->
	<p>
		テキストテキストテキストテキストテキステキストテキストテキストテキストテキス
	</p>
	<dl class="termsDetail_section">
		<dt class="termsDetail_title"><em>第1条</em>総則</dt>
		<dd class="termsDetail_body">
			<ol>
				<li>リストリストリストリスト</li>
				<li>リストリストリストリスト</li>
				<li>リストリストリストリスト</li>
			</ol>
		</dd>
	</dl><!-- /.termsDetail_section -->
	<dl class="termsDetail_section">
		<dt class="termsDetail_title"><em>第2条</em>〇〇</dt>
		<dd class="termsDetail_body">
			<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
			<ul>
				<li>リストリストリストリスト</li>
				<li>リストリストリストリスト</li>
				<li>リストリストリストリスト</li>
			</ul>
		</dd>
	</dl><!-- /.termsDetail_section -->
	<div class="tRight">
		<p>以上</p>
	</div>
	<footer class="termsDetail_footer">
		<p class="termsDetail_signDate">
			制定日： 20XX年XX月XX日
			<br>改定日： 20XX年XX月XX日
		</p>
		<p class="termsDetail_sign">
			株式会社〇〇〇〇〇〇
			<br>代表取締役　〇〇　〇〇
		</p>
	</footer><!-- /.termsDetail_footer -->
</div><!-- /.termsDetail -->

Styleguide 5.4
============================================================ */
.termsDetail {
	$this: &;

	margin: 20px 0;
	padding: 10px;

	// _header area
	&_header {
		margin: 0 0 8px;
		padding: 0 1.4em;
		color: $mainColor01;
		@include fs(18);
		font-weight: bold;
		-webkit-box-shadow: inset 4px 0 0 0 $mainColor01;
		        box-shadow: inset 4px 0 0 0 $mainColor01;
	}
	&_headLine {
		@include fs(20);
		font-weight: bold;
	}
	&_currentDate {
		margin: 0;
		@include fs(12);
		font-weight: bold;
	}

	// _section area
	&_section {
		&:not(:first-child) {
			margin-top: 45px;
		}
		& > * {
			margin: 0;
			padding: 0;
			border: none;
		}
	}
	&_title {
		position: relative;
		margin: 10px auto;
		padding: 0 20px 5px;
		@include fs(16);
		font-weight: bold;
		line-height: 1.45;
		border-bottom: 1px solid $gray-light;
		&::after {
			content: "";
			display: inline-block;
			position: absolute;
			top: 7px;
			left: 0;
			width: 10px;
			height: 10px;
			background-color: $gray-light;
		}
		> em {
			margin-right: .5em;
			font-style: normal;
		}
	}
	&_body {
		padding: 0 10px;
		&:not(:first-child) {
			margin-top: 15px;
		}

		p,dl,ul,ol {
			margin-top: 10px;
		}
	}

	// _footer area
	&_footer {
		margin-top: 30px;
		text-align: right;
	}
	&_signDate {
		@include fs(14);
	}
	&_sign {
		margin-top: 10px;
		@include fs(15);
	}
}
// @media screen and (max-width: $breakpoint-pc) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.termsDetail {
		$this: &;

		margin: 10px 0;
		padding: 5px;

		// _header area
		&_header {
			&:after {
				width: 35%;
			}
		}
		&_headLine {
			@include fs(18);
		}
		&_currentDate {
			@include fs(10);
		}

		// _section area
		&_section {
			margin-top: 35px;
		}
		&_title {
			@include fs(16);
		}
		&_body {
			margin-top: 10px;
			padding: 0;
		}

		// _footer area
		&_footer {
			margin-top: 20px;
		}
		&_signDate {
			@include fs(12);
		}
		&_sign {
			@include fs(14);
		}
	}
}

/*
inquiryForm

inquiryForm

Markup:

Styleguide 5.5
============================================================ */
.inquiryForm {
	$this: &;
	$gutter-v: 15px;
	$width: 35%;
	margin: 1.2em auto;
	padding: 2em;
	width: 85%;
	background-color: $gray-bg;
	border: 1px dotted $gray;

	&_title {
		@include fs(18);
		font-weight: bold;
	}

	// _field area
	&_field {
		&:not(:first-child) {
			margin: #{$gutter-v} 0 0;
		}
	}
	&_table {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
				justify-content: center;
		-webkit-align-items: center;
				align-items: center;

		margin: 0;
		padding: 0;
		width: 100%;
		& > * {
			margin: 0;
			padding: 0;
			border: none;
			&:not(:first-child) {
				margin-left: 1em;
			}
		}
	}
	&_th {
		width: #{$width};
		min-width: #{$width};
		text-align: right;
	}
	&_td {
		width: calc( 100% - #{$width} );
		text-align: left;
	}

	// _title area
	&_title {
		margin: 0;
		font-weight: bold;
	}

	// _checked area
	&_checked {
		margin-top: 1.3em;
		text-align: center;
	}

	// _btns area
	&_btns {
		margin-top: 1.5em;
	}
	&_btn {
		margin: 0;
		&:not(:first-child) {
			margin-top: 1.4em;
		}
		> a ,
		> button {
			display: block;
			position: relative;
			margin: 0 auto;
			padding: .7em;
			width: 300px;
			height: 40px;
			text-align: center;
			color: $white;
			@include fs(14);
			text-decoration: none;
			background-color: $gray-dark;
			border: 1px solid $gray-dark;
			> span {
				position: relative;
				display: inline-block;
				&::before {
					content: "";
					display: inline-block;
					position: absolute;
					top: 50%;
					left: 0;
					margin-top: -5px;
					line-height: 1;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
				}
			}
		}

		// 送信ボタン
		&-send {
			> a ,
			> button {
				width: 200px;
			}
		}
	}

	// 送信画面時
	// 確認画面時
	&-sendForm03 ,
	&-sendForm02 ,
	&-sendForm ,
	&-confirm {
		#{$this}_table {
			padding: 1em;
			background-color: $white;
			& > * {
				min-width: 0;
				width: auto;
			}
		}
	}
	&-sendForm02 {
		#{$this}_table {
			-webkit-justify-content: flex-start;
					justify-content: flex-start;
		}
		#{$this}_th {
			&-title {
				white-space: nowrap;
			}
		}
		#{$this}_td {
			&-input {
				width: 72%;
				// -webkit-flex: 0 1 100%;
				//     -ms-flex: 0 1 100%;
				//         flex: 0 1 100%;
			}
			&-btn {
				white-space: nowrap;
			}
		}
	}
	&-sendForm03 {
		#{$this}_table {
			-webkit-justify-content: flex-start;
					justify-content: flex-start;
		}
		#{$this}_th {
			&-title {
				white-space: nowrap;
			}
		}
		#{$this}_td {
			&-input {
				-webkit-flex: 0 1 100%;
				    -ms-flex: 0 1 100%;
				        flex: 0 1 100%;
			}
			&-btn {
				white-space: nowrap;
			}
		}
		#{$this}_btns {
			margin-top: 1em;
		}
	}

	&-confirm {
		#{$this}_table {
			background-color: $gray-exLight;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.inquiryForm {
		$this: &;
		$width: 35%;

		padding: 1em .5em;
		width: 100%;

		&_title {
			@include fs(15);

		}
		// _field area
		&_table {
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-flow: column wrap;
					flex-flow: column wrap;

			& > * {
				margin: 0;
				width: 100%;
				text-align: left;
				&:not(:first-child) {
					margin-top: .5em;
				}
			}
		}
		&_th {
		}
		&_td {
		}

		// _checked area
		&_checked {
			@include fs(12);
			.label {
				//　チェックボックス
				&.label_checkbox > input + span::before ,
				&.label_checkbox > input:checked + span::after {
					margin-top: 2px;
				}
			}
		}

		// _btns area
		&_btns {
			margin-top: 1em;
		}
		&_btn {
			> a ,
			> button {
				width: 80%;
			}

			// 送信ボタン
			&-send {
				margin: .6em 0 0 !important;
				> a ,
				> button {
					width: 150px;
				}
			}
		}

		// 確認画面時
		&-onetimepw ,
		&-sendForm03 ,
		&-sendForm02 ,
		&-sendForm ,
		&-confirm {
			#{$this}_table {
				padding: .6em;
				& > * {
					width: 100%;
					&:not(:first-child) {
						margin-left: 0;
					}
				}
			}
		}
		&-confirm {
			#{$this}_table {
				& > * {
					text-align: center;
				}
			}
		}
		&-sendForm02 {
			#{$this}_th {
				&-title {
					white-space: normal;
				}
			}
			#{$this}_td {
				&-input {
					width: 100%;
				}
				&-btn {
					white-space: normal;
				}
			}
		}
		&-sendForm03 {
			#{$this}_table {
			}
			#{$this}_th {
				&-title {
					white-space: normal;
				}
			}
			#{$this}_td {
				&-input {
				}
				&-btn {
					white-space: normal;
				}
			}
		}
		&-confirm {
		}
	}
}


@charset "UTF-8";
@import '_config.scss';

/*
モジュール

その他モジュール

Styleguide 4.
*/

/*
パンクズ

breadCrumb

Markup:
<nav class="breadCrumb">
	<ul class="breadCrumb_list">
		<li class="breadCrumb_item home"><a href="<%= rootHomePass %>"><span><%= rootHomeName %></span></a></li>
		<li class="breadCrumb_item"><a href="/">パンクズ一覧</a></li>
		<li class="breadCrumb_item">パンクズ</li>
	</ul>
</nav><!-- /.breadCrumb -->

Styleguide 4.1
============================================================ */
.breadCrumb {
	$this: &;
	$gutter-com: 1em;

	position: relative;
	// padding: 0 #{$gutter-com};
	z-index: 10;
	&_inner {
		// position: absolute;
		// bottom: 0;
		// left: 50%;
		margin: 0 auto;
		padding: 1.2em #{$gutter-gSide02};
		width: #{$baseWidth};
		background-color: $white;
		// -webkit-transform: translateX(-50%);
		//         transform: translateX(-50%);
	}
	&_list {
		margin: 0;
		padding: 0;
		overflow: hidden;
		list-style-type: none;
		> :first-child:before {
			content: none;
		}
	}
	&_item {
		margin: 0;
		float: left;
		color: $linkColor;
		@include fs(14);
		line-height: 1.4;
		&::before {
			content: '＞';
			display: inline-block;
			margin: 0 10px;
			font-size: inherit;
		}
		a {
			color: $linkColor;
		}
		span {
			font-weight: bold;
		}
		&.home {
			position: relative;
			> a {
				position: relative;
				display: inline-block;
				// &::before {
				//	content: '';
				//	display: inline-block;
				//	vertical-align: middle;
				//	position: relative;
				//	top: -2px;
				//	left: -1px;
				//	margin-right: 5px;
				//	@include sprite15x($icon_home);
				// }
				// > span {
				//	@include textHidden;
				// }
			}
			span {
				font-weight: normal;
			}
		}
	}

	// layout module
	&-type01 {
		#{$this}_inner {
			position: relative;
			bottom: auto;
			left: auto;
			-webkit-transform: translateX(0);
			        transform: translateX(0);
		}
	}
}
.pageHeader + .breadCrumb {
	$this: &;
	.breadCrumb_inner {
		position: absolute;
		bottom: 0;
		left: 50%;
		-webkit-transform: translateX(-50%);
		        transform: translateX(-50%);
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.breadCrumb {
		$gutter-com: .5em;

		padding: 5px #{$gutter-com} 0;
		&_inner {
			position: relative;
			bottom: auto;
			left: auto;
			padding: 0;
			width: 100%;
			-webkit-transform: translateX(0%);
			        transform: translateX(0%);
		}

		// 共通スタイル
		&_list {
			max-width: none;
		}
		&_item {
			display: inline;
			float: none;
			@include fs(12);
			&::before {
				margin: 0 .4em;
			}
			&.home {
				> a {
					display: inline;
					&::before {
						display: inline-block;
					}
				}
			}
		}

		// 要素内スクロール表示
		&_list {
			// padding: 5px 0 0;
			// width: auto;
			// white-space: nowrap;
			// overflow-x: scroll;
			// @include scrollbar02;
			// &::-webkit-scrollbar02 {
			//	width: 3px;
			//	height: 3px;
			// }
			// &::-webkit-scrollbar02-thumb {
			//	background-color: $gray-light;
			// }
			// &::-webkit-scrollbar02-track {
			//	background-color: $gray-exLight;
			// }
		}
	}
	.pageHeader + .breadCrumb {
		$this: &;
		.breadCrumb_inner {
			position: relative;
			bottom: auto;
			left: auto;
			-webkit-transform: translateX(0%);
			        transform: translateX(0%);
		}
	}
}

/*
画像とテキスト - （SP時float解除）

imgTxt

Markup:
<div class="imgTxt">
	<div class="imgTxt_left" data-figure="">
		<figure class="imgTxt_figure">
			<img class="imgTxt_img" src="http://fakeimg.pl/160x120/999/fff/" alt="ダミーイメージ">
			<figcaption class="imgTxt_caption" data-figure-caption="">caption caption caption caption caption </figcaption>
		</figure>
	</div>
	<div class="imgTxt_body">
		<p>
			テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
			<br>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
			<br>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
		</p>
	</div>
</div><!-- /.imgTxt -->
<div class="imgTxt">
	<div class="imgTxt_right" data-figure="">
		<figure class="imgTxt_figure">
			<img class="imgTxt_img" src="http://fakeimg.pl/160x120/999/fff/" alt="ダミーイメージ">
			<figcaption class="imgTxt_caption" data-figure-caption="">caption caption caption caption caption </figcaption>
		</figure>
	</div>
	<div class="imgTxt_body {$modifiers}">
		<p>
			テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
			<br>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
			<br>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
		</p>
	</div>
</div><!-- /.imgTxt -->

.imgTxt_body-around - 画像の下まで回り込み

Styleguide 4.2
============================================================ */
.imgTxt {
	$this: &;
	$gutter-h: 40px;
	margin: #{$gutter-h} 0 0;
	overflow: hidden;
	&:first-child {
		margin-top: 0;
	}
	&_left {
		float: left;
		margin: 0 #{$gutter-h} 0 0;
	}
	&_right {
		float: right;
		margin: 0 0 0 #{$gutter-h};
	}
	&_body {
		overflow: hidden;
		&-around {
			overflow: visible;
		}
	}
	&_figure {
		display: inline-block;
		margin: 0;
		text-align: center;
	}
	&_img {
		margin: 0;
		&:not(:first-child) {
			margin-top: 10px;
		}
	}
	&_caption {
		margin: 5px 0;
		text-align: left;
		color: $gray;
		@include fs(14);
		&-top {
			margin-top: 0;
		}
		&-center {
			text-align: center;
		}
		&-right {
			text-align: right;
		}
	}
	p,dl,ul,ol {
		&:first-child {
			margin-top: 0;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.imgTxt {
		$this: &;
		$gutter-h: 10px;
		$gutter-v: 15px;
		&_left ,
		&_right {
			text-align: center;
			float: none;
			margin: 0 0 #{$gutter-h} 0;
		}
		&_figure {
			display: block;
		}
		&_caption {
			font-size: 12px !important;
		}

		&-spFloat {
			#{$this}_left {
				float: left;
				margin: 0 #{$gutter-h} 0 0;
			}
			#{$this}_right {
				float: right;
				margin: 0 0 0 #{$gutter-h};
			}
		}

		&-spFlex {
			display: -webkit-flex;
			display: flex;
			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;

			#{$this}_left ,
			#{$this}_right {
				-webkit-order: 2;
				    -ms-order: 2;
				        order: 2;
				margin: #{$gutter-v} 0 0;
			}
			#{$this}_body {
				-webkit-order: 1;
				    -ms-order: 1;
				        order: 1;
			}
		}
	}
}

/*
テーブル

baseのstyleは「 _default 」に集約

commonTable

Markup:
<div class="tableScroll">
	<table class="commonTable">
		<colgroup>
			<col width="24%">
			<col width="19%">
			<col width="19%">
			<col width="19%">
		</colgroup>
		<caption class="caption">表のタイトル</caption>
		<thead>
			<tr>
				<th>ヘッダ</th>
				<th>ヘッダ</th>
				<th>ヘッダ</th>
				<th>ヘッダ</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<th class="head">テキストテキスト</th>
				<td>テキストテキスト</td>
				<td>テキストテキスト</td>
				<td>テキストテキスト</td>
			</tr>
			<tr>
				<th class="head">テキストテキスト</th>
				<td>テキストテキスト</td>
				<td>テキストテキスト</td>
				<td>テキストテキスト</td>
			</tr>
		</tbody>
		<tfoot>
			<tr>
				<th>フッダ</th>
				<th>フッダ</th>
				<th>フッダ</th>
				<th>フッダ</th>
			</tr>
		</tfoot>
	</table><!-- /.commonTable -->
</div><!-- /.tableScroll -->

Styleguide 4.3.1
============================================================ */
.tableScroll {
	$this: &;
	margin: 20px 0 0;
	& > :first-child {
		margin-top: 0;
	}
}
.commonTable {
	$this: &;
	position: relative;
	margin-top: 20px;
	&:first-child {
		margin-top: 0;
	}
	thead ,
	tfoot ,
	tbody {
		th {
			text-align: center;
			em {
				font-style: normal;
				font-weight: bold;
			}
		}
	}
	.head {
		text-align: center;
		background-color: $gray-bg;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.tableScroll {
		$this: &;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		@include scrollbar02;
		& > table {
			width: auto;
			white-space: nowrap;
			th ,
			td {
				white-space: nowrap;
			}
		}
	}
}

/*
レスポンシブテーブル (SP時テーブル解除)

baseのstyleは「 _default 」に集約

rwdTable

Markup:
<table class="rwdTable">
	<colgroup>
		<col width="30%">
		<col width="70%">
	</colgroup>
	<caption class="caption">表のタイトル</caption>
	<tbody>
		<tr>
			<th class="head">ヘッダ</th>
			<td>テキストテキストテキストテキストテキストテキスト</td>
		</tr>
		<tr>
			<th class="head">ヘッダ</th>
			<td>テキストテキストテキストテキストテキストテキスト</td>
		</tr>
		<tr>
			<th class="head">ヘッダ</th>
			<td>テキストテキストテキストテキストテキストテキスト</td>
		</tr>
		<tr>
			<th class="head">ヘッダ</th>
			<td>テキストテキストテキストテキストテキストテキスト</td>
		</tr>
		<tr>
			<th class="head">ヘッダ</th>
			<td>テキストテキストテキストテキストテキストテキスト</td>
		</tr>
	</tbody>
</table><!-- /.rwdTable -->

Styleguide 4.3.2
============================================================ */
.rwdTable {
	$this: &;
	position: relative;
	margin-top: 20px;
	&:first-child {
		margin-top: 0;
	}
	thead ,
	tfoot ,
	tbody {
		th {
			text-align: center;
			em {
				font-style: normal;
				font-weight: bold;
			}
		}
	}
	.head {
		text-align: center;
		background-color: $gray-bg;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.rwdTable {
		display: block;
		width: auto !important;
		border: none;
		border-top: 1px solid $gray-border;

		> caption ,
		> thead ,
		> tbody {
			display: block;
			width: auto !important;
			> tr {
				display: block;
				width: auto !important;
				> th,
				> td {
					display: block;
					width: auto !important;
					border-top: none;
					padding: 12px 5px 1em;
				}
			}
		}
	}
}

/*
ul,olリスト

markList

Markup:
<ul>
	<li>リストリストclassなし</li>
</ul>
<ul class="markList {$modifiers}">
	<li>リストリスト</li>
	<li>リストリスト</li>
	<li>リストリスト</li>
	<li>リストリストリストリストリストリストリストリストリストリストリストリストリストリストリストリストリストリストリストリストリストリストリストリストリストリストリストリスト</li>
	<li>リストリスト<br>リストリスト</li>
</ul><!-- /.markList -->

.markList-disc - 黒丸
.markList-circle - 白丸
.markList-square - 四角
.markList-note - ※印
.markList-Ldisc - 黒丸(大)
.markList-wDisc - ◎印
.markList-arrow - ＞矢印
.markList-leadingZero - 二桁台数字
.markList-paren - （）つき数字
.markList-circleNum  - 丸数字
.markList-markOnlyOut - 記号のみ無し
.markList-markOut - リストのスタイルそのものを無効
.markList-inside - マーカーの表示位置:inside

Styleguide 4.4
============================================================ */
.markList {
	$this: &;

	position: relative;
	margin: 0;
	padding-left: 2em;
	line-height: 2;
	counter-reset: numbers;

	i ,
	em {
		font-style: normal;
	}
	> li {
		display: list-item;
	}

	// 黒丸
	&-disc {
		list-style-type: disc;
	}

	// 白丸
	&-circle {
		list-style-type: circle;
	}

	// 四角
	&-square {
		list-style-type: square;
	}

	// 共通スタイル
	&-note ,
	&-Ldisc ,
	&-wDisc ,
	&-arrow ,
	&-leadingZero ,
	&-paren ,
	&-circleNum ,
	&-markOnlyOut ,
	&-markOut {
		list-style-type: none !important;
		> li {
			&::before {
				content: "";
				position: relative;
			}
		}
	}

	&-note ,
	&-Ldisc ,
	&-wDisc ,
	&-arrow {
		> li {
			text-indent: -1em;
			&::before {
				content: "";
				position: relative;
				left: -.5em;
			}
		}
	}

	// ※印
	&-note {
		> li {
			&::before {
				content: "※";
			}
		}
	}

	// 黒丸(大)
	&-Ldisc {
		> li {
			&::before {
				content: "●";
			}
		}
	}

	// ◎印
	&-wDisc {
		> li {
			&::before {
				content: "◎";
			}
		}
	}

	// ＞矢印
	&-arrow {
		> li {
			&::before {
				top: -1px;
				left: 1em;
				width: 1em;
				@include fa-old($angle-right);
			}
		}
	}

	// 二桁台数字
	&-leadingZero {
		> li {
			text-indent: -1.9em;
			counter-increment: numbers;
			&::before {
				content: '0' counter(numbers) ". ";
			}
			ol:not(.markList-leadingZero ) ,
			ul:not(.markList-leadingZero ) {
				padding-left: 2em;
				li {
					text-indent: 0;
				}
			}
		}
		li:nth-of-type(9) ~ li::before {
			content: counter(numbers) ". ";
		}

		&-bold {
			> li {
				&::before {
					font-weight: bold;
				}
			}
		}
	}

	// （）つき数字
	&-paren {
		> li {
			text-indent: -2.65em;
			counter-increment: numbers;
			&::before {
				content: "（" counter(numbers) "）";
			}
			ol:not(.markList-paren ) ,
			ul:not(.markList-paren ) {
				padding-left: 2em;
				li {
					text-indent: 0;
				}
			}
		}
	}

	// 丸数字 : 数字を<i>①</i>タグで囲む
	&-circleNum {
		> li {
			&::before {
				display: none;
			}
		}
		i {
			display: inline-block;
			text-indent: -1.5em;
		}
	}

	// 記号のみ無し
	&-markOnlyOut {
		padding-left: 1em;
		list-style-type: none;
	}

	// リストのスタイルそのものを無効
	&-markOut {
		padding: 0;
		list-style-type: none;
	}

	// マーカーの表示位置
	&-inside {
		list-style-position: inside;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.markList {
		$this: &;

		padding-left: 1.5em;
		line-height: 1.8;

		// 二桁台数字
		&-leadingZero {
			padding-left: 2em;
		}

		// （）つき数字
		&-paren {
			padding-left: 2em;
		}
	}
}

/*
グリッドカラム

gridColumn

Markup:
<div class="gridColumn gridColumn-grid gridColumn-grid-3-12pc gridColumn-grid-12-12sp">
	<div class="gridColumn_grid">
		<div class="gridColumn_col">
			Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
			tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
		</div>
		<div class="gridColumn_col">
			Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
			tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
		</div>
		<div class="gridColumn_col">
			Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
			tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
		</div>
		<div class="gridColumn_col">
			Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
			tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
		</div>
	</div>
</div><!-- /.gridColumn -->
<div class="gridColumn gridColumn-grid gridColumn-grid-12-12sp">
	<div class="gridColumn_grid">
		<div class="gridColumn_col gridColumn_col-8-12pc">
			Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
			tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
		</div>
		<div class="gridColumn_col gridColumn_col-4-12pc">
			Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
			tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
		</div>
	</div>
</div><!-- /.gridColumn -->

Styleguide 4.5
============================================================ */
.gridColumn {
	$this: &;
	$gutter-grid-h: 20px;
	$gutter-grid-v: 20px;

	&:not(:first-child) {
		margin-top: 25px;
	}

	&_grid {
	}

	// col module
	&_col {

		position: relative;
		overflow: hidden;
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
				width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-10 {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
				width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
			}
		}
	}

	// layput module

	&-left {
		#{$this}_grid {
			-webkit-justify-content: flex-start;
					justify-content: flex-start;
		}
	}
	&-center {
		#{$this}_grid {
			-webkit-justify-content: center;
					justify-content: center;
		}
	}
	&-right {
		#{$this}_grid {
			-webkit-justify-content: flex-end;
					justify-content: flex-end;
		}
	}
	&-sideArray {
		#{$this}_grid {
			-webkit-justify-content: space-between;
					justify-content: space-between;
		}
	}

	// layout module
	&-grid {
		#{$this}_grid {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-wrap: wrap;
					flex-wrap: wrap;

			margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		}
		&-v {
			#{$this}_grid {
				margin: 0;
				display: block;
				overflow: hidden;
			}
		}
		&-colAuto {
			#{$this}_col {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: auto !important;
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				#{$this}_col {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px )\9;
				}
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-10 {
				#{$this}_col {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px )\9;
				}
			}
		}
	}

	&-gutterNone {
		#{$this}_grid {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-wrap: wrap;
					flex-wrap: wrap;
			margin: 0;
		}
		@for $n from 1 to 13 {
			&-#{$n}-12 {
				#{$this}_col {
					margin: 0;
					width: calc(100%/12 * #{$n} - 0px);
					width: calc(100%/12 * #{$n} - 0px - 4px )\9;
				}
			}
		}
		@for $n from 1 to 13 {
			&-#{$n}-10 {
				#{$this}_col {
					margin: 0;
					width: calc(100%/10 * #{$n} - 0px);
					width: calc(100%/10 * #{$n} - 0px - 4px )\9;
				}
			}
		}
	}
}
// PC style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.gridColumn {
		$this: &;
		$gutter-grid-h: 20px;
		$gutter-grid-v: 20px;

		// col module
		&_col {
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10pc {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}

		// layout module
		&-grid {
			&-v-pc {
				#{$this}_grid {
					margin: 0;
				}
			}
			&-colAuto-pc {
				#{$this}_col {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					#{$this}_col {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px )\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10pc {
					#{$this}_col {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px )\9;
					}
				}
			}
		}
		&-gutterNone {
			#{$this}_grid {
				margin: 0;
			}
			@for $n from 1 to 13 {
				&-#{$n}-12pc {
					#{$this}_col {
						margin: 0;
						width: calc(100%/12 * #{$n} - 0px);
						width: calc(100%/12 * #{$n} - 0px - 4px )\9;
					}
				}
			}
			@for $n from 1 to 13 {
				&-#{$n}-10pc {
					#{$this}_col {
						margin: 0;
						width: calc(100%/10 * #{$n} - 0px);
						width: calc(100%/10 * #{$n} - 0px - 4px )\9;
					}
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.gridColumn {
		$this: &;
		$gutter-grid-h: 10px;
		$gutter-grid-v: 10px;

		&:not(:first-child) {
			margin-top: 15px;
		}
		&_grid {
			& > *:not(:first-child) {
				margin: 0;
			}
		}

		// col module
		&_col {
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10sp {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}

		// layout module
		&-grid {
			#{$this}_grid {
				margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
			}
			&-v-sp {
				#{$this}_grid {
					margin: 0;
				}
			}
			&-colAuto-sp {
				#{$this}_col {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					#{$this}_col {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px )\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10sp {
					#{$this}_col {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px )\9;
					}
				}
			}
		}

		&-gutterNone {
			#{$this}_grid {
				margin: 0;
			}
			@for $n from 1 to 13 {
				&-#{$n}-12sp {
					#{$this}_col {
						margin: 0;
						width: calc(100%/12 * #{$n} - 0px);
						width: calc(100%/12 * #{$n} - 0px - 4px )\9;
					}
				}
			}
			@for $n from 1 to 13 {
				&-#{$n}-10sp {
					#{$this}_col {
						margin: 0;
						width: calc(100%/10 * #{$n} - 0px);
						width: calc(100%/10 * #{$n} - 0px - 4px )\9;
					}
				}
			}
		}
	}
}

/*
ページヘッダ

pageHeader

Markup:
<header class="pageHeader {$modifiers}" data-pageheader="">
	<h1 class="pageHeader_headLine">
		<span class="pageHeader_title">PageHeader</span>
		<small class="pageHeader_sub">サブタイトル</small>
	</h1>
</header><!-- /.pageHeader -->

.pageHeader-design01 - design01

Styleguide 4.6
============================================================ */
.pageHeader {
	$this: &;
	position: relative;
	margin: 0 auto;
	width: 100%;
	background-color: $gray-light;
	// &:first-child {
	//	margin-top: 0;
	// }
	&_headLine {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
				justify-content: center;
			-webkit-flex-flow: column wrap;
					flex-flow: column wrap;

		position: relative;
		margin: 0 auto;
		padding: 20px 10px 40px;
		max-width: #{$baseWidth};
		height: 100%;
		z-index: 10;
		& > * {
			width: 100%;
		}
	}
	&_title ,
	&_sub {
		display: block;
		line-height: 1.4;
		color: $white;
		font-weight: normal;
	}
	&_title {
		margin-top: -1.5em;
		font-weight: normal;
		@include fs(48);
		font-weight: bold;
	}
	&_sub {
		margin-top: 5px;
		@include fs(14);
		font-style: normal;
		font-family: メイリオ;
	}

	// layout module
	&-full {
		#{$this}_headLine {
			max-width: none;
		}
	}

	&[data-pageheader] {
		height: 340px;
		// background-color: rgba(0,0,0, .5);
		background-position: center;
		background-repeat: no-repeat;
		// background-attachment: fixed;
		-webkit-background-size: cover;
				background-size: cover;
		&::before ,
		&::after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0;
			width: 100%;
			height: 100%;
			// opacity: .5;
		}
		&::before {
			background-color: rgba(144, 144, 144, 0.4);
			z-index: 1;
		}
		&::after {
			background: url(./../images/global/bg_stripe01.png) repeat;
			opacity: .5;
			z-index: 2;
		}

		// 特に指定がない場合共通して以下を表示
	// sample image
		$sample-imgUrl: './../images/global/pageheader/bg_pageHeader02.jpg';
		$sample-width: image-width($sample-imgUrl);
		$sample-height: image-height($sample-imgUrl);
		background-image: url($sample-imgUrl);
	}

	&[data-pageheader="blog"] {
	// bg01 image
		$bg01-imgUrl: './../images/global/pageheader/bg_pageHeader.jpg';
		$bg01-width: image-width($bg01-imgUrl);
		$bg01-height: image-height($bg01-imgUrl);
		background-image: url($bg01-imgUrl);
	}

	&[data-pageheader="reminder"] ,
	&[data-pageheader="subscribe"] ,
	&[data-pageheader="contact"] {
	// bg02 image
		$bg02-imgUrl: './../images/global/pageheader/bg_pageHeader02.jpg';
		$bg02-width: image-width($bg02-imgUrl);
		$bg02-height: image-height($bg02-imgUrl);
		background-image: url($bg02-imgUrl);
	}

	&[data-pageheader="company"] {
	// bg04 image
		$bg04-imgUrl: './../images/global/pageheader/bg_pageHeader04.jpg';
		$bg04-width: image-width($bg04-imgUrl);
		$bg04-height: image-height($bg04-imgUrl);
		background-image: url($bg04-imgUrl);
	}

	&[data-pageheader="service"] {
	// bg05 image
		$bg05-imgUrl: './../images/global/pageheader/bg_pageHeader05.jpg';
		$bg05-width: image-width($bg05-imgUrl);
		$bg05-height: image-height($bg05-imgUrl);
		background-image: url($bg05-imgUrl);
	}

}
@media screen and (max-width: $breakpoint-sp) {
	.pageHeader {
		$this: &;

		&_headLine {
			padding: 20px 10px;
			max-width: none;
			height: auto;
			min-height: 85px;
		}
		&_title {
			margin-top: 0;
			@include fs(20);
		}

		&[data-pageheader] {
			min-height: 0;
			height: auto;
			background-attachment: scroll;
		}
	}
}

/*
フレーム

frame

Markup:
<div class="frame {$modifiers}">
	<div class="frame_headLine">関連サイト</div>
	<b>フレーム</b>
	<br>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
		テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
	<br>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
		テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
</div><!-- /.frame -->

.frame-alert - alert

Styleguide 4.7
============================================================ */
.frame {
	$this: &;
	margin: 20px auto;
	padding: 14px;
	border: 1px solid $gray-border;
	&:first-child {
		margin-top: 0;
	}
	&_headLine {
		position: relative;
		margin-bottom: 6px;
		color: $baseColor;
		font-weight: bold;
	}

	p:not([class]) ,
	dl:not([class]) ,
	ul:not([class]) ,
	ol:not([class]) {
		&:first-of-type {
			margin-top: 0;
		}
	}

	// layout module

	// lineout
	&-lineout {
		border: none;
	}

	// alert
	&-alert {
		color: $red;
		border-color: $red;
		#{$this}_title {
			color: $red;
			border-bottom-color: $red;
		}
	}

	// color01
	&-color01 {
		background-color: $mainColor04;
		border-color: $mainColor04;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.frame {
		$this: &;
		padding: 10px;
	}
}

/*
ページャー

pager

Markup:
<nav class="pager" data-pager="">
	<p class="pager_arrow pager_arrow-prev"><a href=""><em>前の10件</em></a></p>
	<ul class="pager_list">
		<li class="pager_item"><a href="">1</a></li>
		<li class="pager_item pager_item-dot"><span>…</span></li>
		<li class="pager_item"><a href="">2</a></li>
		<li class="pager_item"><a href="">3</a></li>
		<li class="pager_item"><a href="">4</a></li>
		<li class="pager_item is-current"><a href="">5</a></li>
		<li class="pager_item"><a href="">6</a></li>
		<li class="pager_item"><a href="">7</a></li>
		<li class="pager_item"><a href="">8</a></li>
		<li class="pager_item pager_item-dot"><span>…</span></li>
		<li class="pager_item"><a href="">9</a></li>
	</ul>
	<p class="pager_arrow pager_arrow-next"><a href=""><em>次の10件</em></a></p>
</nav><!-- /.pager -->

Styleguide 4.8
============================================================ */
.pager {
	$this: &;
	$size-number: 38px;

	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
			justify-content: center;
	-webkit-align-items: center;
			align-items: center;
	margin: 25px auto;
	&_list {
		position: relative;
		@include inline_block-p;
		margin: 0;
		// padding: 0 6.5em;
		text-align: center;
		list-style-type: none;
	}
	&_arrow ,
	&_item {
		position: relative;
		@include inline_block;
		vertical-align: middle;
		margin: 2px 8px;
		line-height: 1;
		&:first-child {
			margin-left: 0;
		}
		a,
		span {
			color: $baseColor;
			text-decoration: none;
			-webkit-transition: all .3s;
					transition: all .3s;
			i ,
			em {
				font-style: normal;
			}
		}
	}
	&_item {
		a,
		span {
			display: block;
			width: #{$size-number};
			height: #{$size-number};
			line-height: #{$size-number};
			margin: 0;
			@include fs(14);
			background-color: $gray-bg;
			-webkit-border-radius: 100%;
			        border-radius: 100%;
		}
		&.is-current {
			a,
			span {
				opacity: 1;
				color: $white;
				background-color: $mainColor01 !important;
			}
		}

		// dot
		&-dot {
			vertical-align: middle;
			margin: 0 5px;
			> a ,
			> span {
				border: none;
				background-color: transparent;
			}
			&.is-current {
				a,
				span {
					background-color: transparent;
				}
			}
		}
	}

	// prev , next
	&_arrow {
		-webkit-flex: 0 0 auto;
		    -ms-flex: 0 0 auto;
		        flex: 0 0 auto;
	    margin: 0;
		&-prev ,
		&-next {
		//	display: inline-block !important;
		//	position: absolute;
		//	top: 50%;
		//	-webkit-transform: translateY(-50%);
		//	        transform: translateY(-50%);
		  	> a ,
		  	> span {
		  		position: relative;
		  		display: inline-block;
		  		margin: 0;
		  		padding: 2px;
		  		width: #{$size-number};
		  		height: #{$size-number};
		  		@include fs(14);
		  		font-weight: normal;
		  		color: $baseColor;
		  		border: 1px solid $mainColor01;
		  		background-color: transparent;
		  		-webkit-border-radius: 100%;
		  		        border-radius: 100%;
		  		&::before ,
		  		&::after {
		  			content: "";
		  			display: inline-block;
		  			position: absolute;
		  			top: 50%;
		  			left: 50%;
		  			width: 14px;
		  			height: 14px;
		  			border-top: 1px solid $mainColor01;
		  			border-right: 1px solid $mainColor01;
		  			-webkit-transition: all .3s;
		  					transition: all .3s;
		  			-webkit-transform: translate(-50%, -50%);
		  			        transform: translate(-50%, -50%);

				}
				// > i {
				//	@include textHidden;
				// }
				> em {
					font-style: normal;
					@include textHidden;
				}
			}
		}
		&-prev {
			padding-right: 1em;
			// left: 0;
			// margin-right: 1em;
			> a ,
			> span {
				&::before {
					margin-left: 3px;
					-webkit-transform: translate(-50%, -50%) rotate(-135deg);
					        transform: translate(-50%, -50%) rotate(-135deg);
				}
				&::after {
					display: none;
				}
			}
		}
		&-next {
			padding-left: 1em;
			// right: 0;
			// margin-left: 1em;
			> a ,
			> span {
				&::before {
					display: none;
				}
				&::after {
					margin-left: -3px;
					-webkit-transform: translate(-50%, -50%) rotate(45deg);
					        transform: translate(-50%, -50%) rotate(45deg);
				}
			}
		}
	}

	// layout module

	&-placeTop {
		margin-bottom: 45px;
	}
	&-placeBtm {
		margin-top: 65px;
	}
}
// hover style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.pager {
		$this: &;
		&_arrow ,
		&_item {
			&:hover {
				a,
				span {
					opacity: 1;
					background-color: lighten($mainColor01, 60%);
				}
			}

			// dot
			&-dot {
				&:hover {
					a,
					span {
						background-color: transparent;
					}
				}
			}
		}

		// prev , next
		&_arrow {
			&-prev ,
			&-next {
				&:hover {
					a,
					span {
						color: $mainColor01;
						background-color: transparent;
						&::before ,
						&::after {
							border-top-color: $mainColor01;
							border-right-color: $mainColor01;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.pager {
		$this: &;
		$size-number: 34px;

		position: relative;
		margin: 35px auto;
		&_list {
			max-width: none;
			padding: 0 .2em;
			@include cf;
		}
		&_arrow ,
		&_item {
			a,
			span {
				@include fs(12);
			}
		}
		&_item {
			display: none;
			margin: 0 3px;
			a,
			span {
				width: #{$size-number};
				height: #{$size-number};
				line-height: #{$size-number};
			}

			// dot
			&-dot {
				display: none;
			}

			&.is-show {
				display: inline-block;
			}
		}

		// prev , next
		&_arrow {
			margin: 0;
			padding: 0;
			&-prev ,
			&-next {
				display: inline-block !important;
				position: absolute;
				top: 50%;
				padding: 0;
				-webkit-transform: translateY(-50%);
				        transform: translateY(-50%);
				> a ,
				> span {
					width: #{$size-number};
					height: #{$size-number};
					&::before ,
					&::after {
						width: 10px;
						height: 10px;
					}
				}
			}
			&-prev {
				left: 0;
			}
			&-next {
				right: 0;
			}
		}

		// layout module

		&-placeTop {
			margin-bottom: 35px;
		}
		&-placeBtm {
			margin-top: 35px;
		}
	}
}

/*
問い合わせインフォ

xxxxxxx

Markup:
<div class="contactInfo">
	<p class="contactInfo_customer">
		<small>電話でのお問い合わせ</small>
		<span class="number">03-3823-6006</span>
	</p>
	<p class="contactInfo_note">
		営業時間 9時30分~18時30分(土・日・祝定休日)
	</p>
</div><!-- /.contactInfo -->

Styleguide 4.9
============================================================ */
.contactInfo {
	$this: &;
	$gutter-h: 15px;

	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
			justify-content: center;
	-webkit-align-items: center;
			align-items: center;

	margin-top: 52px;
	padding: 35px;
	border: 1px solid $gray-border;
	background-color: $white;
	&_customer {
		position: relative;
		margin: 0;
		padding-left: calc( #{$icon-tel-width} + 20px);
		color: $mainColor01;
		font-weight: bold;
		line-height: 1.25;
		small {
			display: block;
			@include fs(18);
		}
		.number {
			display: block;
			@include fs(36);
		}
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			@include sprite($icon_tel);
			-webkit-transform: translateY(-50%);
			        transform: translateY(-50%);

		}
	}
	&_note {
		margin: 5px 0 0 2em;
		padding-top: 20px;
		@include fs(14);
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.contactInfo {
		$this: &;
		$gutter-h: 15px;

		-webkit-align-items: stretch;
				align-items: stretch;
		-webkit-flex-flow: column wrap;
		        flex-flow: column wrap;

		margin-top: 25px;
		padding: 10px;
		&_customer {
			padding-left: calc( (#{$icon-tel-width} / 1.5 ) + 10px);
			small {
				@include fs(14);
			}
			.number {
				@include fs(28);
			}
			&::before {
				@include sprite15x($icon_tel);

			}
		}
		&_note {
			margin: 10px 0 0;
			padding-top: 0;
		}
	}
}

/*
概要説明

summaryFrame

Markup:
<div class="summaryFrame">
	【Category】「説明文」○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○
	<br>○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○
</div><!-- /.summaryFrame -->

Styleguide 4.10
============================================================ */
.summaryFrame {
	$this: &;
	margin-top: 20px;
}
@media screen and (max-width: $breakpoint-sp) {
	.summaryFrame {
		$this: &;
		margin-top: 15px;
	}
}

/*
検索結果バー

resultBar

Markup:
<div class="resultBar">
	<p class="resultBar_numbers"><em class="number">〇〇</em>件</p>
	<dl class="resultBar_categorys">
		<dt><span>カテゴリ</span></dt>
		<dd>すべて</dd>
	</dl>
</div><!-- /.resultBar -->

Styleguide 4.11
============================================================ */
.resultBar {
	$this: &;
	$gutter-h: 2.4em;

	margin: 50px 0;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
			align-items: center;
	& > * {
		margin: 0;
		&:not(:first-child) {
			margin-left: #{$gutter-h};
		}
	}
	&_numbers {
		color: $mainColor01;
		@include fs(18);
		.number {
			margin-right: 5px;
			@include fs(24);
			font-weight: bold;
		}
	}
	&_categorys {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
				align-items: center;
		@include fs(16);
		& > * {
			margin: 0;
			padding: 0;
		}
		dt {
			&::after {
				content: ":";
				display: inline-block;
				vertical-align: middle;
				margin: 0 12px;
			}
		}
		dt {
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.resultBar {
		$this: &;
		$gutter-h: 1em;

		margin: 25px 0;
		-webkit-justify-content: space-between;
				justify-content: space-between;
		& > * {
			margin: 0;
			&:not(:first-child) {
				margin-left: #{$gutter-h};
			}
		}
		&_numbers {
			@include fs(13);
			.number {
				@include fs(20);
			}
		}
		&_categorys {
			padding-top: 6px;
			@include fs(13);
			dt {
				&::after {
					margin: 0 5px;
				}
			}
		}
	}
}














/*
〇〇〇〇〇〇一覧

xxxxxxx

Markup:

Styleguide 4.999999999
============================================================ */
.xxxxxxx {
	$this: &;
	$gutter-h: 15px;
	position: relative;
	margin: 30px auto 0;
	&:first-child {
		margin-top: 0;
	}
	&_inner {
		position: relative;
	}
}
@media screen and (max-width: $breakpoint-sp) {
}

@charset "UTF-8";
@import '../_config.scss';

/*
ボタン

btnStyle
*/

/*
	ボタン （ ベーススタイル ）
------------------------------------------------------------------------ */
// btn base style
%btnStyle {
	$this: &;
	$gutter-icon: .6em;
	$height-btn: 4px;

	// display: -webkit-flex;
	// display: flex;
	// -webkit-justify-content: center;
	//		justify-content: center;
	// -webkit-align-items: center;
	//		align-items: center;

	position: relative;
	display: inline-block;
	margin: auto;
	padding: 14px 1em;
	width: 100%;
	text-align: center;
	color: $white;
	@include fs(16);
	font-weight: bold;
	min-height: #{$height-btn};
	// -webkit-border-radius: #{$height-btn};
	//         border-radius: #{$height-btn};
	background-color: $gray;
	border: 1px solid transparent;
	text-decoration: none;
	outline: none transparent;
	cursor: pointer;
	-webkit-transition: all .3s cubic-bezier(.25,.74,.54,.93), width 0s;
	        transition: all .3s cubic-bezier(.25,.74,.54,.93), width 0s;
	z-index: 2;
	> span {
		position: relative;
		&::before ,
		&::after {
			content: "";
			position: relative;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
		}
	}

	// 部分的に文字を大きく
	.emTxt {
		font-size: 125%;
		font-style: normal;
		font-weight: 700;
		line-height: 1.4;
	}
}
%btnStyle-thin {
	padding-top: 4px;
	padding-bottom: 3px;
	min-height: 0;
}
%btnStyle-thick {
	padding-top: 16px;
	padding-bottom: 18px;
	min-height: 0;
	@include fs(20);
}
// for IE style
@media all and (-ms-high-contrast: none) {
}
// アイコン付きボタン 共通設定
*[class*="btn-img"] {
	> span {
		line-height: inherit;
		&::before {
			content: "";
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 0;
			-webkit-transform: translateY(-50%);
			        transform: translateY(-50%);
		}
	}
}
// hover style only
@media screen and (min-width: $breakpoint-sp + 1) {
}
@media screen and (max-width: $breakpoint-sp) {
	%btnStyle {
		@include fs(14);

		span {
			margin: auto;
		}

		// 部分的に文字を大きく
		.emTxt {
			font-size: 120%;
		}
	}
}

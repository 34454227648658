@charset "UTF-8";

// ====================================================================================
// _mixin
//
// mixin
// ====================================================================================

//
//	@@@_module
//
// breakpoints 関連
// font 関連
// color 関連
// box-shadow 関連
// drop-shadow 関連
// border-radius 関連
// transition 関連
// keyframes 関連
// hover 関連
// scrollbar 関連
//
// 汎用スタイル系
// placeholderColor 関連
// disabled 関連
//
// アクセシビリティ 関連
// js plugin only mixin
//
// gnav / dropMenu 関連
// image style 関連
//


// ====================================================================================
// breakpoints 関連
// ====================================================================================

// breakpoint style
// --------------------------------------
@mixin bp-pc-only {
	// @media screen and (min-width: ($breakpoint-sp + 1)){
	@media screen and (min-width: ($breakpoint-sp + 1)), print {
		@content;
	}
}
@mixin bp-pc-min {
	@media screen and (max-width: ($breakpoint-pc)){
		@content;
	}
}
@mixin bp-pcWide-min {
	@media screen and (max-width: ($breakpoint-wide)){
		@content;
	}
}
@mixin bp-pcWide-to-sp {
	@media screen and (min-width: ($breakpoint-sp + 1)) and (max-width: ($breakpoint-wide + $cmn-width-scrollbarW)){
		@content;
	}
}
@mixin bp-pc-to-sp {
	@media screen and (min-width: ($breakpoint-sp + 1)) and (max-width: ($breakpoint-pc + $cmn-width-scrollbarW)){
		@content;
	}
}
@mixin bp-tab {
	@media screen and (max-width: ($breakpoint-tablet)){
		@content;
	}
}
@mixin bp-sp {
	@media screen and (max-width: ($breakpoint-sp)){
		@content;
	}
}
@mixin bp-spmin {
	@media screen and (min-width: (0)) and (max-width: ($breakpoint-sp-min)){
		@content;
	}
}
@mixin print {
	@media print {
		@content;
	}
}
@mixin screen-and-print {
	@media screen and (min-width: ($breakpoint-sp + 1)), print {
		@content;
	}
}
// hover and active style
// --------------------------------------
@mixin hover {
	@include bp-pc-only {
		&:where(:any-link, :enabled, summary):hover {
			@content;
		}
	}
	@include bp-sp {
		&:where(:any-link, :enabled, summary):active {
			@content;
		}
	}
}
//	hover可能な全ての要素に hover効果を適用したい場合は以下
@mixin hover02 {
	@include bp-pc-only {
		@media (any-hover: hover) and (pointer) {
			&:hover {
				@content;
			}
		}
	}
	@include bp-sp {
		@media (any-hover: none) {
			&:active {
				@content;
			}
		}
	}
}

// data-theme
@mixin module-theme-dark {
	&[data-theme="dark"] {
		@content;
	}
}
@mixin theme-dark {
	[data-theme="dark"] {
		@content;
	}
}

// 会員・非会員
@mixin membership-members {
	[data-membership="members"] {
		@content;
	}
}
@mixin membership-guest {
	[data-membership="guest"] {
		@content;
	}
}


// ====================================================================================
// font 関連
// ====================================================================================

// font-size 関連
// -------------------------------------------------------------
/*
@mixin clamp-font-size ($min_font_size, $font_size, $max_font_size)
$min_font_size (px): 最小フォントサイズ
$font_size (vw):		 可変フォントサイズ
$max_font_size (px): 最大フォントサイズ
*/
@mixin clamp-font-size ($min_font_size, $font_size, $max_font_size) {
	$break_point_min: $min_font_size / $font_size * 100vw;
	$break_point_max: $max_font_size / $font_size * 100vw;
	font-size: $min_font_size;
	@media screen and (min-width: $break_point_min) {
		font-size: $font_size;
	}
	@media screen and (min-width: $break_point_max) {
		font-size: $max_font_size;
	}
}

//px to rem
// font-size: rem(14px, pc);
@function rem($px, $key: 'pc') {
	$value: map-get($font-size, $key);
	@return calc(($px / $value) * 1rem);
}

// base font
// @include fs-base01;
@mixin fs-base01 {
	// background-color: #ff0;
	@include vs(font-size, $cmn-width-sp+1, 12px, $cmn-width-max, 15px);
	@include bp-pc-to-sp {
		// background-color: #0fc;
		@include vs(font-size, $cmn-width-sp+1, 12px, $cmn-width-max, 14px);
	}
	@include bp-sp {
		// background-color: #f0c;
		@include vs(font-size, $cmn-width-none, 11px, $cmn-width-sp, 13px);
	}
}

// px/rem
@mixin fs($fs) {
	$rem: $fs / 10;
	font-size: #{$fs}px;
	font-size: #{$rem}rem;
}

// --- font-size(vw) 関連 --------------------
@function get_vw($size, $viewport:1200){
	$rate: 100 / $viewport;
	@return $rate * $size * 1vw;
}
// ex.) @include fs_vw(24);
@mixin fs_vw($font_size:16){
	font-size: $font_size * 1px;
	font-size: get_vw($font_size);
}

// font-family 関連
// -------------------------------------------------------------
// WebFont系 - google font
// ゴシック体
@mixin ff-YuGothic {
	font-family: "Yu Gothic", 游ゴシック, YuGothic, 游ゴシック体, メイリオ, sans-serif;
}
@mixin ff-NotoSansJP {
	font-family: 'Noto Sans JP', 'Noto Sans CJK JP', 'Yu Gothic', 游ゴシック, メイリオ, Meiryo, sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
}
@mixin ff-NotoSansCJKJP {
	font-family: 'Noto Sans CJK JP', 'Noto Sans JP', 'Yu Gothic', 游ゴシック, メイリオ, Meiryo, sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
}
// 明朝体
@mixin ff-NotoSerif {
	font-family: 'Noto Serif', 'Noto Serif JP', 'kozuka-mincho-pr6n', 'Kozuka Mincho Pro', 'Kozuka Mincho Std', '小塚明朝 Pro R', '小塚明朝 Std R', 'Hiragino Mincho Pro', 'ヒラギノ明朝 Pro W3', 'ＭＳ Ｐ明朝', 'Times', 'Times New Roman', serif ;
	font-optical-sizing: auto;
	font-style: normal;
}
@mixin ff-Mincho {
	font-family : 'kozuka-mincho-pr6n', 'Kozuka Mincho Pro', 'Kozuka Mincho Std', '小塚明朝 Pro R', '小塚明朝 Std R', 'Hiragino Mincho Pro', 'ヒラギノ明朝 Pro W3', 'ＭＳ Ｐ明朝', 'Times', 'Times New Roman', serif ;
}


// WebFont系 - google font
// @include ff-Montserrat;
@mixin ff-Montserrat {
	font-family: 'Montserrat', sans-serif;
}

// font mixin 関連
// -------------------------------------------------------------
$fa-font-path: '/assets/fonts/font-awesome';
$fa-font-size-base: map-get($font-size, 'pc');
$fa-fw-width: calc(20em / 16);

@mixin far($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}
@mixin fa($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}
@mixin fas($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}
@mixin fab($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Brands';
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}

@mixin fa-old($code) {
	content: "";
	content: $code;
	display: inline-block;
	font-family: FontAwesome;
	// font-family: 'Font Awesome 5 Free';
	// font-family: 'Font Awesome 5 Brands';
	font-style: normal;
	font-variant-caps: normal;
	font-variant-ligatures: normal;
	// font-weight: 900;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// --- How To --------------------
// @include fa($xxxxx);

$angle-right:       		'\f105';
$angle-double-right:		'\f101';
$caret-right:       		'\f0da';
$arrow-circle-right:		'\f0a9';
$angle-left:        		'\f104';
$quote-left:        		'\f10d';
$quote-right:       		'\f10e';
$tags:              		'\f02c';
$tag:               		'\f02b';
$exclamation-circle:		'\f06a';
$comment:           		'\f075';
$comment2:          		'\f0e5';
$user:              		'\f007';
$check:             		'\f00c';
$question:          		'\f29c';
$time:              		'\f00d';
$heart:             		'\f08a';
$heart2:            		'\f004';
$download:          		'\f019';
$pencil:            		'\f040';
$heartbeat:         		'\f21e';
$play:              		'\f04b';
$pause:             		'\f04c';

// sign in/out
$sign-in: 		'\f090';
$sign-out:		'\f08b';

// social icon
$facebook:   		'\f09a';
$twitter:    		'\f099';
$google-plus:		'\f0d5';
$instagram:  		'\f16d';
$youtube:    		'\f16a';
$pinterest:  		'\f231';
$share:      		'\f1e1';
$share2:     		'\f1e0';

// siteListで使用中
$th-list:		'\f00b';
$cog:    		'\f013';
$unlink: 		'\f127';


// ====================================================================================
// color 関連
// ====================================================================================

// color - グラデーション
// -------------------------------------------------------------
// @include gradient01(45,.55);
@mixin gradient01($deg,$op) {
	$angle: $deg * 1deg;
	background: rgba(200,226,245,1);
	background: linear-gradient($angle, rgba(246,218,232,$op) 0%, rgba(246,218,232,$op) 45%, rgba(255,255,255,0) 78%, rgba(255,255,255,0) 100%);
}
@mixin gradient02($deg,$op) {
	$angle: $deg * 1deg;
	background: rgb(200,226,245,1);
	background: linear-gradient($angle, rgba(200,226,245,$op) , rgba(242,168,177,$op));
}
@mixin gradient03($deg,$op) {
	$angle: $deg * 1deg;
	background: rgb(222,195,243);
	background: linear-gradient($angle, rgba(244,188,166,1), rgba(222,195,243,1), rgba(249,201,129,1), rgba(250,227,124,1), rgba(153,230,206,1), rgba(200,226,245,1));
}
@mixin grade($from, $to, $vertical: false) {
	@if $vertical {
		background: $from;
		background: -moz-linear-gradient(top, #{$from} 0%, #{$to} 100%);
		background: -webkit-linear-gradient(top, #{$from} 0%,#{$to} 100%);
		background: linear-gradient(to bottom, #{$from} 0%,#{$to} 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$from}', endColorstr='#{$to}',GradientType=0 );
	} @else {
		background: $from;
		background: -moz-linear-gradient(left, #{$from} 0%, #{$to} 100%);
		background: -webkit-linear-gradient(left, #{$from} 0%,#{$to} 100%);
		background: linear-gradient(to right, #{$from} 0%,#{$to} 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$from}', endColorstr='#{$to}',GradientType=1 );
	}
}

// 文字に対するグラデーション
// @include text-gradient01(90,1);
@mixin text-gradient01($deg,$op) {
	$angle: $deg * 1deg;
	background: linear-gradient($angle, rgba(0,142,210,$op), rgba(145,61,145,$op));
	-webkit-background-clip: text;
	color: transparent;
	// color: #f0c;
}


// color - マーカー
// -------------------------------------------------------------
// @include marker(#f00, #00f, 100% 20%);
@mixin marker($start-color, $end-color, $weight){
	// background: $start-color;
	background-image: linear-gradient(90deg, $start-color, $end-color);
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: $weight;
};
// @include color-marker01;
@mixin color-marker01 {
	@include marker(#ff0, #ff0, 100% 20%);
}


// color - 非活性リンク時カラー
// -------------------------------------------------------------
// @include inactive01;
@mixin inactive01 {
	color: $color-linkNone;
	pointer-events: none !important;
	filter: grayscale(1);
}
@mixin inactive02 {
	color: $color-linkNone;
	pointer-events: none !important;
	filter: grayscale(1);
	opacity: 0.5;
}


// ====================================================================================
// box-shadow 関連
// ====================================================================================
// @include boxShadowAuto;
@mixin boxShadowAuto {
	-webkit-box-shadow: none;
	        box-shadow: none;
}
$box-shadow: 1px 1px 1px rgba(0,0,0,0.25);
@mixin boxShadow01 {
	-webkit-box-shadow: 8px 8px 8px rgba(#c3c3c3 , .7);
	        box-shadow: 8px 8px 8px rgba(#c3c3c3 , .7);
}
$input-shadow: 1px 1px 1px rgba(0,0,0,0.2) inset;


// ====================================================================================
// drop-shadow 関連
// ====================================================================================
// @include boxShadowNone;
@mixin dropShadowNone {
	filter: drop-shadow(0 0 0 $color-clear);
}
// @include dropShadow01;
@mixin dropShadow01 {
	filter: drop-shadow(0 7px 14px #ccc);
}


// ====================================================================================
// border-radius 関連
// ====================================================================================
// @include brRNone;
@mixin brRNone {
	-webkit-border-radius: 0px;
			border-radius: 0px;
}
// @include brInfinity;
@mixin brInfinity {
	// border-radius: calc(1px / 0);
	border-radius: calc(100vh * 1);
}
// @include br01;
@mixin br01 {
	@include bp-pc-only {
		border-radius: 18px;
	}
	@include bp-sp {
		border-radius: 10px;
	}
}
// @include br02;
@mixin br02 {
	@include bp-pc-only {
		border-radius: 58px 58px 0 0;
	}
	@include bp-sp {
		border-radius: 28px 28px 0 0;
	}
}


// ====================================================================================
// transition 関連
// ====================================================================================
@mixin transitionType01 {
	-webkit-transition: .3s;
			transition: .3s;
}
@mixin transitionType02 {
	-webkit-transition: all 0.5s ease 0s;
			transition: all 0.5s ease 0s;
}
@mixin transitionType03 {
	-webkit-transition: .3s cubic-bezier(0.42, 0, 0.24, 1.01);
			transition: .3s cubic-bezier(0.42, 0, 0.24, 1.01);
}
@mixin transitionType04 {
	-webkit-transition: .4s cubic-bezier(0.74, 0, 0.13, 1.13);
			transition: .4s cubic-bezier(0.74, 0, 0.13, 1.13);
}
// @include transitionOpt01(
// 	('transform', 1.2, ease-in, 0.1),
// 	('top', 1.2, cubic-bezier(0.51, 0, 0.21, 0.97), 0)
// );
@mixin transitionOpt01($opts...) {
  $transitions: '';
	$default-timing-function: cubic-bezier(0.51, 0, 0.21, 0.97);
  @each $opt in $opts {
    $property: nth($opt, 1);
    $duration: nth($opt, 2);
    // タイミング関数のデフォルトを設定（nthで3番目がなければデフォルトを使用）
    $timing-function: if(length($opt) > 2, nth($opt, 3), $default-timing-function);
    // 遅延時間を取得（4番目がなければ0を使用）
    $delay: if(length($opt) > 3, nth($opt, 4), 0);

    // 最初の要素の前にはカンマを追加しない
    @if $transitions == '' {
      $transitions: #{$property} #{$duration}s #{$timing-function} #{$delay}s;
    } @else {
      $transitions: #{$transitions}, #{$property} #{$duration}s #{$timing-function} #{$delay}s;
    }
  }
  -webkit-transition: $transitions;
  transition: $transitions;
}
@mixin transitionOpt02($opt, $dur, $delay) {
	-webkit-transition: #{$opt} #{$dur}s cubic-bezier(0.51, 0, 0.21, 0.97) #{$delay}s;
	transition: #{$opt} #{$dur}s cubic-bezier(0.51, 0, 0.21, 0.97) #{$delay}s;
}
@mixin transitionInvalid {
	-webkit-transition: 0s;
			transition: 0s;
}

// ====================================================================================
// keyframes 関連
// ====================================================================================
@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}
	@keyframes #{$name} {
		@content;
	}
}

// ====================================================================================
// hover 関連
// ====================================================================================
@mixin hoverStyle01 {
	padding: 0 .3em;
	background-image: linear-gradient(to left, rgba(0,0,0,0) 50%, rgba($mainColor05,1) 50%);
	background-position: 100% 0;
	background-size: 200% auto;
	transition: .3s;
	&:hover {
		opacity: 1;
		background-position: 0 0;
	}
}
@mixin hoverTxtStyle01 {
	text-decoration: none;
	background: linear-gradient($mainColor01, $mainColor01) 0 100%/0 2px no-repeat;
	transition: background .4s;
	@include hover {
		background-size: 100% 2px;
		text-decoration: none;
	}
}
@mixin hoverImgStyle01 {
	@include hover {
		img {
			-webkit-transform: scale(1.05);
							transform: scale(1.05);
			-webkit-transition: 2s;
							transition: 2s;
		}
	}
}

// ====================================================================================
// scrollbar 関連
// ====================================================================================
// @include scrollbar(auto, scroll);
@mixin scrollbar($ofx, $ofy) {
	// background-color: $testColor !important;
	position: relative;
	overflow-x: $ofx;
	overflow-y: $ofy;
	-webkit-overflow-scrolling: touch;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	// scrollbar-color: gray $gray-bg;
	// scrollbar-width: thin;
	&::-webkit-scrollbar {
		width: 7px;
	}
	&::-webkit-scrollbar-thumb {
		width: 7px;
		background-color: $gray;
		-webkit-border-radius: 10px;
		        border-radius: 10px;
	}
	&::-webkit-scrollbar-track {
		background-color: $white;
		-webkit-border-radius: 10px;
		        border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb {
	}
}
@mixin scrollbar02 {
	// moz
	scrollbar-color: gray $gray-bg;
	scrollbar-width: thin;
	// webkit
	&::-webkit-scrollbar {
		cursor: pointer;
		width: 5px;
		height: 7px;
	}
	&::-webkit-scrollbar-thumb {
		border-image-source: initial;
		border-image-slice: initial;
		border-image-width: initial;
		border-image-outset: initial;
		border-image-repeat: initial;
		z-index: 9999;
		background-clip: content-box;
		width: 5px;
		background-color: $gray;
		// background-color: $red;
		border-width: 1px;
		border-style: solid;
		border-color: transparent;
		// border-radius: 5px;
		margin: 1px !important;
	}
	&::-webkit-scrollbar-track {
		background-color: $gray-bg;
		// background-color: $cream;
		cursor: pointer;
	}
	// // IE
	// scrollbar-width: 2px;
	// scrollbar-base-width: 2px;
	// scrollbar-base-color: $gray-bg;
	// scrollbar-arrow-color: $gray-bg;
	// scrollbar-face-color: $gray;
}
@mixin tab-scrollbar {
	$scrollbarWidth: 5px;
	position: relative;
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	&::-webkit-scrollbar {
		width: #{$scrollbarWidth};
		height: #{$scrollbarWidth};
	}
	&::-webkit-scrollbar-thumb {
		background-color: $gray-exLight;
		width: #{$scrollbarWidth};
		-webkit-border-radius: #{$scrollbarWidth};
		        border-radius: #{$scrollbarWidth};
	}
	&::-webkit-scrollbar-track {
		background-color: $color-clear;
		-webkit-border-radius: #{$scrollbarWidth};
		        border-radius: #{$scrollbarWidth};
	}
}
@mixin scrollSnap {
	flex-wrap: nowrap;
	padding-bottom: 8px;
	overflow-x: scroll;
	overscroll-behavior: auto;
	scroll-snap-type: x mandatory;
	> * {
		flex-shrink: 0;
		scroll-snap-align: center;
	}
}


// ====================================================================================
// 汎用スタイル系
// ====================================================================================

// inner 関連
// -------------------------------------------------------------
// @include contentInner;
@mixin contentInner {
	@include bp-pc-only {
		margin: 0 auto;
		max-width: $cmn-width-base;
	}
}
// @include contentFull;
@mixin contentFull {
	@include bp-pcFull-to-pc {
		margin-right: calc(50% - 50vw);
		margin-left: calc(50% - 50vw);
		padding-left: calc(50vw - 50%);
		padding-right: calc(50vw - 50%);
	}
	@include bp-pc-to-sp {
		margin-right: -1em;
		margin-left: -1em;
		padding-left: 1em;
		padding-right: 1em;
	}
	@include bp-sp {
		margin-right: -1em;
		margin-left: -1em;
		padding-left: 1em;
		padding-right: 1em;
	}
}

// position 関連
// -------------------------------------------------------------
// absolute all 0
// @include absolute(auto, 0, 0, 0);
@mixin absolute($t, $r, $b, $l) {
	position: absolute;
	top: #{$t};
	right: #{$r};
	bottom: #{$b};
	left: #{$l};
}

// clearfix
// -------------------------------------------------------------
@mixin cf {
	&::before,
	&::after {
		// content: ".";
		content: "";
		display: block;
		height: 0;
		visibility: hidden;
		clear: both;
		height: 0;
		line-height: 0;
	}
	&::after {
		content: "";
		clear: both;
	}
}

// テキスト非表示・表示 関連
// -------------------------------------------------------------
@mixin textHidden {
	// text-indent: 110%;
	text-indent: -9999px;
	display: none;
	white-space: nowrap;
	overflow: hidden;
	display: none\9;
	opacity: 0;
}
@mixin textShow {
	text-indent: inherit;
	display: inline-block;
	white-space: normal;
	overflow: visible;
	display: inline-block\9;
	opacity: 1;
}

// 単語処理 関連
// -------------------------------------------------------------
// 三点リーダー --------------------
@mixin textEndLeader {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

// 単語の強制折り返し --------------------
@mixin wordRap {
	word-wrap: break-word;
	white-space: normal;
	word-break: break-all;
}

// 単語の強制折り返し禁止 --------------------
@mixin wordNorap {
	white-space: nowrap;
}

// 指定行数の処理 --------------------
// ex.) @include lineClamp(3);
@mixin lineClamp ($number) {
	$number: $number;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: #{$number};
	-webkit-box-orient: vertical;
	min-height: 0;
}

// display : inline_block 関連 --------------------
// 親設定
@mixin inline_block-p {
	letter-spacing: -.44em;
}
// 子設定
@mixin inline_block {
	display: inline-block;
	letter-spacing: normal;
	vertical-align: middle;
	// font-size: 100%;
	// *display: inline;
	// *zoom: 1;
}


// ====================================================================================
// placeholderColor 関連
// ====================================================================================
@mixin placeholderColor($color) {
	&:placeholder-shown {
		color: $color;
	}
	&::-webkit-input-placeholder {
		color:$color;
	}
	&:-moz-placeholder {
		color:$color;
		opacity: 1;
	}
	&::-moz-placeholder {
		color:$color;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color:$color;
	}
}


// ====================================================================================
// disabled 関連
// ====================================================================================
@mixin disabledType01 {
	pointer-events: none !important;
	color: $color-disabled !important;
	opacity: .6 !important;
	cursor: default !important;
	background: none !important;
	background-color: $gray-exLight !important;
	border-color: $gray-exLight !important;
	-webkit-box-shadow: none !important;
			box-shadow: none !important;
	&:hover {
		-webkit-transform: translateY(0) !important;
				transform: translateY(0) !important;
	}
}


// ====================================================================================
// アクセシビリティ 関連
// 視覚的には隠れているが、スクリーンリーダーなどの支援技術には表示される要素
// ====================================================================================
// (スクリーンリーダなど)を利用できるようにしながら、要素を視覚的に隠す
@mixin visually-hidden {
	position: absolute !important;
	margin: -1px !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	border: 0 !important;
	clip: rect(0, 0, 0, 0) !important;
	overflow: hidden !important;
	white-space: nowrap !important;
}
// デフォルトでは要素を視覚的に非表示にすることができるが、 (キーボードのみのユーザなど) フォーカスされたときに表示することが可能
@mixin visually-hidden-focusable {
	position: static;
	margin: 0;
	width: auto;
	height: auto;
	clip: auto;
	overflow: visible;
	white-space: normal;
}


// ====================================================================================
// js plugin only mixin
// ====================================================================================
// slick slider
@mixin slickInitMask {
	position: relative;
	margin: 1px;
	flex: 0 0 auto;
	overflow: hidden;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 5;

		background:
			linear-gradient(0.25turn, transparent, #fff, transparent);
		background-color: #eee;
		background-repeat: no-repeat;
		background-size: 40% 100%;
		background-position: -105% 0;
		animation: slickInitMaskLoading 1.5s infinite;
	}
}
@keyframes slickInitMaskLoading {
	to {
		background-position: 105% 0;
	}
}

@mixin slickVisible {
	.slick-track {
		.slick-loading & {
			visibility: visible !important;
		}
	}
	.slick-slide {
		.slick-loading & {
			visibility: visible !important;
		}
	}
}

@mixin tag {
	padding: 0 10px;
	border-radius: 0 23px 23px 23px;
	background: $aqua-exLight;
	font-size: rem(12px, pc);
	text-decoration: none;
}

@mixin wavePC {
	content: '';
	display: block;
	width: 100%;
	height: 31px;
	position: absolute;
	left: 0;
	mask: url('/assets/images/global/wave-pc.svg') repeat-x center bottom / 480px 31px;
}
@mixin waveSP {
	height: 13px;
	mask: url('/assets/images/global/wave-sp.svg') repeat-x center bottom / 120px 13px;
}


// ====================================================================================
// gnav / dropMenu 関連
// ====================================================================================
// @include dropMenu-slideDrop;
@mixin dropMenu-slideDrop {
	display: flex;
	justify-content: flex-end;

	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	min-height: 0;
	height: 100lvh;
	line-height: 1.5;

	opacity: 0;
	visibility: hidden;
	pointer-events: none;

	-webkit-transition: 0.1s;
					transition: 0.1s;
	[data-gnav-drop-inner] {
		@include scrollbar(auto, scroll);
		height: 100lvh;
		-webkit-transform: translate(100vw, 0);
						transform: translate(100vw, 0);

		-webkit-transition: transform 0.6s;
						transition: transform 0.6s;
		&::-webkit-scrollbar {
			width: 7px;
		}
		&::-webkit-scrollbar-thumb {
			width: 7px;
		}
		&::-webkit-scrollbar-track {
		}
	}
}

// @include dropdownStyleType01;
@mixin dropdownStyleType01 {
	display: none;
	position: relative;
	top: auto;
	left: auto;
	right: auto;
	z-index: $zi-gNavDrop;

	max-height: none;

	opacity: 1;
	visibility: visible;
	overflow: visible;
	pointer-events: auto;
	-webkit-transition: none;
					transition: none;
}


// ====================================================================================
// image style 関連
// ====================================================================================
// @include noImageStyle;
@mixin noImageStyle {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

//  width
// ----------------------------------------------------------------------
$cmn-width-max: 1920px;
$cmn-width-wide: 1450px;
$cmn-width-base: 1200px;
$cmn-width-tablet: 960px;
$cmn-width-sp: 736px;
$cmn-width-sp-min: 560px;
$cmn-width-none: 1px;

$cmn-width-scrollbar: 17px;
$cmn-width-scrollbarW: 34px;

$baseWidth: 1200px;
$tabletWidth: 960px;
$spWidth: 736px;
$minSpWidth: 560px;


// layoutColumn用 width設定
$layC-side-width: 260px;
$layC-side-gutter-h: 40px;
$layC-main-width: $baseWidth - $layC-side-width - $layC-side-gutter-h;
$layC-side-widthPer: floor((( 260px / $baseWidth ) * 10000% )) / 100;
$layC-side-gutter-hPer: floor((( 40px / $baseWidth ) * 10000% )) / 100;
$layC-main-widthPer: 100% - $layC-side-widthPer - $layC-side-gutter-hPer;
// $layC-main-widthPer02: 100% - $layC-side-widthPer - $layC-side-gutter-hPer - $layC-side-widthPer - $layC-side-gutter-hPer;

// -----------------------

$page-width: 1200px;
$main-width: 900px; //仮
$side-width: 240px; //仮


//  height
// ----------------------------------------------------------------------
$fixedHeight: 52px;


//  breakpoints
// ----------------------------------------------------------------------
$breakpoint-max: $cmn-width-max;
$breakpoint-wide: $cmn-width-wide;
$breakpoint-pc: $cmn-width-base;
$breakpoint-tablet: $cmn-width-tablet;
$breakpoint-sp: $cmn-width-sp;
$breakpoint-sp-min: $cmn-width-sp-min;
/* $breakpoint-minSp: $minSpWidth; */


//  共通gutter設定
// ----------------------------------------------------------------------
$gutter-gSide: 16px;
$gutter-gSide02: 30px;


//  size
// ----------------------------------------------------------------------
$font-size: (
	'pc': 16px,
	'sp': 14px,
) !default;
$lineHeight: calc(30/16);


//  colors
// ----------------------------------------------------------------------

// @theme別カラー管理
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
$theme-colors: (
	base: #333,
	link: #000,
	link02: #3AA6B9,
);
$dark-theme-colors: (
	base: #efefef,
	link: #5280DD,
	link02: #ffff00,
);
:root {
	// Light theme
	@each $key, $color in $theme-colors {
		--color-#{$key}: #{$color};
	}
	// Dark theme
	&[data-theme='dark'] {
		@each $key, $color in $dark-theme-colors {
			--color-#{$key}: #{$color};
		}
	}
}

$testColor: #3ed;

$color-common: #333;
$txtColor: var(--color-base);
$color-base: var(--color-base);
$color-link: var(--color-link);
$color-link02: var(--color-link02);
// $color-linkNone: #a5a5a5;
$color-clear: transparent;
$color-disabled: #a5a5a5;
$color-focus: #fdfdef;

$color-overlayBg: rgba(#535353, .2); //仮
$color-overlayBg02: rgba(#ffffff, .9); //仮
$color-imageBg: #e3e3e3;
$color-mask01: rgba(#000, .7);

$overlayBg: rgba(#535353, .5);
$overlayBg02: rgba(#535353, .7);

$white: #fff;
$black: #000;

$red: #f43838;
$red-dark: #901414;

$blue: #00e;
$blue-dark: #192f60;

$cream: #fff799;
$cream-dark: #eddc44;

$yellow: #ffff00;

$baseColor: #333333;
$linkColor: #253f68;
$pcOverlayBgColor: rgba(0,0,0,.9);
$pcOverlayBgColor_w: rgba(255, 255, 255,.9);
$spOverlayBgColor: rgba(0,0,0,.9);
$spOverlayBgColor_w: rgba(255, 255, 255,1);

// mainカラー設定
$mainColor01: #253f68;
$mainColor02: #16253e;
$mainColor03: #ef7f19;
$mainColor04: #e9f2f9;
$mainColor05: #aac6e4;

// grayカラー設定
$gray: #969696;
$gray-light: #c3c3c3;
$gray-exLight: #e3e3e3;
$gray-dark: #4c4c4c; // dummy
$gray-exDark: #2b2b2b; // dummy
$gray-border: #c3c3c3;
$gray-dot: #969696;
$gray-bg: #f6f6f6;

// enqueteテンプレート用
$gray-border: #ddd;
$gray: #888;
$red: #e00;
$overlayBg: rgba(#000, .8);

// socialカラー設定
$color-social01: #1877f2; // @ Facebook
$color-social02: #1da1f2; // @ twitter
$color-social03: #FF0000; // @ Youtube
$color-social04: #00b900; // @ LINE
$color-social05: #D93177; // @ Instagram
$color-social06: #E60023; // @ Pinterest
$color-social07: #DA4742; // @ google plus
$color-social08: #00a4de; // @ hatena plus
$color-social09: #E54254; // @ Pocket plus
$color-social10: #000000; // @ X(旧twitter)


// path 関連
// ----------------------------------------------------------------------
$scssImgPath: './../images/';
$imgPath: './../images/';
// $imgPath: '/assets/images/';


//  font
// ----------------------------------------------------------------------


//  transition
// ----------------------------------------------------------------------
$cubic-bezier01: cubic-bezier(0.51, 0, 0.21, 0.97);


// z-index 関連
// ----------------------------------------------------------------------
$zi-gHeader: 100;// 基準順序
$zi-gNav: 100;// 基準順序
$zi-gNav-isOpen: $zi-gNav + 10; // SPハンバーガーメニュー開閉時
$zi-gNavBar: $zi-gNav + 5; // SPハンバーガーボタン ： 基準順序より上
$zi-overlay: $zi-gNav - 1;
$zi-isFixedPc: $zi-gHeader + 5; // .gHeaderレイヤーより上
$zi-isFixedSp: $zi-gNav + 5; // .gNavレイヤーより上
$zi-gNavDrop: $zi-gHeader + 5; // .gHeaderレイヤーより上
$zi-bodyOverlay: $zi-overlay - 5; // $zi-overlayより下

$zi-datepicker: 25;

$zi-pageTop: 25;
$zi-loader: 99999; // 最上部設定 // 99999

$zi-sticky: 50;

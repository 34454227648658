@charset "UTF-8";
@import '../_config.scss';

/*
js関連スタイル

js関連用カスタムCSS
*/

/*
	No.001: slick
	-----------------
	No.101: jQuery ui : none
	-----------------
*/

/*
	No.001: slick
	- base style -
------------------------------------------------------------------------ */

// slick : スライダーitemの親
// -----------------------------------------
.slick-slider {
	height: 0;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity 1s, visibility 1s linear !important;
	        transition: opacity 1s, visibility 1s linear !important;
	// slick-initializedが付与されたら表示
	&.slick-initialized{
		height: auto;
		opacity: 1;
		visibility: visible;
	}
	&.slick-dotted {
		margin: 0;
	}
}

// slick : スライダーitem
// -----------------------------------------
.slick-slide {
	// opacity: .7;
	outline: none !important;
	border: none;
	outline: none;
	cursor: move;
	a {
		display: inline-block;
		-webkit-transition: all .2s;
		        transition: all .2s;
		&:hover {
			filter: alpha(opacity=70); /*IE*/
			-moz-opacity: 0.70; /*Firefox*/
			opacity: 0.7; /*Opera・Safari*/
			img {
				filter: alpha(opacity=80); /*IE*/
				-moz-opacity: 0.80; /*Firefox*/
				opacity: 0.8; /*Opera・Safari*/
				display: inline-block;
				zoom: 1;
			}
		}
	}
	a {
		img {
			-webkit-transition: all .2s;
			        transition: all .2s;
			backface-visibility: hidden;
		}
	}
}
.slick-center ,
.slick-active ,
.slick-current {
}

// slick : slick arrow
// -----------------------------------------
$slickArrow-size: 36px;
.slick-prev ,
.slick-next {
	width: #{$slickArrow-size};
	height: 100%;
	color: $white;
	background-color: transparent;
	cursor: pointer;
	-webkit-transition: opacity 1s;
	        transition: opacity 1s;
	z-index: 10;
	&::before {
		content: "";
		position: relative;
		width: #{$slickArrow-size};
		height: #{$slickArrow-size};
		color: $white;
		@include fs(32);
		line-height: #{$slickArrow-size};
		font-weight: normal;
		background-color: rgba($gray, .8);
		-webkit-border-radius: 100%;
		        border-radius: 100%;
		-webkit-transition: all .3s;
		        transition: all .3s;
	}
	&:focus ,
	&:hover {
		&::before {
			background-color: transparent !important;
		}
	}
}
.slick-prev {
	&::before {
		padding-right: 3px;
		@include fa-old($angle-left);
	}
}
.slick-next {
	&::before {
		padding-left: 3px;
		@include fa-old($angle-right);
	}
}

// slick : slick dots
// -----------------------------------------
$slickDot-size: 14px;
// $slickDot-height: 10px;
$slickDot-radius: 50%;
.slick-dots {
	bottom: -30px;
	margin-top: 0;
	> li {
		margin: 0 12px;
		width: #{$slickDot-size};
		height: #{$slickDot-size};
		-webkit-border-radius: #{$slickDot-radius};
		        border-radius: #{$slickDot-radius};
		-webkit-transform: scale(1);
		    -ms-transform: scale(1);
		        transform: scale(1);
		-webkit-transition: .3s;
		        transition: .3s;
		overflow: hidden;
	}
	> li button {
		padding: 0;
		width: #{$slickDot-size};
		height: #{$slickDot-size};
		background: $gray-bg;
		border: 1px solid $gray;
		-webkit-border-radius: #{$slickDot-radius};
		        border-radius: #{$slickDot-radius};
	}
	> li button:before {
		display: none;
	}

	> li.slick-active button  {
		background-color: $linkColor;
	}
}
// hover style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	// slick : slick arrow
	// -----------------------------------------
	.slick-prev ,
	.slick-next {
		&:hover ,
		&:focus {
			color: $white;
			opacity: .9;
			&::before {
				background-color: rgba($gray, 1);
			}
		}
	}
	// slick : slick dots
	// -----------------------------------------
	.slick-dots {
		> li button:hover {
			background-color: $gray;
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	// slick : スライダーitemの親
	// -----------------------------------------

	// slick : スライダーitem
	// -----------------------------------------

	// slick : slick arrow
	// -----------------------------------------
	$slickArrow-size: 28px;
	.slick-prev ,
	.slick-next {
		width: #{$slickArrow-size};
		z-index: 10;
		&::before {
			width: #{$slickArrow-size};
			height: #{$slickArrow-size};
			@include fs(26);
			line-height: #{$slickArrow-size};
		}
	}

	// slick : slick dots
	// -----------------------------------------
	$slickDot-size: 12px;
	.slick-dots {
		bottom: -20px;
		> li {
			margin: 0 5px;
			width: #{$slickDot-size};
			height: #{$slickDot-size};
		}
		> li button {
			width: #{$slickDot-size};
			height: #{$slickDot-size};
		}
		> li button:before {
			width: #{$slickDot-size};
			height: #{$slickDot-size};
		}
	}
}



// ------------------------------------------ jQuery ui ------------------------------------------

/*
	No.101: jQuery ui : none
	- base style -
------------------------------------------------------------------------ */

@charset "UTF-8";
@import '../_config.scss';

/*
wysiwygエディタで振られるclassに対するstyle

wysiwyg Class Style
*/

/*
	.youtube
------------------------------------------------------------------------ */
body:not(.mce-content-body) {
	.youtube {
		position: relative;
		width: 100%;
		padding-top: 56.25%;
	}
	.youtube iframe {
		position: absolute;
		top: 0;
		right: 0;
		width: 100% !important;
		height: 100% !important;
	}
}

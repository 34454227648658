@charset "UTF-8";
@import '_config.scss';

/*
slider系モジュール

slider系モジュール

Styleguide 7.
*/

/*
blogSlider

blogスライダー

Markup:
<div class="blogSlider">
	<div class="blogSlider_slider" data-slcslider="blogSlider">
		<article class="blogSlider_sliderItem">
			<div class="blogSlider_inside">
				<div class="blogSlider_hero">
					<a href="xxx" class="blogSlider_figure">
						<img src="<%= siteRoot %>assets/images/___sample/img_sample03.png" alt="">
					</a>
				</div>
				<div class="blogSlider_card">
					<p class="blogSlider_tags">
						<span class="tag tag-category02" style="background: #458bdf; border-color: #458bdf; color: #fff">お役立ち情報</span>
					</p>
					<p class="blogSlider_title"><a href="xxx"><span>技能者の処遇改善に加点など検討／地域団体のICT機器導入を補助 他｜建設業界ニュースまとめ</span></a></p>
					<div class="blogSlider_metas">
						<p class="blogSlider_dates"><time class="date">2020.00.00</time></p>
						<p class="blogSlider_tags">
							<span class="tag tag-ad">広告</span>
						</p>
					</div>
					<p class="blogSlider_lead">
						テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
						<br>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
					</p>
				</div>
			</div>
		</article>
		<% for (var i = 0; i < 4; i++) { %>
		<article class="blogSlider_sliderItem">
			<div class="blogSlider_inside">
				<div class="blogSlider_hero">
					<a href="xxx" class="blogSlider_figure">
						<img src="https://satyr.dev/400x268/<%= i+1 %>?texture=cross" alt="">
					</a>
				</div>
				<div class="blogSlider_card">
					<p class="blogSlider_tags">
						<span class="tag tag-category02" style="background: #458bdf; border-color: #458bdf; color: #fff">カテゴリ</span>
					</p>
					<p class="blogSlider_title"><a href="xxx"><span>タイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトル</span></a></p>
					<div class="blogSlider_metas">
						<p class="blogSlider_dates"><time class="date">2020.00.00</time></p>
						<p class="blogSlider_tags">
							<span class="tag tag-ad">分類（広告）</span>
						</p>
					</div>
					<p class="blogSlider_lead">
						テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
						<br>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
					</p>
				</div>
			</div>
		</article>
		<% } %>
		<article class="blogSlider_sliderItem">
			<div class="blogSlider_inside">
				<div class="blogSlider_hero">
					<figure class="blogSlider_figure blogSlider_figure-noimage">
						<img src="/assets/images/noimage.jpg" loading="eager" alt="ダミーイメージ">
					</figure>
				</div>
				<div class="blogSlider_card">
					<p class="blogSlider_tags">
						<span class="tag tag-category02" style="background: #458bdf; border-color: #458bdf; color: #fff">カテゴリ</span>
					</p>
					<p class="blogSlider_title"><span>【表示のみの場合】 ○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○</span></p>
					<div class="blogSlider_metas">
						<p class="blogSlider_dates"><time class="date">2020.00.00</time></p>
						<p class="blogSlider_tags">
							<span class="tag tag-ad">分類（広告）</span>
						</p>
					</div>
					<p class="blogSlider_lead">
						テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
						<br>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
					</p>
				</div>
			</div>
		</article>
	</div>
</div><!-- /.blogSlider -->

Styleguide 7.1
============================================================ */
.blogSlider {
	$this: &;
	$gutter-h: 20px;
	$side-gutter-h: 35px;
	$side-width: 380px;

	padding: 0 7%;
	&_slider {
		margin: 0;
		opacity: 0;
		visibility: hidden;
		&.slick-initialized ,
		&.is-view {
			opacity: 1;
			visibility: visible;
		}
	}
	&_sliderItem {
	}
	&_inside {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: stretch;
				align-items: stretch;
		padding-bottom: 10px;
	}

	// _hero area
	&_hero {
		width: #{$side-width};
		min-width: #{$side-width};
		margin-right: #{$side-gutter-h};
	}
	&_figure {
		@include aspectRatio(380, 254, contain);
		text-decoration: none;
		@include hoverImgStyle01;
		@include boxShadow01;
		// img {
		// 	@include transitionType02;
		// }
		&-noimage {
			@include noImageStyle;
		}
	}

	// _card area
	&_card {
		width: 100%;
	}
	&_title {
		margin: 0;
		@include fs(24);
		font-weight: bold;
		line-height: 1.35;
		&:not(:first-child) {
			margin-top: 10px;
		}
		a {
			display: inline;
			color: inherit;
			@include hoverTxtStyle01;
		}
	}

	// _metas area
	&_metas {
		display: -webkit-flex;
		display: flex;
		align-items: center;
		gap: 5px 1em;
		margin: 0;
		@include fs(14);
		&:not(:first-child) {
			margin-top: 10px;
		}
	}

	// _tags area
	&_tags {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 3px;
		margin: 0;
		.tag {
		}
	}

	// _dates area
	&_dates {
		flex: 0 0 auto;

		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 5px;

		margin: 0;
		color: $gray;
		@include ff-Montserrat;
		font-weight: bold;
		white-space: nowrap;
	}

	&_lead {
		margin: 0;
		@include fs(18);
		line-height: 1.8;
		@include lineClamp(4);
		&:not(:first-child) {
			margin-top: 20px;
		}
	}

	/* slickSlider base parts ( slider arrows set )
	--------------------------------------------*/
	// icon image
		$slickArrow-width: 60px;
		$slickArrow-height: 60px;

	&_arrow {
		display: block;
		position: absolute;
		top: 50%;
		padding: 0;
		width: #{$slickArrow-width};
		height: #{$slickArrow-height};
		background-color: $mainColor01;
		border: 1px solid $mainColor01;
		outline: none;
		cursor: pointer;
		-webkit-border-radius: 100%;
		        border-radius: 100%;
		-webkit-transform: translate(0,-50%);
		        transform: translate(0,-50%);
		-webkit-transition: 2s;
		        transition: 2s;
		z-index: 5;
		&::before {
			display: none;
		}
		&::after {
			content: "";
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 20px;
			height: 20px;
			border-top: 3px solid $white;
			border-right: 3px solid $white;
			@include transitionType01;
		}
		> span {
			display: none;
		}
		&-prev {
			left: calc( -#{$slickArrow-width} - 5px);
			&::after {
				margin-left: 2px;
				-webkit-transform: translate(-50%, -50%) rotate(-135deg);
				        transform: translate(-50%, -50%) rotate(-135deg);
			}
		}
		&-next {
			right: calc( -#{$slickArrow-width} - 5px);
			&::after {
				margin-left: -2px;
				-webkit-transform: translate(-50%, -50%) rotate(45deg);
				        transform: translate(-50%, -50%) rotate(45deg);
			}
		}
		&:hover {
			background-color: $black;
			border-color: $black;
		}
	}

	 // layout module
	 &-btmLine {
		padding-bottom: 50px;
		border-bottom: 1px solid $gray-border;
	 }
}
@media screen and (min-width: $breakpoint-sp + 1) {
	.blogSlider {
		$this: &;

		&-blogTop {
			padding: 0 4%;
		}
	}
}
// @media screen and (max-width: $breakpoint-pc) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.blogSlider {
		$this: &;
		$gutter-h: 20px;

		padding: 10px 2% 0;

		&_inside {
			flex-direction: column;
			gap: 10px;
		}

		// _hero area
		&_hero {
			width: 100%;
			min-width: 0;
			margin-right: 0;
			padding: 0 1em;
			text-align: center;
		}

		// _card area
		&_card {
			width: 100%;
			padding: 0 10px;
		}
		&_title {
			@include fs(17);
		}

		// _metas area
		&_metas {
			gap: 5px 10px;
			@include fs(13);
		}

		&_lead {
			@include fs(14);
			line-height: 1.6;
			&:not(:first-child) {
				margin-top: 10px;
			}
		}

		/* slickSlider base parts ( slider arrows set )
		--------------------------------------------*/
		// icon image
			$slickArrow-width: 30px;
			$slickArrow-height: 30px;

		&_arrow {
			top: calc( -#{$slickArrow-width} / 1.5 );
			left: auto;
			width: #{$slickArrow-width};
			height: #{$slickArrow-height};
			&::after {
				width: 10px;
				height: 10px;
				border-top-width: 3px;
				border-right-width: 3px;
			}
			&-prev {
				right: calc( #{$slickArrow-width} + 10px );
			}
			&-next {
				right: 0;
			}
		}

		// layout module
		&-btmLine {
			padding-bottom: 50px;
			border-bottom: 1px solid $gray-border;
		}
	}
}

@charset "UTF-8";
@import '_config.scss';

/*
グローバル

gHeader / gNav / gDropMenu / gFooter / etc...
大まかなレイアウトもここに

Styleguide 2.
*/

// #dropmenu01 {
//	opacity: 1;
//	visibility: visible;
//	pointer-events: auto;
//	display: block;
//	max-height: none;
// }


/*
wrapper

wrapper

Markup:
<div class="wrapper"></div><!-- /.wrapper -->

Styleguide 2.1
============================================================ */
.wrapper {
	$this: &;
}
@media screen and (max-width: $breakpoint-sp) {
}
@media print {
	.wrapper {
		margin: 0 auto !important;
		width: 980px !important;
	}
}

/*
コンテナ

container

Markup:
<div class="container">
</div><!-- /.container -->

Styleguide 2.2
============================================================ */
.container {
	$this: &;
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
layoutColumn

layoutColumn

Markup:

Styleguide 2.3
============================================================ */
.layoutColumn {
	$this: &;
	$gutter-com: 1em;

	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
			justify-content: space-between;
	-webkit-align-items: stretch;
			align-items: stretch;

	margin: 0 auto;
	padding: #{$gutter-gSide02} #{$gutter-gSide02} calc( #{$gutter-gSide02} * 3 );
	max-width: #{$baseWidth};
	@include cf;
	& > * {
																								// border: 1px solid red;
		position: relative;
		&::after {
			display: flex;
			justify-content: center;
			align-items: center;

			content: "";
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			opacity: .5;
			color: #555;
			font-size: 80px;
			text-align: center;

			display: none;
		}
	}

	&_mainContents {
		margin: auto;
		padding: #{$gutter-gSide02} #{$gutter-gSide02} calc( #{$gutter-gSide02} * 3 );
		max-width: #{$baseWidth};
	}

	// _spColumn area ( SP/Tablet only )
	&_spColumn {
		display: none;
	}

	// 共通スタイル
	// --------------------------------------
	&_main {
		width: 100%;
		min-width: 0;
		min-height:0;
		-webkit-order: 2;
		    -ms-order: 2;
		        order: 2;
		&::after {
			content: "_main";
		}
	}

	// _side area
	&_side {
		min-width: #{$layC-side-widthPer};
		width: #{$layC-side-widthPer};
		// min-width: #{$layC-side-width};
		// width: #{$layC-side-width};
	}
	&-sideLeft #{$this}_side ,
	&_side-left {
		margin-right: #{$layC-side-gutter-hPer};
		-webkit-order: 1;
		    -ms-order: 1;
		        order: 1;
		&::after {
			content: "left";
		}
	}
	&-sideRight #{$this}_side ,
	&_side-right {
		margin-left: #{$layC-side-gutter-hPer};
		-webkit-order: 3;
		    -ms-order: 3;
		        order: 3;
		&::after {
			content: "right";
			background-color: #f0c;
		}
	}

	&-baseColumn ,
	&-maxWideColumn {
		// _main area
		#{$this}_main {
			margin: 0 auto;
			max-width: 100%;
			&::after {
				background-color: #eee;
			}
		}
		// _side area
		#{$this}_side {
			margin: 0;
			display: none;
		}
	}


	// 1カラム ( base )
	// --------------------------------------
	&-baseColumn {
		#{$this}_main {
			&::after {
				content: "1column";
			}
		}
	}

	// 1カラム maxWideColumn
	// --------------------------------------
	&-maxWideColumn {
		padding: 0 !important;
		max-width: none;
		#{$this}_main {
			max-width: 100%;
			&::after {
				content: "maxWideColumn";
			}
		}
	}

	// 2カラム side-left ver.
	// --------------------------------------
	&-sideLeft {
		// _main area
		#{$this}_main {
			max-width: 100%;
			&::after {
				content: "2columns : side left";
			}
		}

		// _side area
		#{$this}_side {
		}
	}

	// 2カラム side-right ver.
	// --------------------------------------
	&-sideRight {
		// _main area
		#{$this}_main {
			margin-left: 0;
			max-width: 100%;
			&::after {
				content: "2columns : side right";
			}
		}

		// _side area
		#{$this}_side {
			&::after {
				content: "right";
				background-color: #f0c;
			}
		}
	}

	// 3カラム side-left/right ver.
	// --------------------------------------
	&-3columns {
		// _main area
		#{$this}_main {
			&::after {
				content: "3columns";
			}
		}
		// _side area
		#{$this}_side {
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
	.layoutColumn {
		$this: &;
		max-width: none;
		&_mainContents {
			max-width: none;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.layoutColumn {
		$this: &;
		$gutter-com: .8em;

		-webkit-justify-content: flex-start;
				justify-content: flex-start;
		-webkit-flex-flow: column wrap;
		        flex-flow: column wrap;

		margin: 20px auto 0;
		padding: 0 #{$gutter-com} calc( #{$gutter-com} * 3 );

		// 共通スタイル
		// --------------------------------------
		&_main {
			width: 100%;
			-webkit-order: 1;
			    -ms-order: 1;
			        order: 1;
		}
		&_mainContents {
			padding: 0 #{$gutter-com} calc( #{$gutter-com} * 3 );
		}

		// _side area
		&_side {
			margin-top: 35px;
			min-width: 0;
			width: 100%;
		}
		&-sideLeft #{$this}_side ,
		&_side-left {
			margin-right: 0;
			-webkit-order: 2;
			    -ms-order: 2;
			        order: 2;
		}
		&-sideRight #{$this}_side ,
		&_side-right {
			margin-left: 0;
		}

		// 2カラム side-left ver.
		// --------------------------------------
		&-sideLeft {
		}

		// 2カラム side-right ver.
		// --------------------------------------
		&-sideRight {
		}

		// 3カラム side-left/right ver.
		// --------------------------------------
		&-3columns {
		}
	}
}

/*
セクション, article

section , article

Markup:
<section class="section"></section><!-- /.section -->
<article class="article"></article><!-- /.article -->

Styleguide 2.4
============================================================ */
.section {
	$this: &;
	$gutter-narrow: 60px;
	position: relative;
	margin-top: 75px;
	&:first-child {
		margin-top: 0;
	}

	// // 少し狭いエリア
	// &-narrow {
	//	padding: 0 #{$gutter-narrow};
	// }
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.section {
		$this: &;

		margin-top: 55px;
		&-narrow {
			margin: auto;
			padding: 0;
		}
	}
}


/* ------------------------------------------------------------------------------------------------------ */
/* --- header 関連 -------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------ */

/*
header関連

gHeader

Markup:
<header class="gHeader" data-gheader="">
	<div class="gHeader_fixed" data-gheader-fixed="">
		<!-- gHeader_main -->
		<div class="gHeader_main">
			<div class="gHeader_mainUnit gHeader_mainUnit-left">
				<!-- gHeader_logo -->
				<h1 class="gHeader_logo">
					<a href="<%= siteRoot %>" class="gHeader_logoImg"><img src="<%= siteRoot %>assets/images/global/img_logo.png" alt="<%= siteName %>"></a>
				</h1>
			</div>
			<div class="gHeader_mainUnit gHeader_mainUnit-right">
				<!-- ここに 「 _gNav 」 が入る -->
			</div>
		</div><!-- /.gHeader_main -->
		<!-- ここに 「 gNavBar 」 が入る -->
	</div><!-- /.gHeader_fixed -->
</header><!-- /.gHeader -->
				<!-- ここに 「 子要素 」 が入る -->

Styleguide 2.5.1
============================================================ */
.gHeader {
	$this: &;
	$gutter-com: 1em;
	$gutter-main: 2em;

	// logo image
		$logo-imgUrl: './../images/global/img_logo.png';
		$logo-width: image-width($logo-imgUrl);
		$logo-height: image-height($logo-imgUrl);

	margin: 0 auto;
	padding: 8px #{$gutter-com};
	width: 100%;
	color: $white;
	background-color: $mainColor01;
	z-index: $zi-gHeader + 10;
	&_fixed {
		margin: 0 auto;
		max-width: #{$baseWidth};
	}

	// _main area
	&_main {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
				justify-content: space-between;
			-webkit-align-items: center;
					align-items: center;
		& > * {
			margin: 0;
			&:not(:first-child) {
				margin-left: #{$gutter-main};
			}
		}
	}
	&_mainUnit {
		&-left {
		}
		&-right {
		}
	}

	// _logo area
	&_logo {
		line-height: 1;
	}
	&_logoImg {
		display: inline-block;
		margin: 0;
		width: #{$logo-width};
		line-height: 1;
		&:hover {
			opacity: 1 !important;
		}
		img {
			vertical-align: middle;
			width: auto;
			@include transitionType01;
			&:hover {
				opacity: 1 !important;
			}
		}
	}

	// page module
	&[data-gheader="siteTop"] {
		// background-color: $red;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.gHeader {
		$this: &;
		$gutter-com: .5em;
		$gutter-main: 2em;

		// logo image
			$logo-imgUrl: './../images/global/img_logo.png';
			$logo-width: image-width($logo-imgUrl);
			$logo-height: image-height($logo-imgUrl);

		padding: 0;
		background-color: transparent;
		&_fixed {
			top: 0 !important;
			padding: 0 #{$gutter-com};
			max-width: none;
			@extend .is-fixedSp;
		}

		// _main area
		&_main {
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-flow: column wrap;
					flex-flow: column wrap;

			margin: 0;
			padding: 0 !important;
			& > * {
				margin: 0 !important;
			}
		}
		&_mainUnit {
			&-left {
			}
			&-right {
			}
		}

		// _logo area
		&_logo {
			height: calc( #{$fixedHeight} );
			line-height: calc( #{$fixedHeight} );
		}
		&_logoImg {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
					align-items: center;

			max-width: calc( #{$logo-width} / 1.3 );
			height: 100%;
			img {
				max-width: calc( #{$logo-width} / 1.3 );
			}
		}

		// page module
		&[data-gheader="siteTop"] {
		}
	}
}



/* ------------------------------------------------------------------------------------------------------ */
/* --- gNav 関連 --------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------ */

/*
gNav関連

gNav

Markup:
<div class="gNav" data-gnav="">
	<div class="gNav_fixed" data-gnav-fixed="">
		<div class="gNav_close">
			<button class="gNav_closeBtn" data-gnav-close=""><span></span><em>CLOSE</em></button>
		</div><!-- /.gNav_close -->
		<nav class="gNav_menu" data-gnav-menu="">
			<ul class="gNav_menuList">
				<li class="gNav_menuItem <% if(menuLocation == 'menu01'){ %>is-current<% } %>" data-gnav-item="dropnav">
					<a href="<%= siteRoot %>company/" data-gnav-id="dropmenu01"><span>会社概要</span></a>
					<button class="gNav_accordBtn" data-gnav-btn=""></button>
					<div id="dropmenu01" class="gNav_drop gNav_drop-menu01" data-gnav-drop="">
						<div class="gNav_dropInner" data-gnav-dropinner="">
							<div class="gDropMenu">
								<ul class="gDropMenu_list">
									<% for (var i = 0; i < 7; i++) { %><li class="gDropMenu_item"><a href="<%= siteRoot %>">後で削除</a></li>
									<% } %>
									<li class="gDropMenu_item"><div>テキスト（リンクなし） テキストテキストテキスト</div></li>
								</ul>
							</div><!-- /.gDropMenu -->
						</div>
					</div><!-- /.gNav_drop -->
				</li>
				<li class="gNav_menuItem <% if(menuLocation == 'menu02'){ %>is-current<% } %>" data-gnav-item="">
					<a href="<%= siteRoot %>service/" data-gnav-id="dropmenu02"><span>サービス紹介</span></a>
				</li>
				<li class="gNav_menuItem <% if(menuLocation == 'menu03'){ %>is-current<% } %>" data-gnav-item="dropnav">
					<a href="<%= siteRoot %>news/" data-gnav-id="dropmenu03"><span>お知らせ</span></a>
					<button class="gNav_accordBtn" data-gnav-btn=""></button>
					<div id="dropmenu03" class="gNav_drop gNav_drop-menu03" data-gnav-drop="">
						<div class="gNav_dropInner" data-gnav-dropinner="">
							<div class="gDropMenu">
								<ul class="gDropMenu_list">
									<% for (var i = 0; i < 7; i++) { %><li class="gDropMenu_item"><a href="<%= siteRoot %>">後で削除</a></li>
									<% } %>
									<li class="gDropMenu_item"><div>テキスト（リンクなし） テキストテキストテキスト</div></li>
								</ul>
							</div><!-- /.gDropMenu -->
						</div>
					</div><!-- /.gNav_drop -->
				</li>
				<li class="gNav_menuItem <% if(menuLocation == 'menu04'){ %>is-current<% } %>" data-gnav-item="">
					<a href="<%= siteRoot %>blog/" data-gnav-id="dropmenu04"><span>ブログ</span></a>
				</li>
				<li class="gNav_menuItem <% if(menuLocation == 'menu05'){ %>is-current<% } %>" data-gnav-item="">
					<a href="<%= siteRoot %>contact/" data-gnav-id="dropmenu05"><span>お問い合わせ</span></a>
				</li>
			</ul>
		</nav><!-- /.gNav_menu -->
	</div><!-- /.gNav_fixed -->
</div><!-- /.gNav -->

Styleguide 2.6.1
============================================================ */
.gNav {
	$this: &;
	$gutter-com: 1em;
	$gutter-menu-h: 2.4em;

	position: relative;
	width: 100%;
	-webkit-transition: none;
	        transition: none;
	z-index: $zi-gNav;

	&_fixed {
	}

	// _close area
	&_close {
		display: none;
	}

	// _menu area
	&_menu {
	}
	&_menuList {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: flex-end;
				justify-content: flex-end;
		-webkit-align-items: stretch;
				align-items: stretch;

		margin: 0;
		list-style-type: none;
		& > * {
			margin: 0;
			&:not(:first-child) {
				margin-left: #{$gutter-menu-h};
			}
		}
	}
	&_menuItem {
		position: relative;
		width: 100%;
		text-align: center;
		white-space: nowrap;

		> a:not([class]) ,
		> div:not([class]) {
			display: -webkit-flex;
			display: flex;
			-webkit-justify-content: center;
					justify-content: center;
			-webkit-align-items: center;
					align-items: center;

			position: relative;
			padding: 24px 20px;
			width: 100%;
			height: 100%;
			color: $white;
			@include fs(18);
			cursor: pointer;
			text-decoration: none;
			@include transitionType01;
		}

		// dropdown　有りの場合
		&[data-gnav-item="dropnav"] {
		}
	}

	&_accordBtn {
		display: none;
	}

	&_drop {
		display: none;
		position: absolute;
		left: 50%;
		margin: 0;
		width: 100%;
		min-width: 300px;
		text-align: left;
		overflow: hidden;
		background-color: #000;
		@include boxShadow01;
		z-index: $zi-gNavDrop;
		pointer-events: none;
		-webkit-transform: translateX(-50%);
		        transform: translateX(-50%);

																																// pointer-events: auto;
		&.is-showDrop {
			display: block;
			pointer-events: auto;
			animation: showDrop .2s linear 0s;
		}
	}
	&_dropInner {
		position: relative;
		margin: 0 auto;
		padding: 1em;
	}
}
// gNavOverlay
// <div class="gNavOverlay" data-gnav-overlay=""></div>
// --------------------------------------
.gNavOverlay {
	$this: &;
	content: "";
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: move;
	text-decoration: none;
	background: $pcOverlayBgColor_w;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
	z-index: $zi-overlay;

	opacity: 0;
	visibility: hidden;
	-webkit-transition: .3s .3s;
	        transition: .3s .3s;
	&:active ,
	&:hover {
		opacity: 0;
		-webkit-tap-highlight-color:rgba(0,0,0,0);
	}

	&.is-overlayOpen {
		opacity: 1;
		visibility: visible;
		@include transitionType01;
	}
}
@keyframes showDrop {
	from{
		opacity: 0;
	}
	to{
		opacity: 1;
	}
}
// hover style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	// gNav menu
	.gNav {
		$this: &;
		&_menuItem {
			> a:not([class]) ,
			> div:not([class]) {
				&::after {
					content: "";
					position: absolute;
					bottom: 15px;
					left: 50%;
					width: 6px;
					height: 6px;
					-webkit-border-radius: 100%;
					        border-radius: 100%;
					background-color: $white;
					-webkit-transform: translateX(-50%);
					        transform: translateX(-50%);
					@include transitionType01;
					opacity: 0;
				}
			}

			// hover action , is-current
			&:hover ,
			&.is-current {
				> a:not([class]) ,
				> div:not([class]) {
					&::after {
						bottom: 5px;
						opacity: 1;
					}
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.gNav {
		$this: &;
		$gutter-com: 1em;
		$gutter-menu-h: 2.4em;
		$width-nav: 100%;
		$width-accordBtn: 40px;
		$size-closeBtn: 36px;

		position: fixed;
		top: 0;
		left: -100%;
		left: 0;
		bottom: 0;
		width: #{$width-nav};
		background-color: $spOverlayBgColor;

		opacity: 0;
		visibility: hidden;
		// pointer-events: none;
		// -webkit-transition: .3s;
		//         transition: .3s;

		&.is-navOpen {
			// left: 0%;
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
			-webkit-transition: .5s;
			        transition: .5s;
			z-index: $zi-gNav-isOpen;
		}

		&_fixed {
			position: relative;
			padding: 1.5em .5em 4em;
			height: 100%;

			-webkit-overflow-scrolling: touch;
			-webkit-backface-visibility: hidden;
			        backface-visibility: hidden;
			overflow-y: auto;
			@include scrollbar02;
			z-index: 5;
			&::-webkit-scrollbar02 {
				width: 7px;
			}
			&::-webkit-scrollbar02-thumb {
				width: 7px;
			}
		}

		&_close {
			display: block;
			position: sticky;
			top: 5px;
			right: 5px;
			margin-bottom: 1em;
			text-align: right;
			-webkit-transform: translate3d(0,0,0);
			z-index: $zi-gNav-isOpen;
		}
		&_closeBtn {
			position: relative;
			display: inline-block;
			width: #{$size-closeBtn};
			height: #{$size-closeBtn};
			line-height: #{$size-closeBtn};
			text-align: center;
			// border: 1px solid $white;
			-webkit-border-radius: 50%;
			        border-radius: 50%;
			text-decoration: none;
			&::before,
			&::after {
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				margin: 0 0 0 -11px;
				width: calc( #{$size-closeBtn} - 12px );
				height: 2px;
				background: $white;
				@include transitionType01;
			}
			&::before, &::after {
				content: "";
			}
			&::before {
					-webkit-transform: rotate(-45deg);
					        transform: rotate(-45deg);
			}
			&::after {
					-webkit-transform: rotate(45deg);
					        transform: rotate(45deg);
			}
			em {
				@include textHidden;
			}
		}

		// _menu area
		&_menu {
		}
		&_menuList {
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-flow: column wrap;
					flex-flow: column wrap;

			margin: 0;
			border-bottom: 1px solid $gray-dark;
			& > * {
				margin: 0 !important;
			}
		}
		&_menuItem {
			white-space: normal;
			border-top: 1px solid $gray-dark;

			> a:not([class]) ,
			> div:not([class]) {
				display: -webkit-flex;
				display: flex;
				-webkit-justify-content: flex-start;
						justify-content: flex-start;

				padding: 12px 10px;
				padding-right: #{$width-accordBtn};
				height: auto;
				@include fs(16);
				font-weight: bold;
				// &::after {
				//	content: "";
				//	display: inline-block;
				//	position: absolute;
				//	top: 50%;
				//	right: 12px;
				//	width: 8px;
				//	height: 8px;
				//	border-top: 1px solid $gray-light;
				//	border-right: 1px solid $gray-light;
				//	-webkit-transform: translate(0, -50%) rotate(45deg);
				//	        transform: translate(0, -50%) rotate(45deg);
				// }
			}

			// dropdown　有りの場合
			&[data-gnav-item="dropnav"] {
				> a:not([class]) ,
				> div:not([class]) {
					&::after {
						display: none;
					}
				}
				&.is-currentDrop {
					#{$this}_accordBtn {
						&::after {
							-webkit-transform: rotate(-90deg);
							        transform: rotate(-90deg);
						}
					}
				}
			}

			// is-current , is-currentDrop
			&.is-current ,
			&.is-currentDrop {
				> a:not([class]) ,
				> div:not([class]) {
					color: $mainColor03;
				}
			}
		}

		&_accordBtn {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			width: #{$width-accordBtn};
			height: 100%;
			z-index: 5;
			&::before ,
			&::after {
				content: "";
				display: inline-block;
				position: absolute;
				top: 16px;
				right: 12px;
				width: 1px;
				height: 15px;
				background: $gray-dot;
				@include transitionType01;
			}
			&::before {
			}
			&::after {
				transform: rotate(90deg);
			}
		}

		&_drop {
			position: relative;
			top: auto;
			left: auto;
			min-width: 0;
			background-color: rgba(0,0,0, .4);
			overflow: visible;
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
			-webkit-transform: translateX(0);
			        transform: translateX(0);
			@include boxShadowAuto;
			&.is-showDrop {
			}
		}
		&_dropInner {
			padding: .5em;
		}
	}
}

/*
gNav関連

gNavBar

Markup:
<div class="gNavBar" data-gnavbar="">
	<div class="gNavBar_menu">
		<p class="gNavBar_btn" data-gnavbar-btn="menu"><button><span><i></i></span><em>メニュー</em></button></p>
	</div>
</div><!-- gNavBar -->

Styleguide 2.6.2
============================================================ */
// ___ sp only display
.gNavBar {
	$this: &;
	$gutter-h: 5px;
	$size-menu: 40px;

	position: absolute;
	top: 50%;
	right: 5%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	z-index: $zi-gNavBar;

	display: none;
	&_menu {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: flex-end;
				justify-content: flex-end;
		-webkit-align-items: center;
				align-items: center;
		-webkit-flex-wrap: nowrap;
				flex-wrap: nowrap;

		margin: 0;
		& > * {
			margin: 0;
			&:not(:first-child) {
				margin-left: #{$gutter-h};
			}
		}
	}
	&_btn {
		> a,
		> button {
			display: -webkit-flex;
			display: flex;
			-webkit-justify-content: center;
					justify-content: center;
			-webkit-align-items: center;
					align-items: center;
			-webkit-flex-flow: column wrap;
					flex-flow: column wrap;

			position: relative;
			width: calc( #{$size-menu} );
			height: 100%;
			min-height: calc( #{$size-menu} );
			// background-color: $gray-exLight;
			-webkit-border-radius: 3px;
			        border-radius: 3px;
			text-decoration: none;
			@include transitionType01;
			> * {
				margin: 0;
				flex: auto;
			}

			// icon
			> span {
				position: relative;
				display: block;
				width: 100%;
				min-height: 24px;
			}
			// text
			> em {
				display: none;
			}
		}

		// ハンバーガーメニュー
		&[data-gnavbar-btn="menu"] {
			> a,
			> button {
				opacity: 1;
				visibility: visible;
				> span {
					> i,
					&::before,
					&::after {
						display: block;
						position: absolute;
						top: 48%;
						left: 50%;
						margin: 0 0 0 calc( -#{$size-menu} / 4 );
						width: calc( #{$size-menu} / 1.5 );
						height: 3px;
						background: $white;
						border: 1px solid $white;
						-webkit-border-radius: 3px;
						        border-radius: 3px;
						@include transitionType01;
					}
					&::before, &::after {
						content: "";
					}
					&::before {
						-webkit-transform: translateY(-7px);
						        transform: translateY(-7px);
					}
					&::after {
						-webkit-transform: translateY(7px);
						        transform: translateY(7px);
					}
				}
			}
			// &.is-triggerOpen {
			//	> a,
			//	> button {
			//		-webkit-transition: opacity .5s, visibility .5s;
			//				transition: opacity .5s, visibility .5s;
			//		> span {
			//			> i {
			//				opacity: 0;
			//			}
			//			&::before {
			//				-webkit-transform: rotate(-45deg);
			//				        transform: rotate(-45deg);
			//			}
			//			&::after {
			//				-webkit-transform: rotate(45deg);
			//				        transform: rotate(45deg);
			//			}
			//		}
			//	}
			// }
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.gNavBar {
		display: block;
	}
}



/* ------------------------------------------------------------------------------------------------------ */
/* --- gDropMenu 関連 ---------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------ */

/*
gDropMenu関連

gDropMenu

Markup:
<div class="gDropMenu">
	<ul class="gDropMenu_list">
		<% for (var i = 0; i < 7; i++) { %><li class="gDropMenu_item"><a href="<%= siteRoot %>">後で削除</a></li>
		<% } %>
		<li class="gDropMenu_item"><div>テキスト（リンクなし） テキストテキストテキスト</div></li>
	</ul>
</div><!-- /.gDropMenu -->

Styleguide 2.71
============================================================ */
.gDropMenu {
	$this: &;
	$gutter-h: 20px;
	margin: 0;

	// 1階層目
	// -----------------------------------------
	&_list {
		margin: 0;
		list-style-type: none;
	}
	&_item {
		padding: 1px 0;
		> a ,
		> div:not([class]) {
			position: relative;
			display: inline-block;
			padding: 5px 0 5px 15px;
			color: $white;
			@include fs(15);
			line-height: 1.2;
			text-decoration: none;
			@include wordRap;
			&::before {
				content: "";
				display: inline-block;
				position: absolute;
				top: 10px;
				left: 0;
				width: 8px;
				height: 8px;
				border-top: 1px solid $gray-light;
				border-right: 1px solid $gray-light;
				-webkit-transform: rotate(45deg);
				        transform: rotate(45deg);
			}
		}

		// 非リンク時
		> div:not([class]) {
			color: $gray;
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.gDropMenu {
		$this: &;
		$gutter-h: 20px;
		margin: 0;

		// 1階層目
		// -----------------------------------------
		&_list {
			margin: 0;
			list-style-type: none;
		}
		&_item {
			padding: 1px 0;
			> a ,
			> div:not([class]) {
				padding-left: 20px;
				@include fs(14);
				&::before {
					left: 0;
					width: 6px;
					height: 6px;
				}
			}
		}
	}
}



/* ------------------------------------------------------------------------------------------------------ */
/* --- SP only display 関連 ----------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------ */

/*
header関連 (SP表示のみ)

gNavAside

Markup:
<nav class="gNavAside">
	<ul class="gNavAside_list">
		<li class="gNavAside_item"><a href="<%= siteRoot %>xxx/">〇〇〇〇〇〇〇〇</a></li>
		<li class="gNavAside_item"><a href="<%= siteRoot %>xxx/">〇〇〇〇〇〇〇〇</a></li>
		<li class="gNavAside_item"><a href="<%= siteRoot %>xxx/">〇〇〇〇〇〇〇〇</a></li>
	</ul>
</nav><!-- /.gNavAside -->

Styleguide 2.8.1
============================================================ */
.gNavAside {
	$this: &;

	display: none;

	margin: 20px 0 0;

	// _list area
	&_list {
		margin: 0;
		list-style-type: none;
	}
	&_item {
		margin: 0;
		padding: 2px 0;
		> a ,
		> div:not([class]) {
			position: relative;
			display: block;
			padding: 6px 10px;
			padding-left: 2em;
			text-align: left;
			color: $gray-light;
			@include fs(13);
			line-height: 1.4;
			text-decoration: none;
			&::before {
				content: "";
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 5px;
				margin-top: -3px;
				width: 6px;
				height: 6px;
				border-top: 1px solid $gray-border;
				border-right: 1px solid $gray-border;
				-webkit-transform: rotate(45deg);
				        transform: rotate(45deg);
			}
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.gNavAside {
		display: block;
	}
}



/* ------------------------------------------------------------------------------------------------------ */
/* --- is-fixedPc , is-fixedSp -------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------ */

/* グローバルナビ固定処理用Class > .is-fixedPc
-------------------------------------------------------*/
@media screen and (min-width: $breakpoint-sp + 1) {
	.is-fixedPc {
		$this: &;
		position: fixed;
		top: 0 !important;
		left: 0;
		width: 100%;
		min-height: 0;
		-webkit-box-shadow: 0 2px 2px 1px rgba(0,0,0,.1);
				box-shadow: 0 2px 2px 1px rgba(0,0,0,.1);
		z-index: $zi-isFixedPc;
	}
}

/* グローバルナビ固定処理用Class > .is-fixedSp
-------------------------------------------------------*/
@media screen and (max-width: $breakpoint-sp) {
	.is-fixedSp {
		position: fixed;
		top: 0 !important;
		left: 0;
		width: 100%;
		background-color: rgba($mainColor01, 1);
		z-index: $zi-isFixedSp;
	}
}



/* ------------------------------------------------------------------------------------------------------ */
/* --- footer 関連 -------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------ */

/*
footer関連

gFooter

Markup:
<footer id="js-gFooter" class="gFooter">
	<!-- gFooter_main -->
	<div class="gFooter_main">
		<div class="gFooter_mainInner">
			<div class="gFooter_mainUnit gFooter_mainUnit-left">
				<!-- gFooter_logo -->
				<div class="gFooter_logo">
					<a href="<%= siteRoot %>" class="gFooter_logoImg"><img src="<%= siteRoot %>assets/images/global/img_logo.png" alt="<%= siteName %>"></a>
				</div>
				<!-- gFooter_address -->
				<div class="gFooter_address">
					<span class="zip">〒110-0008</span>
					<span class="address">東京都台東区池之端1-4-28　ライオンズプラザ池之端203</span>
				</div>
			</div>
			<div class="gFooter_mainUnit gFooter_mainUnit-right">
				<!-- ここに 「 gFooterGuide 」 が入る -->
			</div>
		</div>
	</div><!-- /.gFooter_main -->
	<!-- ここに 「 gFooterCopys 」 が入る -->
</footer><!-- /.gFooter -->

Styleguide 2.9.1
============================================================ */
.gFooter {
	$this: &;
	$gutter-com: 1em;
	$gutter-main: 2em;

	// logo image
		$logo-imgUrl: './../images/global/img_logo.png';
		$logo-width: image-width($logo-imgUrl);
		$logo-height: image-height($logo-imgUrl);

	position: relative;
	margin: 0 auto 0;
	&_main {
		color: $white;
		padding: 0 #{$gutter-com};
		background-color: $mainColor02;
	}
	&_mainInner {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
				justify-content: space-between;

		margin: 0 auto;
		padding: 60px 0 44px;
		max-width: #{$baseWidth};
		& > * {
			margin: 0;
			&:not(:first-child) {
				margin-left: #{$gutter-main};
			}
		}
	}
	&_mainUnit {
		&-left {
		}
		&-right {
		}
	}

	// _logo area
	&_logo {
		line-height: 1;
	}
	&_logoImg {
		display: inline-block;
		margin: 0;
		width: calc( #{$logo-width} );
		line-height: 1;
		&:hover {
			opacity: 1 !important;
		}
		img {
			vertical-align: middle;
			@include transitionType01;
			width: auto;
			&:hover {
				opacity: 1 !important;
			}
		}
	}

	// _address area
	&_address {
		margin-top: 20px;
		@include fs(14);
		.zip ,
		.address {
			display: block;
		}
		.zip {
		}
		.address {
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.gFooter {
		$this: &;
		$gutter-com: .5em;
		$gutter-main: 15px;

		// logo image
			$logo-imgUrl: './../images/global/img_logo.png';
			$logo-width: image-width($logo-imgUrl);
			$logo-height: image-height($logo-imgUrl);

		// margin-top: 30px;
		&_main {
			padding: 0 #{$gutter-com};
		}
		&_mainInner {
			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;

			padding: 20px 0;
			max-width: none;
			& > * {
				margin: 0 !important;
			}
		}
		&_mainUnit {
			&-left {
				margin-top: #{$gutter-main} !important;
				-webkit-order: 2;
				    -ms-order: 2;
				        order: 2;
			}
			&-right {
				-webkit-order: 1;
				    -ms-order: 1;
				        order: 1;
			}
		}

		// _logo area
		&_logo {
			text-align: center;
		}
		&_logoImg {
			width: calc( #{$logo-width} / 1.5 );
		}

		// _address area
		&_address {
			margin-top: 1em;
			padding: 0 1em;
			@include fs(12);
		}
	}
}

/*
footer関連

gFooterGuide

Markup:
<div class="gFooterGuide" data-gfguide="">
	<div class="gFooterGuide_block">
		<div class="gFooterGuide_unit">
			<p class="gFooterGuide_th" data-gfguide-trigger="dropAccord"><a href="<%= siteRoot %>service/">サービス紹介</a><button class="gFooterGuide_accBtn"></button></p>
			<div class="gFooterGuide_td" data-gfguide-target="">
				<ul class="gFooterGuide_list">
					<li><a href="<%= siteRoot %>nyusatsunet/">入札ネット</a></li>
					<li><a href="<%= siteRoot %>shiryokan/">建設資料館</a></li>
					<li><a href="<%= siteRoot %>minkankenchiku/">建設工事情報@Mail</a></li>
					<li><a href="<%= siteRoot %>contents/">コンテンツ制作</a></li>
					<li><a href="<%= siteRoot %>newspaper/">建設支援サービス</a></li>
					<li><span>テキスト（非リンク時）</span></li>
				</ul>
			</div>
		</div>
		<div class="gFooterGuide_unit">
			<p class="gFooterGuide_th" data-gfguide-trigger="dropAccord"><a href="<%= siteRoot %>xxxxxxxxxxx/">BLOG</a><button class="gFooterGuide_accBtn"></button></p>
			<div class="gFooterGuide_td" data-gfguide-target="">
				<ul class="gFooterGuide_list">
					<li><a href="<%= siteRoot %>xxxxxxxxxxx/aaa/">特集BLOG</a></li>
					<li><a href="<%= siteRoot %>xxxxxxxxxxx/aaa/">新着BLOG</a></li>
					<li><a href="<%= siteRoot %>xxxxxxxxxxx/aaa/">人気BLOG</a></li>
				</ul>
			</div>
		</div>
		<div class="gFooterGuide_unit">
			<div class="gFooterGuide_td">
				<ul class="gFooterGuide_list">
					<li><a href="<%= siteRoot %>company/">会社概要</a></li>
					<li><a href="<%= siteRoot %>information/">インフォメーション</a></li>
					<li><a href="<%= siteRoot %>contact/">お問い合わせ</a></li>
				</ul>
			</div>
		</div>
	</div>
</div><!-- /.gFooterGuide -->

Styleguide 2.9.2
============================================================ */
.gFooterGuide {
	$this: &;
	$gutter-h: 2em;
	$gutter-v: 1em;

	position: relative;
	&_block {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: stretch;
				align-items: stretch;
		-webkit-flex-wrap: wrap;
				flex-wrap: wrap;

		margin: -#{$gutter-v} 0 0 -#{$gutter-h};
		list-style-type: none;
		& > * {
			margin: #{$gutter-v} 0 0 #{$gutter-h};
			min-width: 150px;
		}
	}

	&_th ,
	&_td {
		position: relative;
		> a ,
		> span {
			pointer-events: auto;
			padding: 5px 0;
			color: $white;
			display: inline-block;
			text-decoration: none;
		}
	}
	&_th {
		margin: 0;
		padding: 0;
		@include fs(16);
		font-weight: bold;
		border: none;
		&:not(:first-child) {
			margin-top: 10px;
		}
	}
	&_td {
		display: block;
		&:not(:first-child) {
			margin-top: 10px;
		}
	}
	&_list {
		margin: 0;
		list-style-type: none;
		> li {
			margin: 0;
			padding: 1px 0;
			@include fs(14);
			> a ,
			> div:not([class]) {
				padding: 5px 0;
				color: $white;
				display: inline-block;
				text-decoration: none;
			}
		}
	}

	// アコーディオンボタンで開閉する場合
	&_accBtn {
		display: none;
		pointer-events: none;
	}

	// SP時アコーディオン用data属性
	[data-gfguide-trigger="dropAccord"] {
		pointer-events: none;
	}
	[data-gfguide-target] {
		display: block;
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.gFooterGuide {
		$this: &;
		$gutter-h: 2em;
		$gutter-v: 1em;
		$size-accBtn: 35px;

		padding: 1em .5em;
		&_block {
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;

			margin: 0;
			& > * {
				margin: 0;
				width: 100%;
				width: 100%\9;
				&:not(:first-child) {
					margin: 0;
				}
			}
		}
		&_unit {
			border-top: 1px solid $mainColor01;
		}
		&_th {
			@include fs(15);
			> a ,
			> span {
				display: block;
				padding: 12px 1.6em 10px 5px;
				&::after {
					content: "";
					display: inline-block;
					position: absolute;
					top: 50%;
					right: 8px;
					width: 12px;
					height: 12px;
					border-top: 1px solid $gray-border;
					border-right: 1px solid $gray-border;
					-webkit-transform: translate(0, -50%) rotate(45deg);
					        transform: translate(0, -50%) rotate(45deg);
					@include transitionType01;
				}
			}

			&.is-open {
				// アコーディオンボタンで開閉する場合
				#{$this}_accBtn {
					&::before {
						-webkit-transform: translate(0, -50%) rotate(-90deg);
								transform: translate(0, -50%) rotate(-90deg);
					}
				}
			}
		}
		&_td {
			&:not(:first-child) {
				margin-top: 0;
			}
		}
		&_list {
			> li {
				@include fs(14);
				> a ,
				> span {
					position: relative;
					padding: 4px 5px;
					padding-left: 1.2em;
					&::before {
						content: "";
						display: inline-block;
						position: absolute;
						top: 50%;
						left: 0;
						width: 6px;
						height: 6px;
						border-top: 1px solid $gray-border;
						border-right: 1px solid $gray-border;
						-webkit-transform: translate(0, -50%) rotate(45deg);
						        transform: translate(0, -50%) rotate(45deg);
						@include transitionType01;
					}
				}
			}
		}

		// アコーディオンボタンで開閉する場合
		&_accBtn {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			width: #{$size-accBtn};
			height: 100%;
			display: block;
			pointer-events: auto;
			z-index: 10;

			&::before ,
			&::after {
				content: "";
				display: inline-block;
				position: absolute;
				top: 50%;
				right: 10px;
				width: 1px;
				height: 16px;
				background: $white;
				@include transitionType01;
				-webkit-transform: translateY(-50%);
				        transform: translateY(-50%);
			}
			&::before {
			}
			&::after {
				-webkit-transform: translateY(-50%) rotate(90deg);
				        transform: translateY(-50%) rotate(90deg);
			}
		}

		// SP時アコーディオン用data属性
		[data-gfguide-trigger="dropAccord"] {
			&::after {
				display: none;
			}
			> a ,
			> span {
				&::after {
					display: none;
				}
			}
			#{$this}_accBtn {
				pointer-events: auto;
			}
		}
		[data-gfguide-target] {
			display: none;
			padding-left: 1em;
			padding-bottom: 10px;
		}
	}
}

/*
footer関連

gFooterCopys

Markup:
<div class="gFooterCopys">
	<small class="gFooterCopys_txt">Copyright(C)
		<script>
			document.write(new Date().getFullYear());
		</script>
		建設データ株式会社 all rights reserved</small>
</div><!-- /.gFooterCopys -->

Styleguide 2.9.2
============================================================ */
.gFooterCopys {
	$this: &;
	position: relative;
	margin: 0;
	padding: .5em 0;
	text-align: center;
	line-height: 1;
	&_txt {
		margin: 0;
		color: $mainColor02;
		@include fs(12);
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.gFooterCopys {
		$this: &;
		padding: .5em;
		&_txt {
			@include fs(10);
		}
	}
}



/* ------------------------------------------------------------------------------------------------------ */
/* --- pageTop ------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */

/*
ページトップリンク

pageTop

Markup:
<div id="js-pageTop" class="pageTop">
	<p class="pageTop_btn"><a href="#top"><span>TOP</span></a></p>
</div><!-- /.pageTop -->

Styleguide 2.10
============================================================ */
.pageTop {
	$this: &;
	$size-icon: 60px;

	// position: absolute;
	position: fixed;
	right: 30px;
	bottom: 0;
	text-align: center;
	z-index: $zi-pageTop;
	&_btn {
		margin: 0;
		display: inline-block;
		text-align: right;
		> a {
			position: relative;
			display: inline-block;
			width: #{$size-icon};
			height: #{$size-icon};
			font-style: normal;
			text-decoration: none;
			text-align: center;
			background-color: rgba($mainColor01, .9);
			border: 1px solid rgba($mainColor01, .3);
			&::before {
				content: "";
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 50%;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 5px 8.7px 5px;
				border-color: transparent transparent $white transparent;
				-webkit-transform: translate(-50%, -50%);
				        transform: translate(-50%, -50%);
			}
			> span {
				@include textHidden;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.pageTop {
		$this: &;
		$size-icon: 35px;

		position: fixed !important;
		right: 0px;
		bottom: 80px !important;
		&_btn {
			> a {
				display: inline-block;
				width: #{$size-icon};
				height: #{$size-icon};
			}
		}
	}
}


/* ------------------------------------------------------------------------------------------------------ */
/* --- aside 関連 --------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------ */
/*
aside関連

aside

Markup:
<aside class="aside {$modifiers}">
	<div class="aside_inner">
		<h3 class="aside_headLine">
			<span class="title">Midashi</span>
			<span class="sub">見出し</span>
		</h3>
		<div class="aside_body">
			<p class="aside_txt">テキストテキストテキストテキストテキストテキスト</p>
			contents contents contents contents contents contents contents contents contents contents
			<!-- ここに 「 子要素 」 が入る -->
		</div>
	</div>
</aside><!-- /.aside -->

.aside-line - line

Styleguide 2.11.1
============================================================ */
.aside {
	$this: &;
	$gutter-v: 25px;

	position: relative;
	border: 1px solid $baseColor;
	&:not(:first-child) {
		margin-top: #{$gutter-v};
	}

	&_headLine {
		margin-bottom: 10px;
		@include fs(19);
		font-weight: bold;
	}

	// line
	&-lineout {
		border: none;
	}

	// space
	&-space01 {
		padding: 30px 18px;
	}

	// layout module
	&-asideLogin {
		border-color: $gray;
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
aside関連 : サイドツリー ( base:リンクと開閉ボタンが別々 )

※ リンクエリアで開閉の場合は次のdata属性値に変更 → data-asidenav="block"
※ <a href="#"> → <a href="javascript: void(0);">

asideNav

Markup:
<nav class="asideNav" data-asidenav="separate">
	<ul class="asideNav_list">
		<li class="asideNav_item <% if(menuLocation == 'menu01'){ %>is-current<% } %>"><a href=""><span>リンク</span></a></li>
		<li class="asideNav_item <% if(menuLocation == 'menu99'){ %>is-current<% } %>"><a href=""><span>リンク (is-current)</span></a></li>
		<li class="asideNav_item <% if(menuLocation == 'menu03'){ %>is-current<% } %>">
			<div class="asideNav_btns <% if(menuLocation == 'menu03-btn'){ %>is-open<% } %>">
				<div class="asideNav_btn"><a href="#">アコーディオン</a></div><button class="asideNav_close"><i></i></button>
			</div>
			<nav class="asideNav">
				<ul class="asideNav_list">
					<li class="asideNav_item <% if(menuSubLocation == 'menu03-01'){ %>is-current<% } %>"><a href=""><span>リンク (第2階層)</span></a></li>
					<li class="asideNav_item <% if(menuSubLocation == 'menu99-02'){ %>is-current<% } %>"><a href=""><span>リンク (第2階層) (is-current)</span></a></li>
					<li class="asideNav_item <% if(menuSubLocation == 'menu03-03'){ %>is-current<% } %>"><a href=""><span>リンク (第2階層)</span></a></li>
					<li class="asideNav_item"><div><span>テキスト （リンクなし）</span></div></li>
				</ul>
			</nav><!-- /.asideNav -->
		</li>
		<li class="asideNav_item <% if(menuLocation == 'menu99'){ %>is-current<% } %>">
			<div class="asideNav_btns <% if(menuLocation == 'menu99'){ %>is-open<% } %>">
				<div class="asideNav_btn"><a href="#">アコーディオン(is-open)</a></div><button class="asideNav_close"><i></i></button>
			</div>
			<nav class="asideNav">
				<ul class="asideNav_list">
					<li class="asideNav_item <% if(menuSubLocation == 'menu04-01'){ %>is-current<% } %>">
						<div class="asideNav_btns <% if(menuSubLocation == 'menu04-01'){ %>is-open<% } %>">
							<div class="asideNav_btn"><a href="#">アコーディオン</a></div><button class="asideNav_close"><i></i></button>
						</div>
						<nav class="asideNav">
							<ul class="asideNav_list">
								<li class="asideNav_item"><a href=""><span>リンク (第3階層)</span></a></li>
								<li class="asideNav_item"><a href=""><span>リンク (第3階層)</span></a></li>
								<li class="asideNav_item"><a href=""><span>リンク (第3階層)</span></a></li>
							</ul>
						</nav><!-- /.asideNav -->
					</li>
					<li class="asideNav_item <% if(menuSubLocation == 'menu99-02'){ %>is-current<% } %>">
						<div class="asideNav_btns <% if(menuSubLocation == 'menu99-02'){ %>is-open<% } %>">
							<div class="asideNav_btn"><a href="#">アコーディオン(is-open)</a></div><button class="asideNav_close"><i></i></button>
						</div>
						<nav class="asideNav">
							<ul class="asideNav_list">
								<li class="asideNav_item <% if(menuSubLocation02 == 'menu99-02-01'){ %>is-current<% } %>"><a href=""><span>リンク (第3階層)</span></a></li>
								<li class="asideNav_item <% if(menuSubLocation02 == 'menu99-02-02'){ %>is-current<% } %>"><a href=""><span>リンク (第3階層)</span></a></li>
								<li class="asideNav_item <% if(menuSubLocation02 == 'menu99-02-03'){ %>is-current<% } %>"><a href=""><span>リンク (第3階層)</span></a></li>
							</ul>
						</nav><!-- /.asideNav -->
					</li>
					<li class="asideNav_item <% if(menuSubLocation == 'menu04-03'){ %>is-current<% } %>">
						<div class="asideNav_btns <% if(menuSubLocation == 'menu04-03-btn'){ %>is-open<% } %>">
							<div class="asideNav_btn"><a href="#">アコーディオン</a></div><button class="asideNav_close"><i></i></button>
						</div>
						<nav class="asideNav">
							<ul class="asideNav_list">
								<li class="asideNav_item"><a href=""><span>リンク (第3階層)</span></a></li>
								<li class="asideNav_item"><a href=""><span>リンク (第3階層)</span></a></li>
								<li class="asideNav_item"><a href=""><span>リンク (第3階層)</span></a></li>
							</ul>
						</nav><!-- /.asideNav -->
					</li>
				</ul>
			</nav><!-- /.asideNav -->
		</li>
		<li class="asideNav_item">
			<div class="asideNav_btns">
				<div class="asideNav_btn"><a href="#">アコーディオン</a></div><button class="asideNav_close"><i></i></button>
			</div>
			<nav class="asideNav">
				<ul class="asideNav_list">
					<li class="asideNav_item"><a href=""><span>リンク (第2階層)</span></a></li>
					<li class="asideNav_item"><a href=""><span>リンク (第2階層)</span></a></li>
				</ul>
			</nav><!-- /.asideNav -->
		</li>
		<li class="asideNav_item"><a href=""><span>リンクリンクリンクリンクリンクリンクリンク</span></a></li>
		<li class="asideNav_item"><a href=""><span>リンク</span></a></li>
		<li class="asideNav_item">
			<label class="customInput"><input type="checkbox" name="" id=""><span class="customInput_inner">チェックボックス</span></label>
		</li>
		<li class="asideNav_item">
			<label class="customInput"><input type="radio" name="" id=""><span class="customInput_inner">ラジオボタン</span></label>
		</li>
		<li class="asideNav_item">
			<label class="customInput"><input type="checkbox" name="" id=""><span class="customInput_inner">テキストの長いチェックボックステキストの長いチェックボックス</span></label>
		</li>
		<li class="asideNav_item"><div><span>テキスト （リンクなし）</span></div></li>
	</ul><!-- /.asideNav_list -->
</nav><!-- /.asideNav -->

Styleguide 2.11.2
============================================================ */
.asideNav {
	$this: &;
	$size-closeBtn: 30px;
	margin: 0;
	&_list {
		margin: 0;
		list-style-type: none;
	}
	&_item {
		margin: 0;
		border-bottom: 1px dotted $gray-border;
		// &:first-child {
		//	border-top: 1px dotted $gray-border;
		// }

		// radio ・ checkbox デザインver.
		.customInput {
			display: block;
			padding: #{$gutter-gSide} #{$gutter-gSide} #{$gutter-gSide} .1em;
			color: $baseColor;
			@include fs(14);
			font-weight: 500;
			line-height: 1.4;

			> [type="radio"] + span,
			> [type="checkbox"] + span {
				position: relative;
				display: inline-block;
				padding-left: calc( #{$icon-radio-off-width} / 2  + 5px );
			}
			//　チェックボックス / ラジオボタン
			> [type="radio"] + span::before ,
			> [type="radio"]:checked + span::after ,
			> [type="checkbox"] + span::before ,
			> [type="checkbox"]:checked + span::after {
				content: "";
				display: inline-block;
				position: absolute;
				// top: 1px;
				top: 50%;
				left: 0;
				vertical-align: middle;
				-webkit-transform: translateY(-50%);
				        transform: translateY(-50%);
			}
			> [type="radio"] + span::before {
				@include sprite2x($icon_radio-off);
			}
			> [type="radio"]:checked + span::after {
				@include sprite2x($icon_radio-on);
			}
			> [type="checkbox"] + span::before {
				@include sprite2x($icon_checkbox-off);
			}
			> [type="checkbox"]:checked + span::after {
				@include sprite2x($icon_checkbox-on);
			}
		}

		// only
		&-only {
			border-bottom: none;
		}
	}

	// 1階層目
	// -----------------------------------------
	&_item ,
	&_item > &_btns > &_btn {
		position: relative;
		> a ,
		> div:not([class]) {
			position: relative;
			display: block;
			padding: #{$gutter-gSide};
			color: $baseColor;
			@include fs(14);
			line-height: 1.4;
			font-weight: 500;
			text-decoration: none;
			> span {
				position: relative;
				display: block;
				color: inherit;
				padding-right: 10px;
				&::before {
					content: "";
					display: inline-block;
					position: absolute;
					top: 50%;
					right: 0;
					width: 12px;
					height: 12px;
					border-top: 1px solid $baseColor;
					border-right: 1px solid $baseColor;
					-webkit-transform: translate(0, -50%) rotate(45deg);
					        transform: translate(0, -50%) rotate(45deg);
					@include transitionType01;
				}
			}
		}
		> div:not([class]) {
			color: $gray;
		}
	}

	// 通常リンク
	&_item {
		// is-current
		&.is-current {
			> a {
				color: $mainColor01;
				background-color: $gray-bg;
			}
		}
	}

	// アコーディオンリンク
	&_btns {
		position: relative;
		margin: 0;
		&.is-open {
			#{$this}_btn {
				color: $mainColor01;
			}
			#{$this}_close {
				> i {
					&::before {
						-webkit-transform: rotate(-90deg);
						        transform: rotate(-90deg);
					}
				}
			}
		}
		&.is-current {
			#{$this}_btn {
				> a {
					color: $mainColor01;
					background-color: $gray-bg;
				}
			}
		}
	}
	&_btn {
		position: relative;
		display: block;
		margin: 0;
		padding-top: 2px;
		> a ,
		> div {
			color: $baseColor;
			padding-right: 35px !important;
			> span {
				&::before {
					display: none;
				}
			}
		}
	}

	// アコーディオン開閉アイコン
	&_close {
		position: absolute;
		display: block;
		top: 0%;
		right: 0;
		width: #{$size-closeBtn};
		height: 100%;
		> i {
			&::before ,
			&::after {
				content: "";
				display: inline-block;
				position: absolute;
				top: 50%;
				right: 17px;
				margin-top: -6px;
				width: 1px;
				height: 13px;
				background: $gray-dot;
				@include transitionType01;
			}
			&::before {
			}
			&::after {
				transform: rotate(90deg);
			}
		}
	}

	// リンク横のアイコン
	&_icon {
		content: "";
		display: inline-block;
		position: absolute;
		top: 50%;
		left: 0;
		width: 10px;
		height: 10px;
		-webkit-transform: translateY(-50%);
		        transform: translateY(-50%);
	}

	// 2階層目
	// -----------------------------------------
	&_item &_list {
		margin: 0 0 0 #{$gutter-gSide};
	}
	&_item &_item {
		border: none;
	}

	&_item &_item ,
	&_item &_item > &_btns > &_btn {
		> a ,
		> div:not([class]) {
			padding: 8px #{$gutter-gSide};
			@include fs(13);
			> span {
				&::before {
					width: 8px;
					height: 8px;
				}
			}
		}
	}

	// リンクエリアで開閉 ( リンクと開閉ボタンがセット )
	&[data-asidenav="block"] {
		#{$this}_close {
			// z-index: 5;
			pointer-events: none;
		}
	}
}
// hover style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.asideNav {
		$this: &;
		// 通常リンク
		&_item {
			> a {
				&:hover {
					background-color: $gray-bg;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
aside関連 : 検索窓

gSideSearch

Markup:
<form method="post" action="#">
	<div class="gSideSearch">
		<div class="gSideSearch_inner">
			<div class="gSideSearch_form">
				<input type="hidden" name="mode" value="confirm">
				<div class="gSideSearch_inputs">
					<input type="text" class="gSideSearch_input" name="" placeholder=""><!--
					--><input type="submit" value="" class="gSideSearch_btn" title="検索">
				</div>
			</div><!-- /.gSideSearch_form -->
		</div>
	</div><!-- /.gSideSearch -->
</form><!-- /form -->

Styleguide 2.11.3
============================================================ */
.gSideSearch {
	$this: &;
	$width-btn: 34px;
	$height-form: 40px;

	display: block;
	position: relative;
	&_inner {
		margin: 0 auto;
	}
	&_form {
	}
	&_inputs {
		// display: -webkit-flex;
		// display: flex;
		// -webkit-justify-content: space-between;
		//		justify-content: space-between;
		// -webkit-align-items: center;
		//		align-items: center;
		// -webkit-flex-wrap: nowrap;
		//		flex-wrap: nowrap;
		@include inline_block-p;
		& > * {
			@include inline_block;
			margin: 0;
			min-height: #{$height-form} !important;
			height: #{$height-form} !important;
			line-height: #{$height-form} !important;
			outline: none;
			-webkit-appearance: none;
			-moz-appearance: none;
				appearance: none;
		}
	}
	&_input {
		padding: 4px 7px 0;
		width: calc( 100% - #{$width-btn} );
		width: calc( 100% - #{$width-btn} )\9;
		-webkit-border-radius: 3px 0 0 3px !important;
				border-radius: 3px 0 0 3px !important;
		border-right: none;
		@include fs(12);
	}
	&_btn {
		position: relative;
		width: calc( #{$width-btn} );
		min-width: calc( #{$width-btn} );
		background: $gray-light url("./../images/_sprite/icon_search.png") center no-repeat;
		-webkit-background-size: 16px;
			 -o-background-size: 16px;
				background-size: 16px;
		-webkit-border-radius: 0 3px 3px 0 !important;
				border-radius: 0 3px 3px 0 !important;
		border: none;
		cursor: pointer;
		&:focus {
			background-color: lighten($gray-bg, 2%);
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.gSideSearch {
		&_input {
			&:focus {
				@include fs(16);
			}
		}
	}
}

/*
aside関連 : サイドバナー

asideBanner

Markup:
<nav class="asideBanner">
	<ul class="asideBanner_list">
		<li class="asideBanner_item">
			<a href=""><img src="http://satyr.io/260x80/dddddd?text=This+is+Dummy+Image!!!&texture=cross" alt=""></a>
			<p class="asideBanner_caption">テキストテキスト</p>
		</li>
		<li class="asideBanner_item">
			<a href=""><img src="http://satyr.io/260x80/dddddd?text=This+is+Dummy+Image!!!&texture=cross" alt=""></a>
			<p class="asideBanner_caption">テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
		</li>
		<li class="asideBanner_item">
			<figure><img src="http://satyr.io/260x80/555555?text=No+Link+Image!!!&texture=cross" alt=""></figure>
			<p class="asideBanner_caption">画像表示のみ（リンクなし）</p>
		</li>
	</ul>
</nav><!-- /.asideBanner -->

Styleguide 2.11.4
============================================================ */
.asideBanner {
	$this: &;

	&_list {
		margin: 0;
		list-style-type: none;
	}
	&_item {
		margin: 0;
		&:not(:first-child) {
			margin-top: 1em;
		}
		> a ,
		> div ,
		> figure {
			position: relative;
			display: block;
			margin: 0 auto;
			width: 100%;
			text-align: center;
			color: $baseColor;
			text-decoration: none;
			// background-color: #eee;
			img {
				margin: auto;
			}
		}
	}

	&_caption {
		position: relative;
		margin-top: 6px;
		color: $baseColor;
		@include fs(12);
		line-height: 1.4;
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
aside関連 : サイドログイン

asideLogin

Markup:
<div class="asideLogin">
	<div class="asideLogin_inner">
		<h3 class="asideLogin_headLine">
			<img src="<%= siteRoot %>assets/images/global/img_logo-nyusatsu-net.png" alt="入札ネット" />
			<small>公共工事入札情報<br>配信サービス「入札ネット」</small>
		</h3>
		<form action="<%= siteRoot %>xxx/" method="post" name="">
			<div class="asideLogin_field">
				<dl class="asideLogin_table">
					<dt class="asideLogin_th"><span>ユーザーID:</span></dt>
					<dd class="asideLogin_td">
						<input type="text" name="" class="pcW100p spW100p">
					</dd>
				</dl>
				<dl class="asideLogin_table">
					<dt class="asideLogin_th"><span>パスワード:</span></dt>
					<dd class="asideLogin_td">
						<input type="password" name="" class="pcW100p spW100p">
					</dd>
				</dl>
				<!-- <span class="enquete_error"><b>IDまたはパスワードが違います。</b></span> -->
			</div><!-- /.asideLogin_field -->
			<div class="asideLogin_btns">
				<button type="button" id="" class="asideLogin_btn"><span>ログイン</span></button>
				<p class="asideLogin_reminders">
					<a href="<%= siteRoot %>reminder/"><span>パスワードを忘れた方はこちら</span></a>
				</p>
			</div>
		</form><!-- /form -->
	</div>
</div><!-- /.asideLogin -->

Styleguide 2.11.5
============================================================ */
.asideLogin {
	$gutter-v: 20px;
	&_inner {
		padding: 18px 18px 25px;
	}
	&_headLine {
		padding: 10px 0;
		text-align: center;
		small {
			display: block;
			margin-top: 5px;
			@include fs(12);
		}
	}

	// _field area
	&_field {
		margin-top: 15px;
	}
	&_table {
		margin: 0;
		&:not(:first-child) {
			margin-top: 8px;
		}
		& > * {
			margin: 0;
			padding: 0;
			border: none;
			line-height: 1.35;
		}
	}
	&_th {
		@include fs(14);
	}
	&_td {
		margin-top: 5px;
		input {
			padding: 6px;
		}
	}

	// _btns area
	&_btns {
		margin-top: 30px;
		text-align: center;
	}
	&_btn {
		display: inline-block;
		padding: 10px 1em;
		width: 100%;
		color: $white;
		@include fs(14);
		background-color: $mainColor03;
		border: 1px solid;
	}

	&_reminders {
		margin-top: 35px;
		text-align: center;
		> a {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			color: $mainColor03;
			@include fs(14);
			text-decoration: underline;
			&::before {
				content: "?";
				display: inline-block;
				vertical-align: middle;
				margin-right: 10px;
				width: 22px;
				height: 22px;
				line-height: 22px;
				text-align: center;
				color: $white;
				font-weight: bold;
				@include ff-Montserrat;
				text-decoration: none;
				background-color: $mainColor03;
			}
		}
	}

	// error message
	&_errorMsg {
		margin: 20px 0;
		padding: 10px;
		background: lighten($red, 50%);
		border: 1px solid $red;
		-webkit-border-radius: 5px;
		        border-radius: 5px;
		-webkit-box-shadow: 2px 2px rgba($red, .5);
		        box-shadow: 2px 2px rgba($red, .5);
	}
	&_errorTxt {
		margin: 0;
		color: $red;
		@include fs(14);
		font-weight: bold;
		small {
			display: block;
			margin-top: 5px;
			@include fs(12);
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
}


/*
追従バナー

Markup:

Styleguide 2.12
============================================================ */
.stickyBanner {
	$this: &;

	position: sticky;
	left: 0;
	bottom: -1px;
	width: 100%;
	z-index: $zi-sticky;
	&_inner {
	}
	&_list {
		margin: 0;
		list-style-type: none;
	}
	&_item {
		& > a ,
		& > div {
			display: inline-block;
			text-decoration: none;
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
}

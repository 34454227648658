@charset "UTF-8";

// ====================================================================================
// _aspect-ratio
//
// aspect ratio SCSS mixins
// ====================================================================================

// @include aspectRatio(4, 3, contain);
@mixin aspectRatio($ar-width, $ar-height, $ar-fitType) {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  margin: auto;
  text-align: center;
  aspect-ratio: calc(#{$ar-width} / #{$ar-height});
  text-decoration: none;
  overflow: hidden;

  padding-top: calc(#{$ar-height} / #{$ar-width} * 100%);
  width: 100%;

  svg, img {
    flex: 0 0 auto;
    max-height: 100%;
    object-fit: #{$ar-fitType};
    -webkit-backface-visibility: visible;
    backface-visibility: visible;

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    font-family: 'object-fit: #{$ar-fitType};';
  }

  // @supports内の除算もcalc()で対応
  @supports not (aspect-ratio: calc(#{$ar-width} / #{$ar-height})) {
    // 古いブラウザ向けのフォールバックなどをここに書く
  }
}

// @include aspectOrigin(contain);
@mixin aspectOrigin($ar-fitType) {
  display: flex;
  justify-content: center;
  align-items: center;

  aspect-ratio: auto;

  position: relative;
  margin: auto;
  padding-top: 0;
  text-align: center;
  text-decoration: none;
  overflow: hidden;

  svg, img {
    flex: 0 0 auto;
    object-fit: #{$ar-fitType};
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    margin: auto;
    font-family: 'object-fit: #{$ar-fitType};';

    position: relative;
  }
}


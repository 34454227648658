@charset "UTF-8";

// ====================================================================================
// _keyframes
//
// アニメーション用のkeyframes
// ====================================================================================

// @keyframes
// -------------------------------------------------------------

// swing (横に揺れるアニメーション)
@keyframes swing {
	0% { transform: rotate(0deg); }
	25% { transform: rotate(5deg); }
	50% { transform: rotate(0deg); }
	75% { transform: rotate(-5deg); }
	100% { transform: rotate(0deg); }
}

// popping (跳ねるよなアニメーション)
@keyframes popping {
	0%, 100% { transform: translateY(0); }
	50% { transform: translateY(15px); }
}

// rotation (Y軸を起点に回転するアニメーション)
@keyframes rotation {
	0% { transform: rotateY(0); }
	40% { transform: rotateY(-360deg); }
	100% { transform: rotateY(0); }
}

// rotation2 (回転するアニメーション)
@keyframes rotation2 {
	0% { transform: rotateY(0); }
	40% { transform: rotateY(-180deg); }
	100% { transform: rotateY(0); }
}

// loder用
@-webkit-keyframes spin01 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes spin01 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

// tabFadeIn (タブ切替用)
@include keyframes(tabFadeIn) {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}

// index_design07 ver.
@keyframes D7-fixedPcAnm {
	0% {
		transform: translateY(-200%)
	}
	100% {
		transform: translateY(0)
	}
}

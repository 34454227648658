@charset "UTF-8";
@import '_config.scss';

/*
ページ用モジュール

各ページ用のclass指定
*/

/*
	.anchorPosition
<div id="anc01" name="anc01" class="anchorPosition"></div>
------------------------------------------------------------------------ */
.anchorPosition {
	$this: &;
	position: relative;
	z-index: -1;
	margin-top: -120px;
	padding-top: 120px;
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.anchorPosition {
		$this: &;
		margin-top: calc( -#{$fixedHeight} - 15px );
		padding-top: calc( #{$fixedHeight} + 15px );
	}
}

/*
	.anchorPosition02
<div id="anc01" name="anc01" class="anchorPosition02"></div>
------------------------------------------------------------------------ */
.anchorPosition02 {
	$this: &;
	position: relative;
	z-index: -1;
	margin-top: -10px;
	padding-top: 10px;
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.anchorPosition02 {
		$this: &;
		margin-top: calc( -#{$fixedHeight} - 15px );
		padding-top: calc( #{$fixedHeight} + 15px );
	}
}

/*
	.contact
------------------------------------------------------------------------ */
.contact {
	$this: &;
	margin-top: 75px;
	&_lead {
		text-align: center;
		@include fs(18);
		font-weight: bold;
		line-height: 1.8;
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.contact {
		$this: &;
		margin-top: 40px;
		&_lead {
			text-align: left;
			@include fs(14);
		}
	}
}

/*
	.contactGuide
------------------------------------------------------------------------ */
.contactGuide {
	$this: &;
	$gutter-h: 30px;
	$gutter-v: 30px;

	margin: 60px 0 0;
	&_list {
		margin: 0;
		list-style-type: none;
		& > *:not(:first-child) {
			margin: #{$gutter-v} 0 0;
		}
	}
	&_item {
		border: 2px solid $mainColor01;
		> a ,
		> div {
			position: relative;
			display: block;
			padding: 12px 15px 40px;
			text-decoration: none;
		}
		&-service {
			#{$this}_title {
				&::before {
					@include sprite($icon_setting);
				}
			}
		}
		&-blog {
			#{$this}_title {
				&::before {
					@include sprite($icon_edit);
				}
			}
		}
		&-customer {
			#{$this}_title {
				&::before {
					@include sprite($icon_chat);
				}
			}
		}
	}

	&_main {
		text-align: center;
	}
	&_title {
		position: relative;
		padding-top: 68px;
		@include fs(22);
		font-weight: bold;
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 50%;
			-webkit-transform: translateX(-50%);
			        transform: translateX(-50%);
		}
	}
	&_txt {
		margin-top: 0;
		@include fs(14);
		line-height: 1.8;
	}
	&_footer {
		margin-top: 30px;
		text-align: center;
		.btn {
			padding-top: 20px;
			padding-bottom: 20px;
			width: 100%;
			@include fs(16);
		}
	}

	// layout module
	$gutter-grid-h: 30px;
	$gutter-grid-v: 30px;
	&-grid {
		#{$this}_list {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-wrap: wrap;
					flex-wrap: wrap;

			margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		}
		&-v {
			#{$this}_list {
				margin: 0;
				display: block;
				overflow: hidden;
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-10 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		&-colAuto {
			#{$this}_item {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: auto !important;
			}
		}
	}
}
// PC style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.contactGuide {
		$this: &;

		// layout module
		$gutter-grid-h: 30px;
		$gutter-grid-v: 30px;
		&-grid {
			&-v-pc {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-pc {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.contactGuide {
		$this: &;
		$gutter-h: 15px;
		$gutter-v: 15px;

		margin-top: 30px;
		&_list {
			& > *:not(:first-child) {
				margin: #{$gutter-v} 0 0;
			}
		}

		&_item {
			> a ,
			> div {
				padding: 10px 10px 20px;
			}
		}

		&_title {
			padding-top: 58px;
			@include fs(18);
		}
		&_txt {
			margin-top: 15px;
		}
		&_footer {
			margin-top: 20px;
			.btn {
				padding-top: 16px;
				padding-bottom: 16px;
				@include fs(14);
			}
		}


		// layout module
		$gutter-grid-h: 15px;
		$gutter-grid-v: 15px;
		&-grid {
			#{$this}_list {
				margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
			}
			&-v-sp {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-sp {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}

/*
	.pageID-company
	会社概要
------------------------------------------------------------------------ */
.pageID-company {
	$this: &;
	.commonTable {
		$this: '.'+commonTable;
		th ,
		td {
			padding: 16px 18px;
		}
		&-companyData {
			max-width: 980px;
			th {
				text-align: left;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.pageID-company {
		$this: &;
		.commonTable {
			$this: '.'+commonTable;
			th ,
			td {
				padding: 12px 8px;
			}
			&-companyData {
				max-width: none;
			}
		}
	}
}

/*
	.pageID-service
	サービス紹介
------------------------------------------------------------------------ */
.pageID-service {
	$this: &;
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
	.serviceSummary
------------------------------------------------------------------------ */
.serviceSummary {
	$this: &;
	$gutter-com: 1em;
	$gutter-v: 45px;
	$side-gutter-h: 50px;
	$side-width: 37%;

	margin-bottom: 40px;
	padding-bottom: 50px;
	border-bottom: 2px solid $mainColor01;
	&_inner {
		display: table;
		// table-layout: fixed;
		width: 100%;
		& > * {
			display: table-cell;
			vertical-align: middle;
			padding: 3em 1em;
		}
	}
	&_hero {
		width: #{$side-width};
		min-width: #{$side-width};
	}
	&_figure {
		margin: 0;
		text-align: center;
	}
	&_img {
	}

	&_card {
		width: 100%;
	}
	&_txt {
		margin: 0;
		@include fs(18);
		line-height: 1.8;
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.serviceSummary {
		$this: &;
		$gutter-com: 1em;
		$gutter-v: 10px;
		$side-gutter-h: 50px;
		$side-width: 100%;

		margin-bottom: 30px;
		padding-bottom: 20px;
		&_inner {
			display: block;
			& > * {
				display: block;
				padding: 1em;
			}
		}
		&_hero {
			width: 100%;
			min-width: 0;
		}
		&_img {
			max-width: 250px;
		}

		&_card {
			margin-top: #{$gutter-v};
			padding: 0;
		}
		&_txt {
			@include fs(16);
		}
	}
}

/*
	.serviceGuide
------------------------------------------------------------------------ */
.serviceGuide {
	$this: &;
	$gutter-com: 1em;
	$gutter-v: 40px;
	$side-gutter-h: 38px;
	$side-width: 38%;

	position: relative;
	margin: 40px auto 0;
	&:first-child {
		margin-top: 0;
	}

	&_list {
		margin: 0;
		list-style-type: none;
		& > *:not(:first-child) {
			margin: #{$gutter-v} 0 0;
		}
	}
	&_item {
		border: 1px solid $gray-border;
		pointer-events: none;
		@include transitionType01;
		> a ,
		> div {
			display: table;
			table-layout: fixed;
			width: 100%;
			position: relative;
			// display: block;
			padding: 38px 42px;
			color: $baseColor;
			text-decoration: none;
			& > * {
				display: table-cell;
			}
		}
	}

	// _hero area
	&_hero {
		width: #{$side-width};
		min-width: #{$side-width};
		text-align: center;
		vertical-align: middle;
	}
	&_figure {
		margin: 0 auto;
	}
	&_img {
	}

	&_card {
		vertical-align: top;
	}
	&_hero + &_card {
		padding-left: #{$side-gutter-h};
	}
	&_title {
		@include fs(24);
		font-weight: bold;
	}
	&_txt {
		margin: 0;
		@include fs(18);
		line-height: 1.8;
		&:not(:first-child) {
			margin-top: 15px;
		}
	}
	&_btns {
		margin-top: 50px;
		text-align: right;
		.btn {
			min-width: 480px;
			width: auto;
			pointer-events: auto;
		}
	}
}
// hover style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.serviceGuide {
		$this: &;
		&_item {
			&:hover {
				transform: translateY(-2px);
				border-color: $mainColor01;
				@include boxShadow01;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.serviceGuide {
		$this: &;
		$gutter-com: 1em;
		$gutter-v: 20px;
		$side-gutter-h: 18px;
		$side-width: 100%;

		position: relative;
		margin: 30px auto 0;
		&:first-child {
			margin-top: 0;
		}

		&_list {
			& > *:not(:first-child) {
				margin: #{$gutter-v} 0 0;
			}
		}
		&_item {
			pointer-events: auto;
			> a ,
			> div {
				display: block;
				padding: 20px 15px;
				& > * {
					display: block;
				}
			}
		}

		// _hero area
		&_hero {
			width: #{$side-width};
			min-width: #{$side-width};
		}
		&_img {
			max-width: 200px;
		}

		&_card {
		}
		&_hero + &_card {
			padding: #{$side-gutter-h} 0 0;
		}
		&_title {
			@include fs(18);
		}
		&_txt {
			@include fs(14);
		}
		&_btns {
			margin-top: 25px;
			text-align: center;
			.btn {
				min-width: 0;
				width: 100%;
			}
		}
	}
}

/*
	.serviceGuide02
------------------------------------------------------------------------ */
.serviceGuide02 {
	$this: &;
	$gutter-com: 1em;
	$gutter-v: 40px;
	$side-gutter-h: 38px;
	$side-width: 38%;

	position: relative;
	margin: 40px auto 0;
	&:first-child {
		margin-top: 0;
	}

	&_list {
		margin: 0;
		list-style-type: none;
		& > *:not(:first-child) {
			margin: #{$gutter-v} 0 0;
		}
	}
	&_item {
		border: 1px solid $gray-border;
		pointer-events: none;
		@include transitionType01;
		> a ,
		> div {
			width: 100%;
			position: relative;
			display: block;
			padding: 30px 20px;
			color: $baseColor;
			text-decoration: none;
		}
	}

	// _hero area
	&_hero {
		text-align: center;
		padding: 40px 0;
	}
	&_figure {
		margin: 0 auto;
	}
	&_img {
	}

	&_card {
		padding: 10px 1.4em;
	}
	&_txt {
		margin: 0;
		@include fs(18);
		line-height: 1.8;
		&:not(:first-child) {
			margin-top: 15px;
		}
	}
	&_btns {
		margin-top: 20px;
		text-align: right;
		.btn {
			min-width: 100%;
			width: auto;
			pointer-events: auto;
		}
	}

	// layout module
	$gutter-grid-h: 40px;
	$gutter-grid-v: 40px;
	&-grid {
		#{$this}_list {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-wrap: wrap;
					flex-wrap: wrap;

			margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		}
		&-v {
			#{$this}_list {
				margin: 0;
				display: block;
				overflow: hidden;
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-10 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		&-colAuto {
			#{$this}_item {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: auto !important;
			}
		}
	}
}
// hover style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.serviceGuide02 {
		$this: &;
		&_item {
			&:hover {
				transform: translateY(-2px);
				border-color: $mainColor01;
				@include boxShadow01;
			}
		}
	}
}
// PC style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.serviceGuide02 {
		$this: &;

		// layout module
		$gutter-grid-h: 40px;
		$gutter-grid-v: 40px;
		&-grid {
			&-v-pc {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-pc {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.serviceGuide02 {
		$this: &;
		$gutter-com: 1em;
		$gutter-v: 20px;
		$side-gutter-h: 18px;
		$side-width: 100%;

		position: relative;
		margin: 30px auto 0;
		&:first-child {
			margin-top: 0;
		}

		&_list {
			& > *:not(:first-child) {
				margin: #{$gutter-v} 0 0;
			}
		}
		&_item {
			pointer-events: auto;
			> a ,
			> div {
				padding: 20px 15px;
				& > * {
					display: block;
				}
			}
		}

		// _hero area
		&_hero {
			padding: 10px 0;
		}
		&_img {
			// max-width: 200px;
		}

		&_card {
			padding: #{$side-gutter-h} 0 0;
		}
		&_txt {
			@include fs(14);
		}
		&_btns {
			.btn {
				width: 100%;
			}
		}

		// layout module
		$gutter-grid-h: 20px;
		$gutter-grid-v: 20px;
		&-grid {
			#{$this}_list {
				margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
			}
			&-v-sp {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-sp {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}


/*
	.pageID-blog
------------------------------------------------------------------------ */
.pageID-blog {
	$this: &;
	.blogSlider {
		$this: '.'+blogSlider;
		margin-top: -10px;
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.pageID-blog {
		$this: &;
		.blogSlider {
			$this: '.'+blogSlider;
			margin-top: 40px;
		}
	}
}


/*
	.pageID-reminder
------------------------------------------------------------------------ */
.pageID-reminder {
	$this: &;
}
.reminder {
	$this: &;
	$gutter-h: 50px;

	&_section {
		padding: 40px;
		background-color: $gray-bg;
		&:not(:first-child) {
			margin-top: #{$gutter-h};
		}
	}
	&_headLine {
		text-align: center;
		color: $mainColor01;
		@include fs(24);
		font-weight: bold;
	}
	&_block {
		padding: 25px 1em;
		&:not(:first-child) {
			margin-top: 38px;
			border-top: 1px solid $gray-border;
		}
		&:first-of-type {
			margin-top: 0;
			border: none;
		}
	}
	&_txt ,
	&_txt02 {
		line-height: 1.85;
	}
	&_txt {
		@include fs(18);
	}
	&_txt02 {
		@include fs(14);
	}
	&_form {
		margin-top: 27px;
		text-align: center;
	}
	&_btns {
		margin-top: 42px;
		text-align: center;
		.btn {
			min-width: 480px;
			width: auto;
		}
	}

	.contactInfo {
		border: none;
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.reminder {
		$this: &;
		$gutter-h: 30px;

		&_section {
			padding: 20px 10px;
			&:not(:first-child) {
				margin-top: #{$gutter-h};
			}
		}
		&_headLine {
			@include fs(20);
		}
		&_block {
			padding: 10px .5em;
			&:not(:first-child) {
				margin-top: 20px;
			}
			&:first-of-type {
				margin-top: 0;
			}
		}
		&_txt ,
		&_txt02 {
			line-height: 1.6;
		}
		&_txt {
			@include fs(14);
		}
		&_txt02 {
			@include fs(12);
		}
		&_form {
			margin-top: 15px;
		}
		&_btns {
			margin-top: 30px;
			.btn {
				min-width: 0;
				width: 100%;
			}
		}
	}
}














/*
	.pageID-error
------------------------------------------------------------------------ */
.pageID-error {
	$this: &;
	.main_mainAreaInside {
		background-color: transparent;
	}
	.errorPage {
		&_inner {
			display: -webkit-flex;
			display: flex;
			-webkit-justify-content: center;
					justify-content: center;
			-webkit-align-items: center;
					align-items: center;
			-webkit-flex-flow: column wrap;
					flex-flow: column wrap;

			position: relative;
			min-height: 400px;
			text-align: center;
		}
		& > * {
			width: 100%;
			line-height: 1.4;
		}
		&_title {
			color: $mainColor01;
			@include fs(40);
			line-height: 1.2;
		}
		&_txt {
			margin-top: 2em;
			@include fs(15);
			line-height: 1.4;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.pageID-error {
		$this: &;
		.errorPage {
			&_inner {
				min-height: 200px;
			}
			&_title {
				@include fs(30);
			}
			&_txt {
				@include fs(13);
			}
		}
	}
}



/*
	.pageID-xxxxxx
------------------------------------------------------------------------ */
.pageID-xxxxxx {
	$this: &;
	.xxxxxxxxxx {
		$this: '.'+xxxxxxxxxx;
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
}


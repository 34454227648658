@charset "UTF-8";
@import '_config';
@import '_core-min';
@import '_sprites/_sprite';
@import '_sprites/_sprite_mixin';

@import 'https://ajax.googleapis.com/ajax/libs/jqueryui/1/themes/smoothness/jquery-ui.css';
// fontawesome v4.7.0
@import "_font-awesome/_font-awesome";

// google font
@import "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Noto+Serif:wght@400;700&family=Montserrat:wght@300;400;500;700&display=swap";

@import '_plugins/_animate/_animate.min';
// jquery ui
@import '_plugins/_jquery_ui/_jquery.ui-1.12.1.min';
@import '_plugins/_jquery_ui/_jquery.ui-1.12.1-datepicker.min';
// slick
@import '_plugins/_slick/_slick';
@import '_plugins/_slick/_slick-theme';

// _base css
@import '_base/_ress.min'; // reset css
@import '_base/_base-btn';
@import '_base/_shared';

// wysiwyg original css
@import '_base/_wysiwyg';

// enquete / customDialog css
@import '_modules/_enquete';
// @import '_modules/_fileForm';
@import '_js/_enqueteDialog';
// @import '_js/_customDialog';
@import '_js/_dialog';

// component-custo css
@import '_plugins/_component-custom';

@import '_default';
@import '_global';
@import '_parts';
@import '_module';
@import '_module-form';
@import '_module-list';
@import '_slider';
@import '_entry';
@import '_page';

@import '_sm_page'; // 各種サービスページ用CSS

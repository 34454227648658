@charset "UTF-8";
@import '_config.scss';

/*
ブロックリスト系モジュール

ブロックリスト系モジュール

Styleguide 6.
*/

/*
banner一覧

bannerList

Markup:
<div class="bannerList {$modifiers}">
	<div class="bannerList_list">
		<div class="bannerList_item bannerList_item-4-12pc bannerList_item-6-12sp" data-figure="">
			<figure class="bannerList_figure" data-figure-block="">
				<a href="#" target="_blank"><img src="http://fakeimg.pl/250x75/222222/fff/" alt=""></a>
				<figcaption class="bannerList_caption" data-figure-caption="">caption caption caption caption</figcaption>
			</figure>
		</div>
		<div class="bannerList_item bannerList_item-4-12pc bannerList_item-6-12sp" data-figure="">
			<figure class="bannerList_figure" data-figure-block="">
				<a href="#" target="_blank"><img src="http://fakeimg.pl/250x75/222222/fff/" alt=""></a>
				<figcaption class="bannerList_caption" data-figure-caption="">caption caption caption caption</figcaption>
			</figure>
		</div>
	</div>
</div><!-- /.bannerList -->

.bannerList-left - left
.bannerList-center - center
.bannerList-right - right
.bannerList-sideArray - sideArray

Styleguide 6.1
============================================================ */
.bannerList {
	$this: &;
	$gutter-grid-h: 20px;
	$gutter-grid-v: 20px;

	margin: 20px auto 0;
	&:first-child {
		 margin-top: 0;
	}
	&_list {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: stretch;
				align-items: stretch;
		-webkit-flex-wrap: wrap;
				flex-wrap: wrap;

		margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		list-style-type: none;
		&-v {
			margin: 0;
			display: block;
			overflow: hidden;
			#{$this}_item {
				margin: 0;
				&:not(:first-child) {
					margin-top: #{$gutter-grid-v};
				}
			}
		}
	}
	&_item {
		margin: calc( #{$gutter-grid-v} ) 0 0 calc( #{$gutter-grid-h} );
		position: relative;
		text-align: center;
		overflow: hidden;
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-10 {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
			}
		}
	}

	&_figure {
		margin: 0;
		text-align: center;
		> a {
			display: inline-block;
		}
	}
	&_img {
	}
	&_caption {
		text-align: left;
		margin-top: 6px;
		@include fs(14);
	}

	&-left {
		#{$this}_list {
			-webkit-justify-content: flex-start;
					justify-content: flex-start;
		}
	}
	&-center {
		#{$this}_list {
			-webkit-justify-content: center;
					justify-content: center;
		}
	}
	&-right {
		#{$this}_list {
			-webkit-justify-content: flex-end;
					justify-content: flex-end;
		}
	}
	&-sideArray {
		#{$this}_list {
			-webkit-justify-content: space-between;
					justify-content: space-between;
		}
	}
}
// PC style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.bannerList {
		$this: &;
		$gutter-grid-h: 20px;
		$gutter-grid-v: 20px;

		&_list {
			margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		}

		&_item {
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10pc {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
				}
			}
		}
	}
}
// SP style only
// --------------------------------------
@media screen and (max-width: $breakpoint-sp) {
	.bannerList {
		$this: &;
		$gutter-grid-h: 10px;
		$gutter-grid-v: 10px;

		&_list {
			margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		}
		&_item {
			margin: calc( #{$gutter-grid-v} ) 0 0 calc( #{$gutter-grid-h} );
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10sp {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
				}
			}
		}
	}
}

/*
blog一覧

blogPost

Markup:
<div class="blogPost">
	<div class="blogPost_list">
		<% for (var i = 0; i < 9; i++) { %>
		<article class="blogPost_item">
			<div class="blogPost_inside">
				<div class="blogPost_insideBox">
					<div class="blogPost_hero">
						<a href="xxx" class="blogPost_figure">
							<img src="https://satyr.dev/450x300/<%= i+1 %>?texture=cross" loading="lazy" alt="">
						</a>
					</div>
					<div class="blogPost_card">
						<p class="blogPost_tags">
							<span class="tag tag-category02" style="background: #458bdf; border-color: #458bdf; color: #fff">カテゴリ</span>
						</p>
						<p class="blogPost_title"><a href="xxx"><span>タイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトル</span></a></p>
						<div class="blogPost_metas">
							<p class="blogPost_dates"><time class="date">2020.00.00</time></p>
							<p class="blogPost_tags">
								<span class="tag tag-ad">分類（広告）</span>
							</p>
						</div>
						<p class="blogPost_lead">
							テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
							<br>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
						</p>
						<div class="blogPost_footer">
							<div class="blogPost_btns">
								<a href="xxx" class="btn btn-min btn-thin btn-border01 btn-arrowRight"><span>詳細</span></a>
							</div>
						</div><!-- /.blogPost_footer -->
					</div>
				</div>
			</div>
		</article><!-- /.blogPost_item -->
		<% } %>
		<article class="blogPost_item">
			<div class="blogPost_inside">
				<div class="blogPost_insideBox">
					<div class="blogPost_hero">
						<figure class="blogPost_figure blogPost_figure-noimage">
							<img src="/assets/images/noimage.jpg" loading="lazy" alt="ダミーイメージ">
						</figure>
					</div>
					<div class="blogPost_card">
						<p class="blogPost_tags">
							<span class="tag tag-category02" style="background: #458bdf; border-color: #458bdf; color: #fff">カテゴリ</span>
						</p>
						<p class="blogPost_title"><span>【表示のみの場合】 ○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○</span></p>
						<div class="blogPost_metas">
							<p class="blogPost_dates"><time class="date">2020.00.00</time></p>
							<p class="blogPost_tags">
								<span class="tag tag-ad">分類（広告）</span>
							</p>
						</div>
						<p class="blogPost_lead">
							テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
							<br>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
						</p>
						<div class="blogPost_footer">
							<div class="blogPost_btns">
								<a href="xxx" class="btn btn-min btn-thin btn-border01 btn-arrowRight"><span>詳細</span></a>
							</div>
						</div><!-- /.blogPost_footer -->
					</div>
				</div>
			</div>
		</article><!-- /.blogPost_item -->
	</div>
</div><!-- /.blogPost -->
Styleguide 6.2.1
============================================================ */
.blogPost {
	$this: &;
	$gutter-v: 16px;
	$side-gutter-h: 30px;
	$side-width: 300px;

	&_list {
	}
	&_item {
		position: relative;
		margin: 0;
		> a ,
		> div {
			position: relative;
			display: block;
			padding: #{$gutter-v} 0;
			color: $baseColor;
			text-decoration: none;
		}
		&:first-child {
			> a ,
			> div {
				padding-top: 0;
			}
		}
	}

	&_insideBox {
		display: -webkit-flex;
		display: flex;
		align-items: stretch;
	}

	&_hero {
		min-width: #{$side-width};
		width: #{$side-width};
		margin-right: #{$side-gutter-h};
	}
	&_figure {
		@include aspectRatio(400, 268, contain);
		text-decoration: none;
		@include hoverImgStyle01;
		@include boxShadow01;
		// img {
		// 	@include transitionType02;
		// }
		&-noimage {
			@include noImageStyle;
		}
	}

	&_card {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&_title {
		margin: 0;
		@include fs(16);
		font-weight: bold;
		line-height: 1.35;
		&:not(:first-child) {
			margin-top: 10px;
		}
		a {
			display: inline;
			color: inherit;
			@include hoverTxtStyle01;
		}
	}

	// _metas area
	&_metas {
		display: -webkit-flex;
		display: flex;
		align-items: center;
		gap: 5px 1em;
		margin: 0;
		@include fs(14);
		&:not(:first-child) {
			margin-top: 10px;
		}
	}

	// _tags area
	&_tags {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 3px;
		margin: 0;
		.tag {
		}
	}

	// _dates area
	&_dates {
		@include fs(14);
		flex: 0 0 auto;

		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 5px;

		margin: 0;
		color: $gray;
		@include ff-Montserrat;
		font-weight: bold;
		white-space: nowrap;
	}

	&_lead {
		margin: 0;
		@include fs(14);
		line-height: 1.4;
		// @include lineClamp(2);
		&:not(:first-child) {
			margin-top: 15px;
		}
	}

	&_footer {
		margin-top: auto;
		padding-top: 20px;
	}

	&_btns {
		text-align: right;
	}
}
// hover style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
}
// @media screen and (max-width: $breakpoint-pc) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.blogPost {
		$this: &;
		$gutter-com: .5em;
		$gutter-v: 20px;
		$main-gutter-h: 15px;
		$main-width: 100%;


		&_item {
			&:not(:first-child) {
				border-top: 1px solid $gray-border;
			}
			> a ,
			> div {
				padding: #{$gutter-v} 1em;
			}
		}

		&_insideBox {
			flex-direction: column;
			gap: 20px;
		}

		&_hero {
			width: 100%;
			min-width: 0;
			margin-right: 0;
			text-align: center;
		}

		&_title {
			// @include fs(13);
			&:not(:first-child) {
				margin-top: 5px;
			}
		}

		// _metas area
		&_metas {
			gap: 5px 10px;
			// @include fs(13);
		}

		// _dates area
		&_dates {
			// @include fs(12);
		}

		&_lead {
			// @include fs(12);
			&:not(:first-child) {
				margin-top: 10px;
			}
		}

		&_footer {
			margin-top: auto;
			padding-top: 10px;
		}
	}
}

/*
blog一覧02

blogPost02

Markup:

Styleguide 6.2.2
============================================================ */
.blogPost02 {
	$this: &;
	$gutter-v: 24px;

	position: relative;
	margin: 25px auto 0;
	&:first-child {
		margin-top: 0;
	}

	&_list {
		margin: 0;
		list-style-type: none;
		& > *:not(:first-child) {
			margin: #{$gutter-v} 0 0;
		}
	}
	&_item {
		> a ,
		> div {
			display: grid;
			gap: $gutter-v;
			position: relative;
			// color: $white;
			text-decoration: none;
			@include transitionType01;
		}
	}

	// _hero area
	&_hero {
	}
	&_figure {
		@include aspectRatio(330, 220, contain);
		text-decoration: none;
		@include hoverImgStyle01;
		@include boxShadow01;
		// img {
		// 	@include transitionType02;
		// }
		&-noimage {
			@include noImageStyle;
		}
	}

	&_card {
	}

	&_title {
		margin: 0;
		@include fs(16);
		font-weight: bold;
		line-height: 1.35;
		&:not(:first-child) {
			margin-top: 5px;
		}
		a {
			display: inline;
			color: inherit;
			@include hoverTxtStyle01;
		}
	}

	// _metas area
	&_metas {
		display: -webkit-flex;
		display: flex;
		align-items: center;
		gap: 5px 1em;
		margin: 0;
		@include fs(14);
		&:not(:first-child) {
			margin-top: 10px;
		}
	}

	// _tags area
	&_tags {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 3px;
		margin: 0;
		.tag {
		}
	}

	// _dates area
	&_dates {
		@include fs(14);
		flex: 0 0 auto;

		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 5px;

		margin: 0;
		color: $gray;
		@include ff-Montserrat;
		font-weight: bold;
		white-space: nowrap;
	}

	&_lead {
		margin: 0;
		@include fs(14);
		line-height: 1.4;
		@include lineClamp(2);
		&:not(:first-child) {
			margin-top: 15px;
		}

		&-lead3 {
			-webkit-line-clamp: 3;
		}
	}

	// layout module
	$gutter-grid-h: 24px;
	$gutter-grid-v: 24px;
	&-grid {
		#{$this}_list {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-wrap: wrap;
					flex-wrap: wrap;

			margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		}
		&-v {
			#{$this}_list {
				margin: 0;
				display: block;
				overflow: hidden;
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-10 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		&-colAuto {
			#{$this}_item {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: auto !important;
			}
		}
	}
}
// hover style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
}
// PC style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.blogPost02 {
		$this: &;

		// layout module
		$gutter-grid-h: 24px;
		$gutter-grid-v: 24px;
		&-grid {
			&-v-pc {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-pc {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.blogPost02 {
		$this: &;
		$gutter-com: .5em;
		$gutter-v: 20px;

		&_item {
			> a ,
			> div {
				padding: 0 1em;
			}
		}

		&_title {
			// @include fs(13);
			&:not(:first-child) {
				margin-top: 5px;
			}
		}

		// _metas area
		&_metas {
			gap: 5px 10px;
			// @include fs(13);
		}

		// _dates area
		&_dates {
			// @include fs(12);
		}

		&_lead {
			// @include fs(12);
			&:not(:first-child) {
				margin-top: 10px;
			}
		}


		// layout module
		$gutter-grid-h: 12px;
		$gutter-grid-v: 30px;
		&-grid {
			#{$this}_list {
				margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
			}
			&-v-sp {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-sp {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}

/*
お知らせ一覧

newsPost

Markup:
<div class="newsPost">
	<div class="newsPost_list">
		<article class="newsPost_item">
			<a href="#" target="_blank">
				<div class="newsPost_metas">
					<p class="newsPost_tags">
						<span class="tag" style="background-color: #458bdf; color: #fff">カテゴリ</span>
					</p>
					<p class="newsPost_dates"><time class="date">2020.00.00</time></p>
				</div>
				<p class="newsPost_title">テキストテキストテキストテキストテキスト</p>
			</a>
		</article>
		<article class="newsPost_item">
			<a href="#" target="_blank" class="newsPost_itemInner">
				<div class="newsPost_metas">
					<p class="newsPost_tags">
						<span class="tag" style="background-color: #458bdf; color: #fff">カテゴリ</span>
						<span class="tag" style="background-color: #244fa3; color: #fff">〇〇〇〇</span>
						<span class="tag" style="background-color: #6d96d6; color: #fff">〇〇〇〇</span>
					</p>
					<p class="newsPost_dates"><time class="date">2020.00.00</time></p>
				</div>
				<p class="newsPost_title">テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
			</a>
		</article>
	</div>
</div><!-- /.newsPost -->

Styleguide 6.3
============================================================ */
.newsPost {
	$this: &;

	&:not(:first-child) {
		margin-top: 25px;
	}
	&_list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	&_item {
		border-bottom: 1px dotted $gray-dot;
		> a ,
		> div {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
					align-items: center;

			position: relative;
			// display: block;
			padding: 14px 10px;
			@include fs(16);
			color: $baseColor;
			text-decoration: none;
		}
	}

	// _metas area
	&_metas {
		-webkit-flex: 0 0 auto;
		    -ms-flex: 0 0 auto;
		        flex: 0 0 auto;

		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
				align-items: center;
		& > * {
			margin: 0;
			vertical-align: middle;
			&:not(:first-child) {
				margin-left: 1.5em;
			}
		}
	}

	// _tags area
	&_tags {
		min-width: 120px;
		max-width: 120px;
		position: relative;
		margin: -5px 0 0 -5px;
		& > * {
			margin: 5px 0 0 5px;
		}
		.tag {
			padding: 4px 5px 3px;
			min-width: 110px;
			@include fs(12);
			font-weight: bold;
		}
	}

	// _dates area
	&_dates {
		margin-top: 0;
		color: $gray;
		@include ff-Montserrat;
		font-weight: bold;
		white-space: nowrap;
		& > * {
			margin: 0;
			&:not(:first-child) {
				margin-left: 5px;
			}
		}
	}

	&_title {
		margin: 0 0 0 2.8em;
		overflow: hidden;
	}
}
// hover style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.newsPost {
		$this: &;
		&_item {
			&:hover {
				> a {
					background: $gray-bg;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.newsPost {
		$this: &;

		&_item {
			> a ,
			> div {
				padding: 10px 5px;
			}
		}
		&_item {
			> a ,
			> div {
				-webkit-align-items: stretch;
						align-items: stretch;
				-webkit-flex-flow: column;
				        flex-flow: column;
				@include fs(14);
			}
		}

		// _metas area
		&_metas {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
					align-items: center;
			-webkit-justify-content: space-between;
					justify-content: space-between;
			margin-top: 10px;
			& > * {
				&:not(:first-child) {
					margin-left: 10px;
				}
			}
		}

		// _tags area
		&_tags {
			width: 100%;
			min-width: 0;
			max-width: none;
			.tag {
				min-width: 85px;
			}
		}

		&_title {
			margin: 10px 0 0;
		}
	}
}

@charset "UTF-8";
@import '_config.scss';

/*
各種サービスページ用モジュール

各種サービスページ用のclass指定
*/


/*
	@共通モジュール
	@建設工事情報
	@建設工事情報＠Mail
	@日本工業経済新聞社
	@入札ネット＋α
	@コンテンツ制作/広報PR支援
*/


/* ------------------------------------------------------------------------------------------------------ */
/* --- @共通モジュール --------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------ */
// 変数
// -------------------------------------------------------------
$baseWidth02: 1200px;

// --- color 関連 ( pageID-kdFile ) --------------------
$kdFileColor01: #00a0e9;
$kdFileColor02: #bc0101;
$kdFileColor03: #ce7777;

// --- color 関連 ( pageID-kdMail ) --------------------
$kdMailColor01: #b54a5d;
$kdMailColor02: #ffdfe5;
$kd-radius-com: 22px;
$kd-radius-com-sp: 12px;

// --- color 関連 ( pageID-kdNews ) --------------------
$kdNewsColor01: #006979;
$kdNewsColor02: #253f68;
$kdNewsColor03: #bed2d5;
$kdNewsColor04: #e5edee;


// --- color 関連 ( pageID-kdNet ) --------------------
$kdNetColor01: #ffe955;
$kdNetColor02: #ff4e00;
$kdNetColor03: #fffdf0;
$kdNetColor04: #009c74;
$kdNetColor05: #faf2a9;
$kdNetColor06: #333366;
$kdNetColor07: #ffe0d3;

// --- color 関連 ( pageID-contentMktg ) --------------------
$contentMktgColor01: #274775;


/*
	pageSection
------------------------------------------------------------------------ */
.pageSection {
	$this: &;
	$gutter-com: 1em;
																				// border: 1px solid blue;
	padding: 0 #{$gutter-com};
	&_inner {
		margin: 0 auto;
		max-width: #{$baseWidth};
	}

	// ______ pageID-kdFile
	&[id="kdFileConcept"] {
		padding-top: 50px;
		padding-bottom: 72px;
	}
	&[id="kdFileService"] {
		padding-top: 40px;
		padding-bottom: 90px;
		background-color: $gray-bg;
		background-image: url('/files/kd-file/bg_service01.png')
						, url('/files/kd-file/bg_service02.png');
		background-position: left top
							, right bottom;
		background-repeat: no-repeat
							, no-repeat;
	}
	&[id="kdFileGuide"] {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	&[id="kdFileCustomer"] {
		padding-top: 40px;
		padding-bottom: 80px;
		background-color: $kdFileColor03;
	}
	&[id="kdFileContact"] {
		padding-top: 120px;
		padding-bottom: 120px;
	}


	// ______ pageID-kdMail
	&[id="kdMailPoint"] {
		padding-top: 68px;
		padding-bottom: 80px;
	}
	&[id="kdMailProvision"] {
		padding-top: 40px;
		padding-bottom: 40px;
		background: $kdMailColor01;
	}
	&[id="kdMailRegister"] {
		padding-top: 80px;
		padding-bottom: 75px;
	}
	&[id="kdMailCustomer"] {
		padding-top: 60px;
		padding-bottom: 60px;
		background: $kdMailColor02;
	}
	&[id="kdMailPrice"] {
		padding-top: 80px;
		padding-bottom: 80px;
	}


	// ______ pageID-kdNews
	&[id="kdNewsGuide"] {
		padding-top: 100px;
		padding-bottom: 100px;
	}
	&[id="kdNewsSubscribe"] {
		padding-top: 38px;
		padding-bottom: 38px;
		background: $kdNewsColor04;
	}
	&[id="kdNewsContact"] {
		padding-top: 120px;
		padding-bottom: 120px;
	}


	// ______ pageID-kdNet
	&[id="kdNetRecommend"] {
		padding-top: 80px;
		padding-bottom: 20px;
	}
	&[id="kdNetGuide"] {
		padding: 0;
		background: $kdNetColor03;
		#{$this}_inner {
			max-width: none;
		}
	}
	&[id="kdNetIntro"] {
		padding-top: 70px;
		padding-bottom: 76px;
		background-repeat: repeat;
	}
	&[id="kdNetPrice"] {
		padding-top: 72px;
		padding-bottom: 40px;
	}
	&[id="kdNetTrial"] {
		padding-top: 50px;
		padding-bottom: 120px;
	}
	&[id="kdNetContact"] {
		padding-top: 120px;
		padding-bottom: 120px;
		background: $kdNetColor07;
	}


	// ______ pageID-contentMktg
	&[id="contentSummary"] {
		padding: 0;
		#{$this}_inner {
			max-width: none;
		}
	}
	&[id="contentIntro"] {
		padding-top: 98px;
		padding-bottom: 98px;
	}
	&[id="contentContact"] {
		padding-top: 100px;
		padding-bottom: 100px;
		background: $mainColor05;
	}

	// pageID-xxxxxx
	&[id="sssssssssss"] {
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.pageSection {
		$this: &;
		$gutter-com: 1em;

		padding: 0 #{$gutter-com};
		&_inner {
			max-width: none;
		}


		// ______ pageID-kdFile
		&[id="kdFileConcept"] {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		&[id="kdFileService"] {
			padding-top: 30px;
			padding-bottom: 50px;
			background-size: 50% auto
							, 35% auto;
		}
		&[id="kdFileGuide"] {
			padding-top: 20px;
			padding-bottom: 20px;
		}
		&[id="kdFileCustomer"] {
			padding-top: 30px;
		}
		&[id="kdFileContact"] {
			padding-top: 60px;
			padding-bottom: 60px;
		}


		// ______ pageID-kdMail
		&[id="kdMailPoint"] {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		&[id="kdMailProvision"] {
			padding-top: 40px;
			padding-bottom: 40px;
		}
		&[id="kdMailRegister"] {
			padding-top: 40px;
			padding-bottom: 40px;
		}
		&[id="kdMailCustomer"] {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		&[id="kdMailPrice"] {
			padding-top: 40px;
			padding-bottom: 40px;
		}


		// ______ pageID-kdNews
		&[id="kdNewsGuide"] {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		&[id="kdNewsSubscribe"] {
			padding-top: 38px;
			padding-bottom: 38px;
		}
		&[id="kdNewsContact"] {
			padding-top: 60px;
			padding-bottom: 60px;
		}


		// ______ pageID-kdNet
		&[id="kdNetRecommend"] {
			padding-top: 20px;
			padding-bottom: 20px;
		}
		&[id="kdNetGuide"] {
		}
		&[id="kdNetIntro"] {
			padding-top: 34px;
			padding-bottom: 40px;
		}
		&[id="kdNetPrice"] {
			padding-top: 34px;
			padding-bottom: 40px;
		}
		&[id="kdNetTrial"] {
			padding-top: 40px;
			padding-bottom: 40px;
		}
		&[id="kdNetContact"] {
			padding-top: 40px;
			padding-bottom: 40px;
		}


		// ______ pageID-contentMktg
		&[id="contentSummary"] {
		}
		&[id="contentIntro"] {
			padding-top: 48px;
			padding-bottom: 48px;
		}
		&[id="contentContact"] {
			padding-top: 50px;
			padding-bottom: 50px;
		}
	}
}


/*
	pageBtn
------------------------------------------------------------------------ */
.pageBtn {
	$this: &;
	@extend %btnStyle;
	padding-left: 1.5em;
	padding-right: 1.5em;
	background: #fff;

	color: $baseColor;
	border-color: $baseColor;

	&-color06 ,
	&-color05 ,
	&-color04 ,
	&-color03 ,
	&-color02 ,
	&-color01 {
		color: $white;
	}

	&-color01 {
		background-color: $kdMailColor01;
		border-color: $kdMailColor01;
	}

	&-color02 {
		background-color: $contentMktgColor01;
		border-color: $contentMktgColor01;
	}

	&-color03 {
		background-color: $kdFileColor02;
		border-color: $kdFileColor02;
	}

	&-color04 {
		background-color: $kdNewsColor01;
		border-color: $kdNewsColor01;
	}

	&-color05 {
		background-color: $kdNetColor04;
		border-color: $kdNetColor04;
	}

	&-color06 {
		background-color: $kdNetColor02;
		border-color: $kdNetColor02;
	}

	&-gradColor01 {
		color: $white;
		// text-shadow: 1px 1px 1px $kdNetColor02
		//			, -1px 1px 1px $kdNetColor02
		//			, 1px -1px 1px $kdNetColor02
		//			, -1px -1px 1px $kdNetColor02
		//			, 2px 2px 2px $kdNetColor02
		//			, -2px 2px 2px $kdNetColor02
		//			, 2px -2px 2px $kdNetColor02
		//			, -2px -2px 2px $kdNetColor02
		//			, 3px 3px 3px $kdNetColor02
		//			, -3px 3px 3px $kdNetColor02
		//			, 3px -3px 3px $kdNetColor02
		//			, -3px -3px 3px $kdNetColor02;
		background-color: $kdNetColor06;
	}


	&-shadow01 {
		-webkit-box-shadow: 6px 6px 8px rgba(0,0,0, .2);
		        box-shadow: 6px 6px 8px rgba(0,0,0, .2);
	}

	&-round01 {
		-webkit-border-radius: 10px;
		        border-radius: 10px;
	}

	&-arrowRight {
		> span {
			position: static;
			&::after {
				// @include fa-old($angle-right);
				content: "";
				display: inline-block;
				position: absolute;
				top: 50%;
				right: 12px;
				width: 12px;
				height: 12px;
				border-top: 2px solid;
				border-right: 2px solid;
				-webkit-transform: translate(0, -50%) rotate(45deg);
				        transform: translate(0, -50%) rotate(45deg);
			}
		}
	}

	&-thin {
		padding: 8px 1em;
	}

	&-thick {
		padding: 40px 2em;
		@include fs(36);
		font-weight: 700;
		> span {
			&::after {
				right: 28px;
				width: 40px;
				height: 40px;
				border-top-width: 6px;
				border-right-width: 6px;
			}
		}
	}

	&-thick02 {
		padding-top: 16px;
		padding-bottom: 16px;
		@include fs(16);
		font-weight: 700;
		> span {
			&::after {
				width: 18px;
				height: 18px;
			}
		}
	}

	&-thick03 {
		padding-top: 22px;
		padding-bottom: 22px;
		@include fs(18);
		font-weight: 700;
	}

	&-mail {
		// icon image
			$mail-imgUrl: './../images/_sprite/icon_mail02.png';
			$mail-width: image-width($mail-imgUrl);
			$mail-height: image-height($mail-imgUrl);
		> span {
			&::before {
				content: "";
				position: absolute;
				top: 50%;
				left: 15px;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-webkit-transform: translateY(-50%);
				        transform: translateY(-50%);
				@include sprite($icon_mail02);
			}
		}
	}
}
// @media screen and (max-width: $breakpoint-pc) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.pageBtn {
		$this: &;

		padding-left: 1em;
		padding-right: 1em;

		&-arrowRight ,
		&-thick {
			> span {
				&::after {
					right: 10px;
					width: 16px;
					height: 16px;
					border-top-width: 3px;
					border-right-width: 3px;
				}
			}
		}

		&-thick {
			padding: 16px 1.5em;
			@include fs(18);
		}

		&-thick02 {
			padding-top: 12px;
			padding-bottom: 12px;
			@include fs(15);
			> span {
				&::after {
					width: 12px;
					height: 12px;
				}
			}
		}

		&-thick03 {
			padding-top: 18px;
			padding-bottom: 18px;
			@include fs(16);
		}

		&-mail {
			// icon image
				$mail-imgUrl: './../images/_sprite/icon_mail02.png';
				$mail-width: image-width($mail-imgUrl);
				$mail-height: image-height($mail-imgUrl);
			padding-left: calc( #{$mail-width} / 2 );
			> span {
				&::before {
					left: 10px;
					@include sprite2x($icon_mail02);
				}
			}
		}
	}
}


/* ------------------------------------------------------------------------------------------------------ */
/* --- @建設工事情報 --------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------ */

/*
	.pageID-kdFile
------------------------------------------------------------------------ */
.pageID-kdFile {
	$this: &;
	.categoryHeader {
		$this: '.'+categoryHeader;
		$gutter-com: 5%;

		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
				align-items: center;

		position: relative;
		height: 625px;
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-background-size: cover;
				background-size: cover;
		&_inner {
			width: 100%;
		}

		&_logo {
			display: block;
			position: absolute;
			top: 50px;
			left: 2%;
			margin: 0;
			width: 100%;
			> span {
				display: block;
			}
		}

		&_headLine {
			margin-top: 30px;
			padding: 45px #{$gutter-gSide02};
			background-color: rgba(0,0,0, .6);
		}

		&_headLineInner {
			margin: 0 auto;
			max-width: #{$baseWidth};
			text-align: center;
			& > * {
				display: block;
				color: $white;
				@include ff-NotoSerif;
			}
		}
		&_title {
			@include fs(42);
		}
		&_sub {
			margin-top: 25px;
			@include fs(24);
		}
	}
}
.kdFileHeadLine {
	position: relative;
	margin-bottom: 32px;
	padding-bottom: 18px;
	text-align: center;
	@include fs(36);
	font-weight: bold;
	&::after {
		content: "";
		position: absolute;
		display: inline-block;
		bottom: 0;
		left: 50%;
		width: 60px;
		height: 4px;
		background-color: $kdFileColor02;
		-webkit-transform: translateX(-50%);
		        transform: translateX(-50%);
	}
}
@media screen and (max-width: $breakpoint-sp) {
}
// @media screen and (min-width: $breakpoint-sp + 1) and (max-width: $breakpoint-pc + 20) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.pageID-kdFile {
		$this: &;
		$gutter-com: .5em;
		.categoryHeader {
			$this: '.'+categoryHeader;
			$gutter-com: 3%;

			min-height: 60vh;
			height: auto;
			&_logo {
				top: 15px;
				left: 10px;
				> span {
					max-width: none;
				}
				img {
					max-height: 30px;
				}
			}
			&_logo {
				width: auto;
			}

			&_headLine {
				margin-top: 0;
				padding: 16px #{$gutter-com};
			}

			&_headLineInner {
				max-width: none;
			}
			&_title {
				@include fs(22);
				line-height: 1.4;
			}
			&_sub {
				margin-top: 15px;
				@include fs(14);
			}
		}
	}
	.kdFileHeadLine {
		margin-bottom: 20px;
		padding-bottom: 14px;
		@include fs(26);
		&::after {
			width: 45px;
		}
	}
}

/*
	.kdFileConcept
------------------------------------------------------------------------ */
.kdFileConcept {
	$this: &;

	text-align: center;
	&_lead {
		display: block;
		margin-top: 25px;
		@include fs(25);
		font-weight: bold;
		line-height: 1.9;
		> span {
			margin: 0;
			padding: 0 10px;
			display: inline-block;
			border-bottom: 2px solid lighten($kdFileColor01, 40%);
			&:not(:first-child) {
				margin-top: 10px;
			}
			em {
				color: $kdFileColor01;
				font-style: normal;
			}
		}
	}
	&_txt {
		margin-top: 26px;
		@include fs(18);
		line-height: 1.8;
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.kdFileConcept {
		$this: &;

		text-align: center;
		&_lead {
			margin-top: 20px;
			@include fs(16);
			line-height: 2;
			> span {
				padding: 0 5px;
				display: inline;
			}
		}
		&_txt {
			margin-top: 20px;
			@include fs(14);
			line-height: 1.6;
		}
	}
}

/*
	.kdFileService
------------------------------------------------------------------------ */
.kdFileService {
	$this: &;
	$gutter-v: 40px;
	$gutter-h: 120px;
	// book image
		$book-imgUrl: './../../files/kd-file/bg_service03.png';
		$book-width: image-width($book-imgUrl);
		$book-height: image-height($book-imgUrl);
	&_txt {
		margin-top: 25px;
		@include fs(18);
		text-align: center;
	}
	&_book {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
				align-items: center;
		margin-top: 45px;
		height: #{$book-height};
		background-image: url($book-imgUrl);
		background-position: center;
		background-repeat: no-repeat;
		-webkit-background-size: cover;
				background-size: cover;
	}
	&_bookInner {
		position: relative;
		top: -15px;
		padding: 20px 100px;
		width: 100%;
	}
	&_bookList {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: stretch;
				align-items: stretch;
		-webkit-flex-wrap: wrap;
				flex-wrap: wrap;

		margin: -#{$gutter-v} 0 0 -#{$gutter-h};
		list-style-type: none;
		& > * {
			margin: #{$gutter-v} 0 0 #{$gutter-h};
			width: calc( ( 100% - #{$gutter-h} * 2 ) / 2);
			width: calc( ( 100% - 4px - #{$gutter-h} * 2 ) / 2)\9;
		}
		> li {
			// padding: 20px 0;
			text-align: center;
		}
	}
	&_bookTitle {
		position: relative;
		min-height: #{$icon-pencil-height};
		color: $kdFileColor02;
		@include fs(24);
		> span {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			&::before {
				content: "";
				display: inline-block;
				vertical-align: middle;
				position: relative;
				top: -10px;
				margin-right: 12px;
				@include sprite($icon_pencil);
			}
		}
	}
	&_bookTxt {
		margin-top: 20px;
		@include fs(14);
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.kdFileService {
		$this: &;
		$gutter-v: 35px;
		$gutter-h: 10px;
		// book image
			$book-imgUrl: './../../files/kd-file/bg_service03.png';
			$book-width: image-width($book-imgUrl);
			$book-height: image-height($book-imgUrl);

		&_txt {
			margin-top: 20px;
			@include fs(14);
		}
		&_book {
			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;

			margin-top: 30px;
			height: auto;
			background: none;
			border: 2px solid $kdFileColor02;
		}
		&_bookInner {
			top: auto;
			padding: 20px 10px;
		}
		&_bookList {
			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;

			margin:  0;
			& > * {
				margin: 0;
				width: 100%;
				width: 100%\9;
				&:not(:first-child) {
					margin: #{$gutter-v} 0 0;
				}
			}
			> li {
				text-align: left;
			}
		}
		&_bookTitle {
			position: relative;
			min-height: calc( #{$icon-pencil-height} / 2 );
			@include fs(15);
			> span {
				&::before {
					top: -3px;
					margin-right: 8px;
					@include sprite2x($icon_pencil);
				}
			}
		}
		&_bookTxt {
			margin-top: 10px;
		}
	}
}

/*
	.kdFileGuide
------------------------------------------------------------------------ */
.kdFileGuide {
	$this: &;
	$gutter-v: 48px;

	&:not(:first-child) {
		margin-top: 60px;
	}

	&_list {
		margin: 0;
		list-style-type: none;
		& > *:not(:first-child) {
			margin: #{$gutter-v} 0 0;
		}
	}
	&_item {
		border: 1px solid $kdFileColor02;
		    -webkit-box-shadow: 3px 3px 0 $kdFileColor03;
		            box-shadow: 3px 3px 0 $kdFileColor03;
		> a ,
		> div {
			position: relative;
			display: block;
			width: 100%;
			height: 100%;
			// display: block;
			padding: 30px 40px 40px;
			color: $baseColor;
			text-decoration: none;
		}
	}

	&_title {
		text-align: center;
		color: $kdFileColor02;
		@include fs(26);
		font-weight: bold;
	}
	&_txt {
		margin-top: 25px;
		@include fs(14);
		line-height: 1.75;
		em {
			color: $kdFileColor02;
			font-style: normal;
			@include fs(18);
			font-weight: bold;
		}
	}
	&_btns {
		margin-top: 52px;
		text-align: center;
		.pageBtn {
			min-width: 340px;
			width: auto;
		}
	}

	// layout module
	$gutter-grid-h: 98px;
	$gutter-grid-v: 48px;
	&-grid {
		#{$this}_list {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-wrap: wrap;
					flex-wrap: wrap;

			margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		}
		&-v {
			#{$this}_list {
				margin: 0;
				display: block;
				overflow: hidden;
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-10 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		&-colAuto {
			#{$this}_item {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: auto !important;
			}
		}
	}
}
// PC style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.kdFileGuide {
		$this: &;

		// layout module
		$gutter-grid-h: 98px;
		$gutter-grid-v: 48px;
		&-grid {
			&-v-pc {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-pc {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.kdFileGuide {
		$this: &;
		$gutter-com: .5em;
		$gutter-v: 20px;

		&:not(:first-child) {
			margin-top: 15px;
		}
		&_list {
			& > *:not(:first-child) {
				margin: #{$gutter-v} 0 0;
			}
		}
		&_item {
			> a ,
			> div {
				padding: 20px 10px;
			}
		}

		&_title {
			@include fs(19);
		}
		&_txt {
			margin-top: 20px;
		}
		&_btns {
			margin-top: 30px;
			.pageBtn {
				min-width: 0;
				width: 100%;
			}
		}

		// layout module
		$gutter-grid-h: 12px;
		$gutter-grid-v: 20px;
		&-grid {
			#{$this}_list {
				margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
			}
			&-v-sp {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-sp {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}

/*
	.kdFileHistory
------------------------------------------------------------------------ */
.kdFileHistory {
	$this: &;

	&:not(:first-child) {
		margin-top: 100px;
	}
	&_title {
		padding: 0 1em 20px;
		text-align: center;
		@include fs(36);
		font-weight: bold;
		border-bottom: 2px solid $kdFileColor02;
	}
	&_txt {
		margin-top: 28px;
		@include fs(18);
		line-height: 1.85;
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.kdFileHistory {
		$this: &;

		&:not(:first-child) {
			margin-top: 50px;
		}
		&_title {
			padding: 0 .5em 10px;
			@include fs(22);
		}
		&_txt {
			margin-top: 18px;
			@include fs(14);
		}
	}
}

/*
	.kdFileCustomer
------------------------------------------------------------------------ */
.kdFileCustomer {
	$this: &;

	&:not(:first-child) {
		margin-top: 60px;
	}

	&_headLine {
		position: relative;
		padding-top: 64px;
		text-align: center;
		color: $white;
		@include fs(36);
		font-weight: bold;
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 50%;
			-webkit-transform: translateX(-50%);
			        transform: translateX(-50%);
			@include sprite($icon_point03);
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.kdFileCustomer {
		$this: &;

		&:not(:first-child) {
			margin-top: 30px;
		}

		&_headLine {
			padding-top: 34px;
			@include fs(26);
			&::before {
				@include sprite15x($icon_point03);
			}
		}
	}
}

/*
	.kdFileCusVoice
------------------------------------------------------------------------ */
.kdFileCusVoice {
	$this: &;
	$gutter-v: 48px;

	&:not(:first-child) {
		margin-top: 30px;
	}

	&_list {
		margin: 0;
		list-style-type: none;
		& > *:not(:first-child) {
			margin: #{$gutter-v} 0 0;
		}
	}
	&_item {
		position: relative;
		border: 1px solid $kdFileColor02;
		-webkit-border-radius: 30px;
		        border-radius: 30px;
	    -webkit-box-shadow: 4px 4px 0 rgba($kdFileColor02, .6);
	            box-shadow: 4px 4px 0 rgba($kdFileColor02, .6);
		&::before ,
		&::after {
			content: "";
			display: block;
			position: absolute;
			bottom: -28px;
			left: 50%;
			width: 100px;
			height: 100px;
			-webkit-border-radius: 16px;
			        border-radius: 16px;
			-webkit-transform: translate( -50% ,0) rotate(45deg);
			        transform: translate( -50% ,0) rotate(45deg);
			z-index: 1;
		}
		&::before {
			bottom: -33px;
			margin-left: 2px;
			background-color: rgba($kdFileColor02, .6);
		}
		&::after {
			background-color: $white;
		}
		> a ,
		> div {
			position: relative;
			display: block;
			width: 100%;
			height: 100%;
			// display: block;
			padding: 35px 40px 50px;
			color: $baseColor;
			text-decoration: none;
			background-color: $white;
			-webkit-border-radius: 30px;
			        border-radius: 30px;
			z-index: 5;
		}
	}

	&_customer {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
				justify-content: center;
		-webkit-align-items: center;
				align-items: center;
		margin: 0;
		& > * {
			margin: 0;
			&:not(:first-child) {
				margin-left: 35px;
			}
		}
		.title {
			@include fs(18);
			font-weight: bold;
		}
	}

	&_title {
		text-align: center;
		padding: 0 10px 10px;
		color: $kdFileColor02;
		@include fs(24);
		border-bottom: 1px solid $baseColor;
		&:not(:first-child) {
			margin-top: 20px;
		}
	}
	&_txt {
		margin-top: 33px;
		@include fs(14);
		line-height: 1.75;
	}

	// layout module
	$gutter-grid-h: 98px;
	$gutter-grid-v: 48px;
	&-grid {
		#{$this}_list {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-wrap: wrap;
					flex-wrap: wrap;

			margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		}
		&-v {
			#{$this}_list {
				margin: 0;
				display: block;
				overflow: hidden;
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-10 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		&-colAuto {
			#{$this}_item {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: auto !important;
			}
		}
	}
}
// PC style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.kdFileCusVoice {
		$this: &;

		// layout module
		$gutter-grid-h: 98px;
		$gutter-grid-v: 48px;
		&-grid {
			&-v-pc {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-pc {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.kdFileCusVoice {
		$this: &;
		$gutter-com: .5em;
		$gutter-v: 48px;

		&:not(:first-child) {
			margin-top: 30px;
		}
		&_list {
			& > *:not(:first-child) {
				margin: #{$gutter-v} 0 0;
			}
		}
		&_item {
			-webkit-border-radius: 15px;
			        border-radius: 15px;
			&::before ,
			&::after {
				bottom: -14px;
				width: 50px;
				height: 50px;
				-webkit-border-radius: 8px;
				        border-radius: 8px;
			}
			&::before {
				bottom: -18px;
			}
			> a ,
			> div {
				padding: 20px 10px 30px;
				-webkit-border-radius: 15px;
				        border-radius: 15px;
			}
		}

		&_customer {
			& > * {
				&:not(:first-child) {
					margin-left: 20px;
				}
			}
			.title {
				@include fs(15);
			}
			.illust {
				max-width: 50px;
			}
		}

		&_title {
			padding: 0 5px 5px;
			@include fs(18);
			&:not(:first-child) {
				margin-top: 10px;
			}
		}
		&_txt {
			margin-top: 20px;
		}

		// layout module
		$gutter-grid-h: 15px;
		$gutter-grid-v: 38px;
		&-grid {
			#{$this}_list {
				margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
			}
			&-v-sp {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-sp {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}

/*
	.kdFileContact
------------------------------------------------------------------------ */
.kdFileContact {
	$this: &;
	$gutter-h: 60px;
	$gutter-v: 30px;
	$side-width: 53%;

	&_block {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
				align-items: center;
		& > * {
			margin: 0;
			&:not(:first-child) {
				margin-left: #{$gutter-h};
			}
		}
	}
	&_msg {
		width: #{$side-width};
	}
	&_txt {
		@include fs(18);
		font-weight: bold;
	}
	&_btns {
		width: calc( 100% - #{$side-width} );
		text-align: center;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.kdFileContact {
		$this: &;
		$gutter-h: 20px;
		$gutter-v: 20px;
		$side-width: 100%;

		&_block {
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;
			& > * {
				margin: 0;
				width: 100%;
				&:not(:first-child) {
					margin: #{$gutter-v} 0 0;
				}
			}
		}
		&_msg {
		}
		&_txt {
			@include fs(16);
		}
		&_btns {
			.pageBtn {
				width: 100%;
			}
		}
	}
}



/* ------------------------------------------------------------------------------------------------------ */
/* --- @建設工事情報＠Mail --------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------ */

/*
	.pageID-kdMail
------------------------------------------------------------------------ */
.pageID-kdMail {
	$this: &;
	.categoryHeader {
		$this: '.'+categoryHeader;
		$gutter-com: 5%;

		display: -webkit-flex;
		display: flex;
		// -webkit-align-items: center;
		//		align-items: center;

		min-height: 625px;
		margin-top: 5px;
		padding: 0 #{$gutter-gSide02};
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-background-size: cover;
				background-size: cover;
		&_inner {
			margin: 0 auto;
			padding: 106px 0 35px;
			width: 100%;
			max-width: #{$baseWidth};
		}
		&_headLine {
			& > * {
				display: block;
			}
		}
		&_sub {
			@include fs(18);
		}
		&_title {
			margin-top: 23px;
		}
	}
	.categorySummary {
		$this: '.'+categorySummary;

		display: inline-block;
		margin-top: 58px;
		padding: 32px 38px;
		min-width: 800px;
		background-color: $white;
		& > * {
			display: block;
		}
		&_title {
			color: $kdMailColor01;
			@include fs(28);
			font-weight: bold;
		}
		&_lead {
			color: $kdMailColor01;
			margin-top: 15px;
			@include fs(18);
			line-height: 1.8;
		}
		&_txt {
			margin-top: 30px;
			@include fs(14);
			font-weight: bold;
		}
		&_note {
			margin-top: 18px;
			@include fs(14);
		}
	}
}
// @media screen and (min-width: $breakpoint-sp + 1) and (max-width: $breakpoint-pc + 20) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.pageID-kdMail {
		$this: &;
		$gutter-com: 1em;
		.categoryHeader {
			$this: '.'+categoryHeader;
			$gutter-com: 3%;

			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;
			min-height: 0;
			margin-top: 0;
			padding: 0;

			&_inner {
				padding: 30px #{$gutter-com};
				max-width: none;
			}
			&_sub {
				@include fs(14);
			}
			&_title {
				margin-top: 10px;
			}
		}
		.categorySummary {
			$this: '.'+categorySummary;

			display: block;
			margin-top: 28px;
			padding: 22px 10px;
			min-width: 0;
			&_title {
				@include fs(20);
			}
			&_lead {
				@include fs(14);
				line-height: 1.6;
			}
			&_txt {
				margin-top: 25px;
				@include fs(12);
			}
			&_note {
				margin-top: 10px;
				@include fs(12);
			}
		}
	}
}

/*
	.kdMailPoint
------------------------------------------------------------------------ */
.kdMailPoint {
	$this: &;
	&_headline {
		position: relative;
		text-align: center;
		@include fs(28);
		font-weight: bold;
		> span {
			display: inline-block;
			padding: 0 .5em 15px;
			border-bottom: 4px solid $kdMailColor01;
			em {
				color: $kdMailColor01;
				@include fs(38);
				font-weight: bold;
				font-style: normal;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.kdMailPoint {
		$this: &;
		&_headline {
			@include fs(16);
			line-height: 1.4;
			> span {
				display: block;
				padding: 0 .5em 5px;
				em {
					@include fs(28);
				}
			}
		}
	}
}

/*
	.kdMailPointList
------------------------------------------------------------------------ */
.kdMailPointList {
	$this: &;
	$gutter-v: 40px;

	&:not(:first-child) {
		margin-top: 60px;
	}

	&_list {
		margin: 0;
		list-style-type: none;
		& > *:not(:first-child) {
			margin: #{$gutter-v} 0 0;
		}
	}
	&_item {
		border: 2px solid $baseColor;
		-webkit-border-radius: #{$kd-radius-com};
		        border-radius: #{$kd-radius-com};
		> a ,
		> div {
			display: -webkit-flex;
			display: flex;
			-webkit-justify-content: center;
					justify-content: center;
			-webkit-align-items: center;
					align-items: center;
			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;

			position: relative;
			width: 100%;
			height: 100%;
			// display: block;
			padding: 30px 8%;
			text-align: center;
			color: $baseColor;
			text-decoration: none;
			@include transitionType01;
			& > * {
				width: 100%;
			}
		}
	}

	&_title {
		color: $kdMailColor01;
		@include fs(18);
		font-weight: bold;
	}
	&_txt {
		margin-top: 10px;
		@include fs(14);
		line-height: 1.65;
	}
	&_btns {
		margin-top: 28px;
		text-align: center;
		.pageBtn {
			width: 100%;
		}
	}

	// layout module
	$gutter-grid-h: 24px;
	$gutter-grid-v: 24px;
	&-grid {
		#{$this}_list {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-wrap: wrap;
					flex-wrap: wrap;

			margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		}
		&-v {
			#{$this}_list {
				margin: 0;
				display: block;
				overflow: hidden;
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-10 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		&-colAuto {
			#{$this}_item {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: auto !important;
			}
		}
	}
}
// PC style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.kdMailPointList {
		$this: &;

		// layout module
		$gutter-grid-h: 24px;
		$gutter-grid-v: 24px;
		&-grid {
			&-v-pc {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-pc {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.kdMailPointList {
		$this: &;
		$gutter-com: .5em;
		$gutter-v: 20px;

		&:not(:first-child) {
			margin-top: 15px;
		}
		&_list {
			& > *:not(:first-child) {
				margin: #{$gutter-v} 0 0;
			}
		}

		&_item {
			-webkit-border-radius: #{$kd-radius-com-sp};
			        border-radius: #{$kd-radius-com-sp};
			> a ,
			> div {
				padding: 20px 10px;
			}
		}
		&_btns {
			margin-top: 20px;
		}

		// layout module
		$gutter-grid-h: 12px;
		$gutter-grid-v: 20px;
		&-grid {
			#{$this}_list {
				margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
			}
			&-v-sp {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-sp {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}

/*
	.kdMailProvision
------------------------------------------------------------------------ */
.kdMailProvision {
	$this: &;
	$gutter-h: 50px;

	&_block {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
				align-items: center;


		padding: 50px 40px;
		background-color: $white;
		-webkit-border-radius: #{$kd-radius-com};
		        border-radius: #{$kd-radius-com};
		& > * {
			margin: 0;
			&:not(:first-child) {
				margin-left: #{$gutter-h};
			}
		}
	}
	&_card {
		padding: 5px 0;
		width: 100%;
	}
	&_frame {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
				justify-content: center;
		-webkit-align-items: center;
				align-items: center;
		-webkit-flex-flow: column wrap;
		        flex-flow: column wrap;
		        padding: 50px 36px;
		border: 1px solid $gray;
		& > * {
			width: 100%;
			text-align: center;
		}
	}
	&_title {
		@include fs(23);
		font-weight: bold;
	}
	&_lead {
		margin-top: 15px;
		@include fs(18);
	}
	&_hero {
		-webkit-flex: 0 0 auto;
		    -ms-flex: 0 0 auto;
		        flex: 0 0 auto;
		text-align: center;
		padding-right: 40px;
	}
	&_img {
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.kdMailProvision {
		$this: &;
		$gutter-h: 25px;

		&_block {
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;

			padding: 10px;
			-webkit-border-radius: #{$kd-radius-com-sp};
			        border-radius: #{$kd-radius-com-sp};
			& > * {
				margin: 0;
				&:not(:first-child) {
					margin-left: #{$gutter-h};
				}
			}
		}
		&_frame {
			padding: 15px;
		}
		&_title {
			@include fs(16);
		}
		&_lead {
			@include fs(14);
		}
		&_hero {
			padding-right: 0;
		}
	}
}

/*
	.kdMailRegister
------------------------------------------------------------------------ */
.kdMailRegister {
	$this: &;
	$gutter-h: 50px;

	padding: 65px 35px 10px;
	border: 4px solid $kdMailColor01;
	-webkit-border-radius: #{$kd-radius-com};
	        border-radius: #{$kd-radius-com};
	&_card {
	}
	&_title {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
				justify-content: center;
		-webkit-align-items: flex-end;
				align-items: flex-end;

		position: relative;
		margin: 0;
		padding: 0 75px;
		text-align: center;
		color: $baseColor;
		@include fs(45);
		font-weight: bold;
		&::before ,
		&::after {
			content: "";
			display: inline-block;
			position: absolute;
			top: 12px;
			width: 6px;
			height: 95px;
			background-color: $baseColor;
		}
		&::before {
			left: 36px;
			-webkit-transform: rotate(-45deg);
					transform: rotate(-45deg);
		}
		&::after {
			right: 36px;
			-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
		}
		> span {
			position: relative;
			display: inline-block;
			&::before {
				content: "";
				display: block;
				position: absolute;
				top: -30px;
				right: -70px;
				@include sprite($icon_point01);
			}
		}
	}
	&_hero {
		margin-top: 58px;
		text-align: center;
	}
	&_img {
	}
}
// @media screen and (min-width: $breakpoint-sp + 1) and (max-width: $breakpoint-pc + 20) {
//	.kdMailRegister {
//		$this: &;
//		&_title {
//			@include fs(35);
//		}
//	}
// }
@media screen and (max-width: $breakpoint-sp) {
	.kdMailRegister {
		$this: &;
		$gutter-h: 50px;

		padding: 20px 10px 10px;
		-webkit-border-radius: #{$kd-radius-com-sp};
		        border-radius: #{$kd-radius-com-sp};
		&_card {
		}
		&_title {
			padding: 0 1em;
			@include fs(16);
			&::before ,
			&::after {
				top: 12px;
				width: 3px;
				height: 45px;
			}
			&::before {
				left: 5px;
				-webkit-transform: rotate(-15deg);
						transform: rotate(-15deg);
			}
			&::after {
				right: 5px;
				-webkit-transform: rotate(15deg);
						transform: rotate(15deg);
			}
			> span {
				&::before {
					top: -16px;
					right: -12px;
					@include sprite15x($icon_point01);
				}
			}
		}
		&_hero {
			margin-top: 20px;
		}
		&_img {
		}
	}
}

/*
	.kdMailCustomer
------------------------------------------------------------------------ */
.kdMailCustomer {
	$this: &;

	&_block {
		padding: 30px 30px 60px;
		text-align: center;
		background-color: $white;
		// border: 2px solid black;
		-webkit-border-radius: #{$kd-radius-com};
		        border-radius: #{$kd-radius-com};
	}
	&_headLine {
		position: relative;
		padding-top: 50px;
		color: $kdMailColor01;
		@include fs(24);
		font-weight: bold;
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 50%;
			-webkit-transform: translateX(-50%);
			        transform: translateX(-50%);
			@include sprite($icon_point02);
		}
	}
	&_lead {
		display: block;
		margin-top: 20px;
		@include fs(36);
		font-weight: bold;
	}
	&_btns {
		margin-top: 25px;
		text-align: center;
		.pageBtn {
			width: 360px;
		}
	}
	&_txt {
		margin-top: 30px;
		@include fs(14);
		line-height: 1.65;
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.kdMailCustomer {
		$this: &;

		&_block {
			padding: 15px 10px;
			-webkit-border-radius: #{$kd-radius-com-sp};
			        border-radius: #{$kd-radius-com-sp};
		}
		&_headLine {
			@include fs(18);
		}
		&_lead {
			@include fs(20);
		}
		&_btns {
			margin-top: 20px;
			.pageBtn {
				width: 100%;
			}
		}
		&_txt {
			margin-top: 20px;
		}
	}
}

/*
	.kdMailCustomer
------------------------------------------------------------------------ */
.kdMailPrice {
	$this: &;

	&_block {
	}
	&_frame {
		margin: 0 auto;
		max-width: 90%;
		padding: 55px 30px;
		text-align: center;
		border: 2px solid $baseColor;
		-webkit-border-radius: #{$kd-radius-com};
		        border-radius: #{$kd-radius-com};
		& > * {
			display: block;
		}
	}
	&_sub {
		@include fs(18);
	}
	&_lead {
		margin-top: 15px;
		color: $kdMailColor01;
		@include fs(36);
		font-weight: bold;
	}
	&_txt {
		margin-top: 6px;
		@include fs(24);
		font-weight: bold;
	}
	&_btns {
		margin-top: 78px;
		text-align: center;
		.pageBtn {
			width: 900px;
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.kdMailPrice {
		$this: &;

		&_block {
		}
		&_frame {
			max-width: 100%;
			padding: 15px 10px;
			-webkit-border-radius: #{$kd-radius-com-sp};
			        border-radius: #{$kd-radius-com-sp};
		}
		&_sub {
			@include fs(12);
		}
		&_lead {
			margin-top: 15px;
			@include fs(20);
		}
		&_txt {
			@include fs(16);
		}
		&_btns {
			margin-top: 25px;
			.pageBtn {
				width: 100%;
			}
		}
	}
}



/* ------------------------------------------------------------------------------------------------------ */
/* --- @日本工業経済新聞社 --------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------ */

/*
	.pageID-kdNews
------------------------------------------------------------------------ */
.pageID-kdNews {
	$this: &;
	.categoryHeader {
		$this: '.'+categoryHeader;
		$gutter-com: 5%;

		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
				align-items: center;

		height: 625px;
		padding: 0 #{$gutter-gSide02};
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-background-size: cover;
				background-size: cover;
		&_inner {
			margin: 0 auto;
			width: 100%;
			max-width: #{$baseWidth};
		}
		&_headLine {
			& > * {
				display: block;
			}
		}
		&_title {
			@include fs(48);
			font-weight: bold;
		}
		&_lead {
			display: block;
			margin-top: 25px;
			color: $kdNewsColor01;
			@include fs(36);
			font-weight: bold;
		}
		&_txt {
			display: block;
			margin-top: 20px;
			@include fs(24);
		}
	}
	.commonTable {
		$this: '.'+commonTable;
		&-pricePlan {
			width: 100%;
			table-layout: fixed;
			th ,
			td {
				padding: 8px 14px;
				vertical-align: middle;
				border-color: $baseColor;
			}
			thead {
				th {
					color: $baseColor;
					background-color: $kdNewsColor03;
				}
			}
			tbody {
				th {
					text-align: left;
					background-color: $white;
				}
				td {
					text-align: center;
				}
			}
		}
	}
}
// @media screen and (min-width: $breakpoint-sp + 1) and (max-width: $breakpoint-pc + 20) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.pageID-kdNews {
		$this: &;
		$gutter-com: 1em;
		.categoryHeader {
			$this: '.'+categoryHeader;
			$gutter-com: 3%;

			height: auto;
			min-height: 50vh;
			padding: 0 #{$gutter-com};
			width: 100%;
			&_inner {
				max-width: none;
			}
			&_title {
				@include fs(28);
			}
			&_lead {
				margin-top: 20px;
				@include fs(18);
			}
			&_txt {
				margin-top: 12px;
				@include fs(14);
			}
		}
		.commonTable {
			$this: '.'+commonTable;
			&-pricePlan {
				min-width: 450px;
				th ,
				td {
					padding: 8px;
					white-space: normal !important;
				}
				.name {
					width: 120px;
					min-width: 120px;
				}
			}
		}
	}
}

/*
	.kdNewsGuide
------------------------------------------------------------------------ */
.kdNewsGuide {
	$this: &;
	$gutter-h: 50px;
	$gutter-v: 135px;
	$max-width: 710px;

	&:not(:first-child) {
		margin: #{$gutter-v} 0 0;
	}
	&_title {
		position: relative;
		padding-bottom: 18px;
		@include fs(24);
		font-weight: bold;
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			min-width: #{$max-width};
			height: 4px;
			background: $kdNewsColor02;
		}
	}
	&_contents {
		margin-top: 48px;
	}
	&_contentsInner {
		@include cf;
	}

	&_left {
		float: left;
		margin: 0 #{$gutter-h} 0 0;
	}
	&_right {
		float: right;
		margin: 0 0 0 #{$gutter-h};
	}
	&_figure {
		display: inline-block;
		margin: 0;
		text-align: center;
	}
	&_img {
		margin: 0;
		&:not(:first-child) {
			margin-top: 10px;
		}
	}

	&_body {
		overflow: hidden;
		&-around {
			overflow: visible;
		}
	}
	&_txt {
		margin: 0;
		@include fs(18);
		line-height: 1.85;
		&:not(:first-child) {
			margin-top: 20px;
		}
		&-maxWide {
			max-width: #{$max-width};
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.kdNewsGuide {
		$this: &;
		$gutter-h: 20px;
		$gutter-v: 55px;
		$max-width: 710px;

		&:not(:first-child) {
			margin: #{$gutter-v} 0 0;
		}
		&_title {
			padding-bottom: 10px;
			@include fs(18);
			&::after {
				min-width: 0;
				width: 100%;
			}
		}
		&_contents {
			margin-top: 28px;
		}
		&_contentsInner {
			@include cf;
		}

		&_left ,
		&_right {
			text-align: center;
			float: none;
			margin: 0 0 #{$gutter-h} 0;
		}

		&_txt {
			@include fs(14);
			&:not(:first-child) {
				margin-top: 15px;
			}
			&-maxWide {
				max-width: none;
			}
		}
	}
}

/*
	.kdNewsXxxx
------------------------------------------------------------------------ */
.kdNewsSubscribe {
	$this: &;
	$gutter-h: 40px;
	$gutter-v: 30px;

	text-align: center;
	&_title {
		color: $kdNewsColor01;
		@include fs(25);
		font-weight: bold;
	}
	&_txt {
		margin-top: 30px;
		@include fs(18);
	}
	&_contents {
		margin-top: 35px;
	}
	&_btns {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
				justify-content: center;
		-webkit-align-items: center;
				align-items: center;
		-webkit-flex-wrap: wrap;
				flex-wrap: wrap;

		margin: -#{$gutter-v} 0 0 -#{$gutter-h};
		list-style-type: none;
		& > * {
			margin: #{$gutter-v} 0 0 #{$gutter-h};
		}
	}
	&_btn {
		.pageBtn {
			min-width: 420px;
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.kdNewsSubscribe {
		$this: &;
		$gutter-h: 10px;
		$gutter-v: 15px;

		&_title {
			@include fs(20);
		}
		&_txt {
			margin-top: 20px;
			@include fs(14);
		}
		&_contents {
			margin-top: 25px;
		}
		&_btns {
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;

			margin: 0;
			& > * {
				margin: 0;
				&:not(:first-child) {
					margin: #{$gutter-v} 0 0;
				}
			}
		}
		&_btn {
			.pageBtn {
				min-width: 0;
				width: 100%;
			}
		}
	}
}

/*
	.kdNewsContact
------------------------------------------------------------------------ */
.kdNewsContact {
	$this: &;
	&_btns {
		text-align: center;
		.pageBtn {
			min-width: 540px;
			width: auto;
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.kdNewsContact {
		$this: &;
		&_btns {
			.pageBtn {
				min-width: 0;
				width: 100%;
			}
		}
	}
}



/* ------------------------------------------------------------------------------------------------------ */
/* --- @入札ネット＋α --------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------ */

/*
	.pageID-kdNet
------------------------------------------------------------------------ */
.pageID-kdNet {
	$this: &;

	.categoryHeader {
		$this: '.'+categoryHeader;
		$gutter-com: 5%;

		display: -webkit-flex;
		display: flex;
		// -webkit-align-items: center;
		//		align-items: center;

		height: 625px;
		padding: 0 #{$gutter-gSide02};
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-background-size: cover;
				background-size: cover;
		&_inner {
			position: relative;
			margin: 0 auto;
			width: 100%;
			max-width: #{$baseWidth};
		}

		&_headLine {
			position: absolute;
			top: 53%;
			left: 50%;
			width: 100%;
			-webkit-transform: translate(-50%, -50%);
			        transform: translate(-50%, -50%);
		}

		&_title {
			text-align: center;
		}
		&_lead {
			display: block;
			margin-top: 55px;
			text-align: center;
			> span {
				padding: 14px 3em;
				line-height: 1;
				@include fs(24);
				font-weight: bold;
				background-color: $kdNetColor01;
				-webkit-border-radius: 60px;
				        border-radius: 60px;
			}
		}

		&_logo {
			position: absolute;
			top: 11%;
			left: 0;
			margin: 0;
		}
		&_emblem {
			position: absolute;
			top: 7%;
			right: 0;
			margin: 0;
		}
	}
}
.kdNetHeadLine {
	position: relative;
	margin-bottom: 40px;
	text-align: center;
	@include fs(42);
	font-weight: bold;
	> span {
		display: inline-block;
		padding: 0 18px 18px;
		border-bottom: 5px solid $kdNetColor02;
	}
}
@media screen and (max-width: $breakpoint-sp) {
}
// @media screen and (min-width: $breakpoint-sp + 1) and (max-width: $breakpoint-pc + 20) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.pageID-kdNet {
		$this: &;
		$gutter-com: 1em;
		.categoryHeader {
			$this: '.'+categoryHeader;
			$gutter-com: 3%;

			height: auto;
			min-height: 50vh;
			padding: 0 #{$gutter-com};
			width: 100%;
			&_inner {
				max-width: none;
			}

			&_headLine {
				top: 50%;
			}

			&_title {
			}
			&_lead {
				margin-top: 20px;
				> span {
					padding: 8px 1em;
					line-height: 1;
					@include fs(14);
					-webkit-border-radius: 30px;
					        border-radius: 30px;
				}
			}

			&_logo {
				top: 4%;
				img {
					max-width: 140px;
				}
			}
			&_emblem {
				top: 3%;
				img {
					max-width: 60px;
				}
			}
		}
	}
	.kdNetHeadLine {
		margin-bottom: 30px;
		@include fs(28);
		> span {
			padding: 0 10px 10px;
		}
	}
}

/*
	.kdNetRecommend
------------------------------------------------------------------------ */
.kdNetRecommend {
	$this: &;
	$size-chackIcon: 50px;

	&_title {
		position: relative;
		display: block;
		margin: 0 auto;
		padding: 20px 1em;
		width: 800px;
		text-align: center;
		color: $white;
		@include fs(36);
		font-weight: bold;
		background: $kdNetColor02;
		-webkit-border-radius: 20px;
		        border-radius: 20px;
		z-index: 5;
		&::after {
			content: "";
			display: block;
			position: absolute;
			bottom: -34px;
			left: 50%;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 34px 50px 0 50px;
			border-color: $kdNetColor02 transparent transparent transparent;
			-webkit-transform: translateX(-50%);
			        transform: translateX(-50%);

		}
	}
	&_contents {
		position: relative;
		top: -55px;
		padding: 115px 50px 80px;
		border: 6px solid $kdNetColor02;
		-webkit-border-radius: 20px;
		        border-radius: 20px;
	}
	&_checkList {
		margin: 0 auto;
		max-width: 900px;
		list-style-type: none;
		> li {
			position: relative;
			margin: 0;
			padding-left: calc( #{$size-chackIcon} + 40px );
			@include fs(32);
			font-weight: bold;
			&:not(:first-child) {
				margin-top: 1em;
			}
			&::before ,
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				left: 6px;
				-webkit-transform: translateY(-50%);
				        transform: translateY(-50%);
			}
			&::before {
				width: #{$size-chackIcon};
				height: #{$size-chackIcon};
				background-color: $white;
				border: 2px solid $black;
			}
			&::after {
				left: calc( #{$size-chackIcon} / 2 + 6px );
				margin-top: -17px;
				width: 32px;
				height: 24px;
				border-left: 8px solid $red;
				border-bottom: 8px solid $red;
				-webkit-transform: translate(-50% , 0%) rotate(-45deg);
						transform: translate(-50% , 0%) rotate(-45deg);
			}
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.kdNetRecommend {
		$this: &;
		$size-chackIcon: 20px;

		&_title {
			padding: 10px .5em;
			width: 90%;
			@include fs(20);
			-webkit-border-radius: 10px;
			        border-radius: 10px;
			&::after {
				bottom: -14px;
				border-width: 14px 22px 0 22px;

			}
		}
		&_contents {
			top: -25px;
			padding: 55px 10px 30px;
			border-width: 3px;
			-webkit-border-radius: 10px;
			        border-radius: 10px;
		}
		&_checkList {
			max-width: none;
			> li {
				padding-left: calc( #{$size-chackIcon} + 10px );
				@include fs(16);
				&::before ,
				&::after {
					left: 2px;
				}
				&::before {
					width: #{$size-chackIcon};
					height: #{$size-chackIcon};
				}
				&::after {
					left: calc( #{$size-chackIcon} / 2 + 3px );
					margin-top: -8px;
					width: 16px;
					height: 10px;
					border-left-width: 4px;
					border-bottom-width: 4px;
				}
			}
		}
	}
}

/*
	.kdNetGuide
------------------------------------------------------------------------ */
.kdNetGuide {
	$this: &;
	&_headLine {
		padding: 40px 1em;
		text-align: center;
		background: $kdNetColor06;
		// background: linear-gradient(180deg, rgba(255,233,85,1) 0%, rgba(255,80,1,1) 100%);
		> span {
			display: block;
			margin: 0 auto;
			max-width: #{$baseWidth};
			color: $white;
			@include fs(36);
			font-weight: bold;
		}
	}
	&_inner {
		margin: 0 auto;
		padding: 65px 0 50px;
		max-width: #{$baseWidth};
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.kdNetGuide {
		$this: &;
		$gutter-com: 1em;
		&_headLine {
			padding: 25px .5em;
			> span {
				@include fs(18);
			}
		}
		&_inner {
			padding: 30px #{$gutter-com};
			max-width: none;
		}
	}
}

/*
	.kdNetGuideList
------------------------------------------------------------------------ */
.kdNetGuideList {
	$this: &;
	$gutter-v: 30px;

	&:not(:first-child) {
		margin-top: 30px;
	}

	&_list {
		margin: 0;
		list-style-type: none;
		& > *:not(:first-child) {
			margin: #{$gutter-v} 0 0;
		}
	}
	&_item {
		background-color: $white;
		border: 3px solid $gray-light;
		-webkit-border-radius: 15px;
		        border-radius: 15px;
		> a ,
		> div {
			position: relative;
			display: block;
			width: 100%;
			height: 100%;
			// display: block;
			padding: 46px 30px 30px;
			color: $baseColor;
			text-decoration: none;
		}
	}

	&_title {
		text-align: center;
		color: $kdNetColor02;
		@include fs(30);
		font-weight: bold;
	}
	&_txt {
		margin-top: 30px;
		@include fs(16);
		line-height: 1.8;
	}

	// layout module
	$gutter-grid-h: 30px;
	$gutter-grid-v: 30px;
	&-grid {
		#{$this}_list {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-wrap: wrap;
					flex-wrap: wrap;

			margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		}
		&-v {
			#{$this}_list {
				margin: 0;
				display: block;
				overflow: hidden;
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-10 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		&-colAuto {
			#{$this}_item {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: auto !important;
			}
		}
	}
}
// PC style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.kdNetGuideList {
		$this: &;

		// layout module
		$gutter-grid-h: 30px;
		$gutter-grid-v: 30px;
		&-grid {
			&-v-pc {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-pc {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.kdNetGuideList {
		$this: &;
		$gutter-com: .5em;
		$gutter-v: 20px;

		&:not(:first-child) {
			margin-top: 15px;
		}
		&_list {
			& > *:not(:first-child) {
				margin: #{$gutter-v} 0 0;
			}
		}
		&_item {
			-webkit-border-radius: 10px;
			        border-radius: 10px;
			> a ,
			> div {
				padding: 20px 10px;
			}
		}

		&_title {
			@include fs(20);
		}
		&_txt {
			margin-top: 20px;
			@include fs(14);
		}

		// layout module
		$gutter-grid-h: 10px;
		$gutter-grid-v: 20px;
		&-grid {
			#{$this}_list {
				margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
			}
			&-v-sp {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-sp {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}

/*
	.kdNetIntroList
------------------------------------------------------------------------ */
.kdNetIntroList {
	$this: &;
	$gutter-v: 18px;

	&:not(:first-child) {
		margin-top: 30px;
	}

	&_list {
		margin: 0;
		list-style-type: none;
		& > *:not(:first-child) {
			margin: #{$gutter-v} 0 0;
		}
	}
	&_item {
		min-width: 285px;
		min-height: 285px;
		> a ,
		> div {
			display: -webkit-flex;
			display: flex;
			-webkit-justify-content: center;
					justify-content: center;
			-webkit-align-items: center;
					align-items: center;
			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;

			position: relative;
			// display: block;
			width: 100%;
			height: 100%;
			padding: 30px;
			text-align: center;
			color: $baseColor;
			text-decoration: none;
			background-color: $white;
			-webkit-border-radius: 100%;
			        border-radius: 100%;
			-webkit-box-shadow: 4px 4px 8px rgba(0,0,0,.2);
			        box-shadow: 4px 4px 8px rgba(0,0,0,.2);
			& > * {
				width: 100%;
			}
		}
	}

	&_title {
		@include fs(18);
		font-weight: bold;
	}
	&_icon {
		margin-top: 14px;
	}
	&_txt {
		margin-top: 10px;
		@include fs(15);
	}

	// layout module
	$gutter-grid-h: 18px;
	$gutter-grid-v: 18px;
	&-grid {
		#{$this}_list {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-wrap: wrap;
					flex-wrap: wrap;

			margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		}
		&-v {
			#{$this}_list {
				margin: 0;
				display: block;
				overflow: hidden;
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-10 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		&-colAuto {
			#{$this}_item {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: auto !important;
			}
		}
	}
}
// PC style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.kdNetIntroList {
		$this: &;

		// layout module
		$gutter-grid-h: 18px;
		$gutter-grid-v: 18px;
		&-grid {
			&-v-pc {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-pc {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.kdNetIntroList {
		$this: &;
		$gutter-com: .5em;
		$gutter-v: 20px;

		&:not(:first-child) {
			margin-top: 15px;
		}
		&_list {
			& > *:not(:first-child) {
				margin: #{$gutter-v} 0 0;
			}
		}
		&_item {
			margin: 0 auto;
			> a ,
			> div {
				margin: auto;
				padding: 30px;
				max-width: 285px;
				max-height: 285px;
			}
		}

		&_icon {
			margin-top: 10px;
		}
		&_txt {
			margin-top: 5px;
			@include fs(14);
		}

		// layout module
		$gutter-grid-h: 10px;
		$gutter-grid-v: 20px;
		&-grid {
			#{$this}_list {
				margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
			}
			&-v-sp {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-sp {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}

/*
	.kdNetPrice
------------------------------------------------------------------------ */
.kdNetPrice {
	$this: &;
	&_txt {
		@include fs(16);
	}

	.tableScroll {
		margin-top: 54px;
	}
	&_table {
		width: 100%;
		// table-layout: fixed;
		th ,
		td {
			padding: 17px 14px;
			@include fs(16);
			text-align: left;
			border-color: $gray-light;
		}
		thead {
			th {
				text-align: center;
				vertical-align: middle;
			}
		}
		tbody {
			th {
			}
			td {
				text-align: center;
				vertical-align: middle;
			}
		}
		.caption01 {
			text-align: center;
			color: $white;
			background-color: $kdNetColor06;
		}
		.caption02 {
			color: $gray-dark;
			background-color: $gray-bg;
		}

		.important {
			font-weight: bold;
			> em {
				color: $kdNetColor02;
				@include fs(24);
				font-style: normal;
			}
		}

		.empty {
			background-color: transparent;
			border: none;
		}

	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.kdNetPrice {
		$this: &;

		.tableScroll {
			margin-top: 20px;
		}
		&_table {
			th ,
			td {
				padding: 10px 8px;
				@include fs(14);
			}
			.important {
				> em {
					@include fs(20);
				}
			}
		}
	}
}

/*
	.kdNetTrial
------------------------------------------------------------------------ */
.kdNetTrial {
	$this: &;
	&_btns {
		text-align: center;
		.pageBtn {
			max-width: 900px;
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
	.kdNetContact
------------------------------------------------------------------------ */
.kdNetContact {
	$this: &;
	&_btns {
		text-align: center;
		.pageBtn {
			max-width: 1100px;
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
}



/* ------------------------------------------------------------------------------------------------------ */
/* --- @コンテンツ制作/広報PR支援 --------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------ */

/*
	.pageID-contentMktg
------------------------------------------------------------------------ */
.pageID-contentMktg {
	$this: &;
	.categoryHeader {
		$this: '.'+categoryHeader;
		$gutter-com: 5%;

		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
				align-items: center;

		height: 625px;
		margin-top: 5px;
		padding: 0 #{$gutter-gSide02};
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-background-size: cover;
				background-size: cover;
		&_inner {
			margin: 0 auto;
			padding: 35px 0;
			width: 100%;
			max-width: #{$baseWidth};
		}
		&_headLine {
			max-width: 670px;
			& > * {
				display: block;
			}
		}
		&_title ,
		&_sub {
			text-shadow: 1px 1px 1px rgba($white, .9)
						, -1px 1px 1px rgba($white, .9)
						, 1px -1px 1px rgba($white, .9)
						, -1px -1px 1px rgba($white, .9)
						, 3px 3px 3px rgba($white, .5)
						, -3px 3px 3px rgba($white, .5)
						, 3px -3px 3px rgba($white, .5)
						, -3px -3px 3px rgba($white, .5);
		}
		&_title {
			@include fs(36);
			font-weight: bold;
		}
		&_sub {
			margin-top: 35px;
			@include fs(18);
			line-height: 2;
		}
	}
}
// @media screen and (min-width: $breakpoint-sp + 1) and (max-width: $breakpoint-pc + 20) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.pageID-contentMktg {
		$this: &;
		$gutter-com: .5em;
		.categoryHeader {
			$this: '.'+categoryHeader;
			$gutter-com: 3%;

			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;
			height: auto;
			margin-top: 0;

			&_inner {
				padding: 30px #{$gutter-com};
				max-width: none;
			}
			&_headLine {
				max-width: none;
			}
			&_title ,
			&_sub {
				text-shadow: 1px 1px 1px rgba($white, .9)
							, -1px 1px 1px rgba($white, .9)
							, 1px -1px 1px rgba($white, .9)
							, -1px -1px 1px rgba($white, .9)
							, 3px 3px 3px rgba($white, .9)
							, -3px 3px 3px rgba($white, .9)
							, 3px -3px 3px rgba($white, .9)
							, -3px -3px 3px rgba($white, .9);
			}
			&_title {
				@include fs(20);
			}
			&_sub {
				margin-top: 20px;
				@include fs(14);
				line-height: 1.6;
			}
		}
	}
}

/*
	.contentSummary
------------------------------------------------------------------------ */
.contentSummary {
	$this: &;
	$gutter-com: 1em;

	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
			align-items: center;

	min-height: 600px;
	padding: 90px #{$gutter-gSide02} 236px;
	width: 100%;
	background-position: bottom center;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
			background-size: cover;
	&_inner {
		margin: 0 auto;
		width: 100%;
		text-align: center;
		max-width: #{$baseWidth};
	}
	&_title {
		position: relative;
		padding: 0 2em 30px;
		@include fs(24);
		font-weight: bold;
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 85%;
			height: 4px;
			background-color: $mainColor01;
			-webkit-transform: translateX(-50%);
			        transform: translateX(-50%);
		}
	}
	&_txt {
		margin-top: 46px;
		@include fs(18);
		line-height: 1.85;
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.contentSummary {
		$this: &;
		$gutter-com: .5em;

		min-height: 0;
		padding: 30px #{$gutter-com} 70px;
		width: 100%;
		-webkit-background-size: 150% auto;
				background-size: 150% auto;
		&_inner {
			max-width: none;
		}
		&_title {
			padding: 0 .5em 10px;
			@include fs(18);
			&::after {
				width: 100%;
			}
		}
		&_txt {
			margin-top: 26px;
			@include fs(14);
		}
	}
}

/*
	.contentIntro
------------------------------------------------------------------------ */
.contentIntro {
	$this: &;
	$gutter-com: 1em;
	$gutter-v: 78px;
	$side-gutter-h: 50px;
	$side-width: 40%;

	// main image size
	$main-maxRatioWidth: 480;
	$main-maxRatioHeight: 320;
	$main-imgAspectRatio: calc( #{$main-maxRatioHeight} / #{$main-maxRatioWidth} * 100% ); // アスペクト比

	$main-maxWidth: 480px;
	$main-maxHeight: 320px;
	$main-maxWidthRatio: floor((( $main-maxWidth / $baseWidth ) * 10000% )) / 100;

	&_block {
		margin: 0;
		&:not(:first-child) {
			margin-top: #{$gutter-v};
		}
		&:nth-child(odd) {
			#{$this}_hero {
				margin-right: #{$side-gutter-h};
			}
		}
		&:nth-child(even) {
			#{$this}_hero {
				margin-left: #{$side-gutter-h};
				-webkit-order: 2;
				    -ms-order: 2;
				        order: 2;
			}
			#{$this}_card {
				-webkit-order: 1;
				    -ms-order: 1;
				        order: 1;
			}
		}
	}
	&_inside {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: stretch;
				align-items: stretch;

		margin: 0 auto;
		max-width: #{$baseWidth};
		& > * {
		}
	}

	// _hero area
	&_hero {
		width: #{$side-width};
		min-width: #{$side-width};
	}
	&_figure {
		position: relative;
		display: block;
		margin: 0 auto;
		padding-top: #{$main-imgAspectRatio};
		width: 100%;
		// overflow: hidden;
		text-align: center;
		// border: 1px solid $gray-border;
		-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
	}
	&_img {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		max-width: 100%;
		max-height: 100%;
		width: 100%; // 「 cover 」時設定追加
		height: 100%; // 「 cover 」時設定追加
		object-fit: cover;
		font-family: 'object-fit: cover;';
		-webkit-transition: .3s;
				transition: .3s;
	}

	&_card {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
				align-items: center;
		width: 100%;
	}
	&_cardInner {
	}
	&_title {
		position: relative;
		padding: 14px 0 0 140px;
		min-height: 60px;
		color: $mainColor02;
		@include fs(24);
		font-weight: bold;
		&::before {
			content: "";
			position: absolute;
			top: -20px;
			left: 20px;
			width: 62px;
			height: 60px;
			background: $mainColor05;
			transform: skewX(-35deg);
		}
		span {
			position: absolute;
			top: 0;
			left: 50px;
			@include fs(48);
			@include ff-Montserrat;
			z-index: 2;
		}
		strong {
		}
	}
	&_txt ,
	&_lead {
		@include fs(18);
		line-height: 1.8;
	}
	&_txt {
		margin-top: 25px;
	}
	&_lead {
		display: block;
		margin-top: 25px;
		color: $mainColor01;
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.contentIntro {
		$this: &;
		$gutter-com: 1em;
		$gutter-v: 48px;
		$side-gutter-v: 15px;
		$side-width: 100%;

		// main image size
		$main-maxRatioWidth: 480;
		$main-maxRatioHeight: 320;
		$main-imgAspectRatio: calc( #{$main-maxRatioHeight} / #{$main-maxRatioWidth} * 100% ); // アスペクト比

		$main-maxWidth: 480px;
		$main-maxHeight: 320px;
		$main-maxWidthRatio: floor((( $main-maxWidth / $baseWidth ) * 10000% )) / 100;

		&_block {
			margin: 0;
			&:not(:first-child) {
				margin-top: #{$gutter-v};
			}
			&:nth-child(odd) {
				#{$this}_hero {
				}
			}
			&:nth-child(even) {
				#{$this}_hero {
					-webkit-order: 1;
					    -ms-order: 1;
					        order: 1;
				}
				#{$this}_card {
					-webkit-order: 2;
					    -ms-order: 2;
					        order: 2;
				}
			}
		}
		&_inside {
			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;

			max-width: none;
		}

		// _hero area
		&_hero {
			margin: 0 !important;
			width: #{$side-width};
			min-width: #{$side-width};
			padding: 0 1em;
		}

		&_card {
			margin-top: #{$side-gutter-v};
			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;
		}
		&_title {
			padding: 10px 0 0 70px;
			min-height: 50px;
			@include fs(18);
			&::before {
				top: -4px;
				left: 8px;
				width: 32px;
				height: 50px;
				transform: skewX(-35deg);
			}
			span {
				top: 5px;
				left: 20px;
				@include fs(28);
			}
			strong {
			}
		}
		&_txt ,
		&_lead {
			@include fs(15);
		}
		&_txt {
			margin-top: 20px;
		}
		&_lead {
			margin-top: 20px;
		}
	}
}

/*
	.contentIntro
------------------------------------------------------------------------ */
.contentContact {
	$this: &;
	&_btns {
		text-align: center;
		.pageBtn {
			width: 1000px;
		}
	}
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
	.contentContact {
		$this: &;
		&_btns {
			.pageBtn {
				width: 100%;
			}
		}
	}
}










/* ------------------------------------------------------------------------------------------------------ */
/* --- aaaaaaaaaaaaaaaaaaaa --------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------ */

/*
	.aaaaaaaaaaaaaaaaaaaa
------------------------------------------------------------------------ */
.aaaaaaaaaaaaaaaaaaaa {
	$this: &;
}
@media screen and (max-width: $breakpoint-pc) {
}
@media screen and (max-width: $breakpoint-sp) {
}

// @media screen and (min-width: $breakpoint-sp + 1) {
//	body {
//		background: url('/files/kd-net/net.jpg') top 0 center no-repeat;
//		// -webkit-background-size: contain;
//		//      -o-background-size: contain;
//		//         background-size: contain;
//		* {
//			background: none !important;
//			background-color: transparent !important;
//			// background-color: rgba(0,0,0, .1) !important;
//		}
//		img {
//			opacity: .5;
//		}
//	}
// }

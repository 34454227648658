@charset "UTF-8";
@import '../_config.scss';

/*
便利クラス

マージン強制とかの便利クラス

Styleguide 1.
*/

/*
	汎用
------------------------------------------------------------------------ */
/* margin , padding */
@for $i from 0 through 100 {
	.m#{$i} {
		margin: #{$i}px !important;
	}
	.mt#{$i} {
		margin-top: #{$i}px !important;
	}
	.mr#{$i} {
		margin-right: #{$i}px !important;
	}
	.mb#{$i} {
		margin-bottom: #{$i}px !important;
	}
	.ml#{$i} {
		margin-left: #{$i}px !important;
	}
	.mt#{$i}e {
		margin-top: #{$i * .1}em !important;
	}
	.mr#{$i}e {
		margin-right: #{$i * .1}em !important;
	}
	.mb#{$i}e {
		margin-bottom: #{$i * .1}em !important;
	}
	.ml#{$i}e {
		margin-left: #{$i * .1}em !important;
	}
	.mlr#{$i} {
		margin-left: #{$i}px !important;
		margin-right: #{$i}px !important;
	}
	.p#{$i} {
		padding: #{$i}px !important;
	}
	.pt#{$i} {
		padding-top: #{$i}px !important;
	}
	.pr#{$i} {
		padding-right: #{$i}px !important;
	}
	.pb#{$i} {
		padding-bottom: #{$i}px !important;
	}
	.pl#{$i} {
		padding-left: #{$i}px !important;
	}
	.pt#{$i}e {
		padding-top: #{$i * .1}em !important;
	}
	.pr#{$i}e {
		padding-right: #{$i * .1}em !important;
	}
	.pb#{$i}e {
		padding-bottom: #{$i * .1}em !important;
	}
	.pl#{$i}e {
		padding-left: #{$i * .1}em !important;
	}
	@media screen and (max-width: $breakpoint-sp) {
		.mt#{$i}-sp {
			margin-top: #{$i}px !important;
		}
		.mr#{$i}-sp {
			margin-right: #{$i}px !important;
		}
		.mb#{$i}-sp {
			margin-bottom: #{$i}px !important;
		}
		.ml#{$i}-sp {
			margin-left: #{$i}px !important;
		}
		.mt#{$i}e-sp {
			margin-top: #{$i * .1}em !important;
		}
		.mr#{$i}e-sp {
			margin-right: #{$i * .1}em !important;
		}
		.mb#{$i}e-sp {
			margin-bottom: #{$i * .1}em !important;
		}
		.ml#{$i}e-sp {
			margin-left: #{$i * .1}em !important;
		}

		.pt#{$i}-sp {
			padding-top: #{$i}px !important;
		}
		.pr#{$i}-sp {
			padding-right: #{$i}px !important;
		}
		.pb#{$i}-sp {
			padding-bottom: #{$i}px !important;
		}
		.pl#{$i}-sp {
			padding-left: #{$i}px !important;
		}
		.pt#{$i}e-sp {
			padding-top: #{$i * .1}em !important;
		}
		.pr#{$i}e-sp {
			padding-right: #{$i * .1}em !important;
		}
		.pb#{$i}e-sp {
			padding-bottom: #{$i * .1}em !important;
		}
		.pl#{$i}e-sp {
			padding-left: #{$i * .1}em !important;
		}

	}
	@media screen and (min-width: $breakpoint-sp +1){
		.mt#{$i}-pc {
			margin-top: #{$i}px !important;
		}
		.mr#{$i}-pc {
			margin-right: #{$i}px !important;
		}
		.mb#{$i}-pc {
			margin-bottom: #{$i}px !important;
		}
		.ml#{$i}-pc {
			margin-left: #{$i}px !important;
		}
		.mt#{$i}e-pc {
			margin-top: #{$i * .1}em !important;
		}
		.mr#{$i}e-pc {
			margin-right: #{$i * .1}em !important;
		}
		.mb#{$i}e-pc {
			margin-bottom: #{$i * .1}em !important;
		}
		.ml#{$i}e-pc {
			margin-left: #{$i * .1}em !important;
		}

		.pt#{$i}-pc {
			padding-top: #{$i}px !important;
		}
		.pr#{$i}-pc {
			padding-right: #{$i}px !important;
		}
		.pb#{$i}-pc {
			padding-bottom: #{$i}px !important;
		}
		.pl#{$i}-pc {
			padding-left: #{$i}px !important;
		}
		.pt#{$i}e-pc {
			padding-top: #{$i * .1}em !important;
		}
		.pr#{$i}e-pc {
			padding-right: #{$i * .1}em !important;
		}
		.pb#{$i}e-pc {
			padding-bottom: #{$i * .1}em !important;
		}
		.pl#{$i}e-pc {
			padding-left: #{$i * .1}em !important;
		}

	}
}
.ma {
	margin: auto !important;
}
.mta {
	margin-top: auto !important;
}
.mra {
	margin-right: auto !important;
}
.mba {
	margin-bottom: auto !important;
}
.mla {
	margin-left: auto !important;
}
.mlra {
	margin-left: auto !important;
	margin-right: auto !important;
}
@media screen and (min-width: $breakpoint-sp+1) {
	.mta-pc {
		margin-top: auto !important;
	}
	.mra-pc {
		margin-right: auto !important;
	}
	.mba-pc {
		margin-bottom: auto !important;
	}
	.mla-pc {
		margin-left: auto !important;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.mta-sp {
		margin-top: auto !important;
	}
	.mra-sp {
		margin-right: auto !important;
	}
	.mba-sp {
		margin-bottom: auto !important;
	}
	.mla-sp {
		margin-left: auto !important;
	}
}

/* width */
@for $i from 1 through 100 {
	.w#{$i}p {
		width: #{$i}% !important;
	}
	.w#{$i}e {
		width: #{$i*.1}em !important;
	}
	@media screen and (min-width: $breakpoint-sp +1 ){
		.pcW#{$i}p {
			width: #{$i}% !important;
		}
		.pcW#{$i}e {
			width: #{$i*.1}em !important;
		}
	}
	@media screen and (max-width: $breakpoint-sp) {
		.spW#{$i}p {
			width: #{$i}% !important;
		}
		.spW#{$i}e {
			width: #{$i*.1}em !important;
		}
	}
}
.wAuto {
	width: auto !important;
}
.minWid0 {
	min-width: 0 !important;
}
.maxWidNone {
	max-width: none !important;
}

/* height */
.hAuto {
	height: auto !important;
}
.minHgt0 {
	min-height: 0 !important;
}
.maxHgtNone {
	max-height: none !important;
}

/* input , select , textarea */
@for $i from 1 through 100 {
	input[type=text].input,
	input[type=password].input,
	select.input,
	textarea.input {
		&-w#{$i}p {
			width: #{$i}% !important;
		}

		@media screen and (min-width: $breakpoint-sp +1 ), print {
			&-pcW#{$i}p {
				width: #{$i}% !important;
			}
		}
		@media screen and (max-width: $breakpoint-sp) {
			&-spW#{$i}p {
				width: #{$i}% !important;
			}
		}
	}
}

/* position */
.relative {
	position: relative;
}

/* display */
.none {
	display: none !important;
}
.inline {
	display: inline !important;
}
.inlineBlock {
	display: inline-block !important;
}
.block {
	display: block !important;
}

/* indent */
@for $i from 1 through 50 {
	.indent#{$i}e {
		text-indent: #{$i*.1}em !important;
	}
}
.indent0e {
	text-indent: 0em !important;
}

/* line-height */
@for $i from 1 through 30 {
	.lineHight#{$i} {
		line-height: #{$i*.1} !important;
	}
}

/* font */
@for $i from 1 through 50 {
	.fs#{$i} {
		font-size:#{$i}px !important;
	}
	@media screen and (min-width: $breakpoint-sp +1 ), print {
		.fs#{$i}-pc {
			font-size:#{$i}px !important;
		}
	}
	@media screen and (max-width: $breakpoint-sp) {
		.fs#{$i}-sp {
			font-size:#{$i}px !important;
		}
	}
}

/* font style */
.bld { font-weight: bold !important; }
.bld5 { font-weight: 500 !important; }
.bld7 { font-weight: 700 !important; }
.italic { font-style: italic !important; }

/* font family */
.ff-YuGothic { @include ff-YuGothic; }

/* float */
.fLeft { float: left !important; }
.fRight { float: right !important; }

/* table */
.tlFixed { table-layout: fixed !important; }
.tlAuto { table-layout: auto !important; }

/* text-align */
.tLeft  { text-align: left !important; }
.tCenter { text-align: center !important; }
.tRight { text-align: right !important; }
.mCenter { margin: 0 auto !important;}

/* vertical-align */
.vat { vertical-align: top !important; }
.vam { vertical-align: middle !important; }
.vab { vertical-align: bottom !important; }

/* hrLine */
.hrLine {
	border: none;
	border-top-style: solid;
	border-top-width: 1px;
	border-top-color: $gray-border;
	&-color01 {
		border-top-color: $red;
	}
	&-dotted01 {
		border-top-style: dotted;
	}
}

/* nowrap */
.nowrap {
	white-space: nowrap;
}

/* overflow */
.ofhidden {
	overflow: hidden !important;
}

/* textHidden */
.txtHide {
	@include textHidden;
}

/* textEndLeader */
.txtEndLeader {
	@include textEndLeader;
}

/* link */
.deadlink {
	pointer-events: none;
	cursor: default;
}

/* color */
.red {
	color: $red !important;
}
.blue {
	color: $blue !important;
}
.gray {
	color: $gray !important;
}

/* clearfix */
.clearfix {
	&::before,
	&::after {
		content: ".";
		display: block;
		height: 0;
		visibility: hidden;
		clear: both;
		height: 0;
		line-height: 0;
	}
	&::after {
		content: "";
		clear: both;
	}
}
.clearfix {
	*zoom: 1;
}
.noAround {
	overflow: hidden;
	*zoom: 1;
}
.clear, .clb {
	clear: both;
}

/* js common > telephone call ( SP only ) */
.tap-phoneCode {
	color: $baseColor;
	text-decoration: none;
	> a {
		display: inline-block;
		color: $baseColor;
		text-decoration: none;
	}
}

/* js > powertip */
[data-powertip] {
	cursor: help;
}


/*
	hidden

レスポンシブ用　表示/非表示

.(sp/tab/pc)-onlyで.(sp/tab/pc)でのみ表示

.(sp/tab/pc)-hiddenで.(sp/tab/pc)でのみ非表示

つまり.sp-onlyでtab/pcは非表示に、.pc-onlyでtab/spは非表示に。

Markup:

Styleguide 1.1
------------------------------------------------------------------------ */
.hidden {
	display: none !important;
}
// SPのみ
@media screen and (max-width: $breakpoint-sp) {
	.sp-hidden,
	.pc-only,
	.tab-only {
		display: none !important;
	}

	.sp-fLeft { float: left !important; }
	.sp-fRight { float: right !important; }
	.sp-tLeft  { text-align: left !important; }
	.sp-tCenter { text-align: center !important; }
	.sp-tRight { text-align: right !important; }
	.sp-mCenter { margin: 0 auto !important;}
	.sp-vat { vertical-align: top !important; }
	.sp-vam { vertical-align: middle !important; }
	.sp-vab { vertical-align: bottom !important; }

	/* display */
	.sp-inline { display: inline !important; }
	.sp-inlineBlock { display: inline-block !important; }
	.sp-block { display: block !important; }
}
// Tabletのみ
@media screen and (min-width: $breakpoint-sp + 1) and (max-width: $breakpoint-tablet) {
	.tab-hidden,
	.pc-only,
	.sp-only {
		display: none !important;
	}

	.tab-fLeft { float: left !important; }
	.tab-fRight { float: right !important; }
	.tab-tLeft  { text-align: left !important; }
	.tab-tCenter { text-align: center !important; }
	.tab-tRight { text-align: right !important; }
	.tab-mCenter { margin: 0 auto !important;}
	.tab-vat { vertical-align: top !important; }
	.tab-vam { vertical-align: middle !important; }
	.tab-vab { vertical-align: bottom !important; }

	/* display */
	.tab-inline { display: inline !important; }
	.tab-inlineBlock { display: inline-block !important; }
	.tab-block { display: block !important; }
}
// PCのみ
@media screen and (min-width: $breakpoint-sp + 1) {
	.pc-hidden,
	.tab-only,
	.sp-only {
		display: none !important;
	}

	.pc-fLeft { float: left !important; }
	.pc-fRight { float: right !important; }
	.pc-tLeft  { text-align: left !important; }
	.pc-tCenter { text-align: center !important; }
	.pc-tRight { text-align: right !important; }
	.pc-mCenter { margin: 0 auto !important;}
	.pc-vat { vertical-align: top !important; }
	.pc-vam { vertical-align: middle !important; }
	.pc-vab { vertical-align: bottom !important; }

	/* display */
	.pc-inline { display: inline !important; }
	.pc-inlineBlock { display: inline-block !important; }
	.pc-block { display: block !important; }
}
@media screen {
	.print-only {
		display: none !important;
	}
}

.enqueteDialog {
	$this: &;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3000;
	display: none;
	transition: .4s opacity;
	cursor: default;

	&_overlay {
		display: block;
		background: $overlayBg;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&_inner {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		padding: 20px;
		border: 1px solid $gray-border;
		box-shadow: 0 0 5px rgba(#000, .3);
		min-width: 400px;
		max-width: 80%;
		max-height: 80%;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		transition: top .4s;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_txt {
		& > :first-child {
			margin-top: 0;
		}
	}
	&_btns {
		display: flex;
		justify-content: center;
		margin: 20px 10px 0;
	}
	&_btnItem {
		margin: 0 10px;
		min-width: 200px;
		display: block;
		text-align: center;
	}
	&_btn {
		display: block;
		text-align: center;
		text-decoration: none;
		color: inherit;
		border: 1px solid $gray-border;
		padding: 5px 10px;
	}

	/*
	----------------------------------------------------------------------*/
	&-enter-active {
		display: block;
	}
	&-enter {
		opacity: 0;
		&:hover {
			opacity: 0;
		}
		#{$this}_inner {
			top: 55%;
		}
	}
	&-enter-to {
		opacity: 1;
		&:hover {
			opacity: 1;
		}
		#{$this}_inner {
			top: 50%;
		}
	}

	&-leave-active {
		display: block;
	}
	&-leave {
		opacity: 1;
		&:hover {
			opacity: 1;
		}
		#{$this}_inner {
			top: 50%;
		}
	}
	&-leave-to {
		opacity: 0;
		&:hover {
			opacity: 0;
		}
		#{$this}_inner {
			top: 40%;
		}
	}
}
@keyframes enqueteDialog-isOpen {
	0% {
		opacity: 0;
		z-index: 1000;
	}
	100% {
		opacity: 1;
		z-index: 1000;
	}
}
@keyframes enqueteDialog-isClose {
	0% {
		opacity: 1;
		z-index: 1000;
	}
	99% {
		z-index: 1000;
	}
	100% {
		opacity: 0;
		z-index: -9999;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.enqueteDialog {
		$this: &;
		&_overlay {
		}
		&_inner {
			max-width: 100%;
			min-width: 80%;
		}
		&_txt {
		}
		&_btns {
			display: block;
			margin: 20px 0 0;
			& > :first-child {
				margin-top: 0;
			}
		}
		&_btn {
			margin: 15px 0 0;
			width: auto;
			&-cancel {
			}
			&-ok {
			}
		}
	}

}

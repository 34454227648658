@charset "UTF-8";
@import '_config.scss';

/*
パーツ

見出しとかボタンとかアイコン付きの文字とか

Styleguide 3.
*/

/*
見出し1

headLine1

Markup:
<h2 class="headLine1"><span>見出し1</span></h2><!-- /.headLine1 -->

Styleguide 3.1.1
============================================================ */
.headLine1 {
	$this: &;
	position: relative;
	top: -5px;
	margin: 55px auto;
	padding-bottom: 16px;
	@include fs(36);
	font-weight: bold;
	line-height: 1.3;
	&::after {
		content: "";
		display: inline-block;
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 6%;
		height: 4px;
		background-color: $baseColor;
	}
	&:first-child {
		margin-top: 0;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.headLine1 {
		$this: &;
		top: 0;
		margin: 24px auto;
		padding-bottom: 12px;
		@include fs(22);
		&::after {
			width: 14%;
		}
	}
}

/*
見出し2

headLine2

Markup:
<h2 class="headLine2">見出し2</h2><!-- /.headLine2 -->

Styleguide 3.1.2
============================================================ */
.headLine2 {
	$this: &;
	position: relative;
	margin: 30px auto;
	padding-left: 36px;
	@include fs(18);
	font-weight: bold;
	line-height: 1.45;
	&::after {
		content: "";
		display: inline-block;
		position: absolute;
		top: 9px;
		left: 0;
		width: 20px;
		height: 10px;
		background-color: $baseColor;
	}
	&:first-child {
		margin-top: 0;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.headLine2 {
		$this: &;
		margin: 25px auto;
		padding-left: 25px;
		@include fs(17);
		&::after {
			top: 8px;
			width: 16px;
			height: 5px;
		}
	}
}

/*
見出し3

headLine3

Markup:
<h2 class="headLine3">見出し3</h2><!-- /.headLine3 -->

Styleguide 3.1.3
============================================================ */
.headLine3 {
	$this: &;
	position: relative;
	margin: 30px auto;
	padding: 0 18px 6px;
	@include fs(18);
	font-weight: bold;
	line-height: 1.45;
	border-bottom: 2px solid $baseColor;
	&:first-child {
		margin-top: 0;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.headLine3 {
		$this: &;
		margin: 25px auto;
		padding: 0 12px 6px;
		@include fs(17);
	}
}

/*
見出し4

headLine4

Markup:
<h2 class="headLine4">見出し4</h2><!-- /.headLine4 -->

Styleguide 3.1.2
============================================================ */
.headLine4 {
	$this: &;
	position: relative;
	margin: 30px auto;
	padding: 0 30px 5px;
	@include fs(18);
	font-weight: bold;
	line-height: 1.45;
	border-bottom: 1px solid $gray-light;
	&::after {
		content: "";
		display: inline-block;
		position: absolute;
		top: 7px;
		left: 0;
		width: 14px;
		height: 14px;
		background-color: $gray-light;
	}
	&:first-child {
		margin-top: 0;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.headLine4 {
		$this: &;
		margin: 25px auto;
		padding: 0 18px 5px;
		@include fs(16);
		&::after {
			top: 7px;
			width: 10px;
			height: 10px;
		}
	}
}

/*
ボタンリスト

btnList

Markup:
<div class="btnList {$modifiers}">
	<div class="btnList_grid">
		<div class="btnList_col btnList_col-4-12pc btnList_col-6-12sp">
			<a href="<%= siteRoot %>catalog/" class="btn btn-color01"><span>cs</span></a>
		</div>
		<div class="btnList_col btnList_col-4-12pc btnList_col-6-12sp">
			<a href="<%= siteRoot %>catalog/" class="btn btn-color01"><span>cs</span></a>
		</div>
	</div>
</div><!-- /.btnList -->

.btnList-left - left
.btnList-center - center
.btnList-right - right
.btnList-sideArray - sideArray

Styleguide 3.2
============================================================ */
.btnList {
	$this: &;
	$gutter-grid-h: 20px;
	$gutter-grid-v: 20px;

	margin-top: 20px;
	&_grid {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: stretch;
				align-items: stretch;
		-webkit-flex-wrap: wrap;
				flex-wrap: wrap;

		margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		&-v {
			margin: 0;
			display: block;
			overflow: hidden;
		}
	}
	&_col {
		position: relative;
		overflow: hidden;
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-10 {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
			}
		}
	}

	&-left {
		#{$this}_grid {
			-webkit-justify-content: flex-start;
					justify-content: flex-start;
		}
	}
	&-center {
		#{$this}_grid {
			-webkit-justify-content: center;
					justify-content: center;
		}
		#{$this}_col {
			text-align: center;
		}
	}
	&-right {
		#{$this}_grid {
			-webkit-justify-content: flex-end;
					justify-content: flex-end;
		}
		#{$this}_col {
			text-align: right;
		}
	}
	&-sideArray {
		#{$this}_grid {
			-webkit-justify-content: space-between;
					justify-content: space-between;
		}
	}

	// page module
	&-siteTop {
		margin-top: 7em;
	}
}
// PC style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.btnList {
		$this: &;
		$gutter-grid-h: 20px;
		$gutter-grid-v: 20px;

		&_col {
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10pc {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.btnList {
		$this: &;
		$gutter-grid-h: 10px;
		$gutter-grid-v: 10px;

		&_grid {
			margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		}
		&_col {
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10sp {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
				}
			}
		}

		// page module
		&-siteTop {
			margin-top: 3em;
		}
	}
}

/*
ボタン

btn

Markup:
<a href="#" class="btn {$modifiers}"><span>ボタン</span></a>
<button type="button" class="btn {$modifiers}"><span>ボタン</span></button>

. --------  width type - ***
.btn-min - min
.btn-mid - mid
.btn-wide - wide
.btn-max - max
.btn-auto - auto
. --------  height type - ***
. --------  color type - ***
.btn-color01 - color01
. --------  border type - ***
.btn-border01 - border01
. --------  round type - ***
.btn-round01 - round01
. --------  shadow type - ***
.btn-shadow01 - shadow01
. --------  arrow btn - ***
.btn-arrowLeft - arrowLeft
.btn-arrowLeft02 - arrowLeft02
.btn-arrowRight - arrowRight
.btn-arrowRight02 - arrowRight02
. --------  OK , Cancel type - ***
.btn-success - success
.btn-cancel - cancel
. --------  ページバック系ボタン - ***
.btn-topPage - topPage
.btn-mypageTop - mypageTop
. --------  form画面 btn - ***
.btn-zipcode - zipcode
.btn-confirmBtn - confirmBtn
.btn-submitBtn - submitBtn
.btn-backPrev - backPrev
. --------  画像アイコン btn - ***
.btn-search - search
. --------  個別 btn - ***
.disabledBtn - disabledBtn

Styleguide 3.3.1
============================================================ */
.btn {
	$this: &;
	$gutter-icon: .6em;
	@extend %btnStyle;

	// -------- width type
	&-min {
		width: 150px;
	}
	&-mid {
		width: 280px;
	}
	&-wide {
		width: 480px;
	}
	&-max {
		width: 100%;
	}
	&-auto {
		width: auto;
	}

	// -------- height type
	&-thin {
		@extend %btnStyle-thin;
		> span {
			&::before {
				right: 10px !important;
				width: 8px !important;
				height: 8px !important;
			}
		}
	}
	&-thick {
		@extend %btnStyle-thick;
	}

	// -------- color type
	// &-color99 ,
	&-color01 {
		color: $white;
	}
	&-color01 {
		background-color: $mainColor01;
		> span {
			&::before {
				border-top-color: $white;
				border-right-color: $white;
			}
		}
	}
	// &-color99 {
	//	background-color: $mainColor02;
	// }
	&-blue {
		background: $blue;
	}
	&-gray {
		background: $gray;
	}

	// -------- border type
	// &-border99 ,
	&-border01 {
		color: inherit;
		background-color: transparent;
		border: 1px solid;
		&::before {
			color: inherit;
		}
	}
	&-border01 {
		color: $mainColor01;
		border-color: $mainColor01;
		> span {
			&::before {
				border-top-color: $mainColor01;
				border-right-color: $mainColor01;
			}
		}
	}
	// &-border99 {
	//	color: $mainColor02;
	//	border-color: $mainColor02;
	// }

	// -------- round type

	// -------- shadow type

	// -------- arrow btn
	// front arrow アイコン 共通
	&-arrowLeft02 ,
	&-arrowLeft ,
	&-arrowRight02 ,
	&-arrowRight {
		> span {
			position: static;
			&::before {
				// @include fa-old($angle-right);
				content: "";
				display: inline-block;
				position: absolute;
				top: 50%;
				right: 18px;
				width: 12px;
				height: 12px;
				border-top: 2px solid;
				border-right: 2px solid;
				-webkit-transform: translate(0, -50%) rotate(45deg);
				        transform: translate(0, -50%) rotate(45deg);
			}
		}
	}
	&-arrowLeft02 ,
	&-arrowRight02 {
		> span {
			&::before {
				-webkit-transform: translate(0, -50%) rotate(-135deg);
				        transform: translate(0, -50%) rotate(-135deg);
			}
		}
	}

	// front arrow アイコン
	&-arrowLeft02 ,
	&-arrowLeft {
		> span {
			padding-left: 10px;
			&::before {
				left: 18px;
			}
		}
	}

	// back arrow アイコン
	&-arrowRight02 ,
	&-arrowRight {
		> span {
			padding-right: 10px;
			&::before {
				right: 10px;
			}
		}
	}

	// -------- personal button type ( 専用ボタン )
	// OK , Cancel btn
	&-yes ,
	&-no ,
	&-success ,
	&-cancel {
		min-width: 120px;
	}
	&-yes ,
	&-success {
		color: $white;
		background-color: lighten($red, 15%);
	}
	&-no ,
	&-cancel {
		color: $white;
		background-color: lighten(#333, 25%);
	}

	// トップページへ
	&-topPage {
		color: $white;
		background-color: $mainColor01;
		border: 1px solid $mainColor01;
		> span {
			position: static;
			width: 100%;
			&::before {
				// @include fa-old($angle-right);
				content: "";
				display: inline-block;
				position: absolute;
				top: 50%;
				right: 10px;
				width: 12px;
				height: 12px;
				border-top: 2px solid $white;
				border-right: 2px solid $white;
				-webkit-transform: translate(0, -50%) rotate(45deg);
				        transform: translate(0, -50%) rotate(45deg);
			}
		}
	}

	// マイページへ
	&-mypageTop {
		color: $white;
		background-color: $mainColor02;
	}

	// zipcode btn
	&-zipcode {
		margin: 0;
		padding: 0 !important;
		width: 60px;
		height: 28px !important;
		min-height: 28px !important;
		line-height: 28px !important;
		@include fs(12);
		-webkit-border-radius: 2px;
		        border-radius: 2px;
		&:hover {
			opacity: 1;
		}
		&:focus {
			background-color: darken($gray, 30%);
		}
	}

	// -------- form画面 btn
	&-confirmBtn ,
	&-submitBtn ,
	&-backPrev {
		padding-top: 22px;
		padding-bottom: 22px;
		width: 480px;
		@include fs(18);
		> span {
			position: static;
			width: 100%;
			&::before {
				// @include fa-old($angle-right);
				content: "";
				display: inline-block;
				position: absolute;
				top: 50%;
				right: 10px;
				width: 12px;
				height: 12px;
				border-top: 2px solid $white;
				border-right: 2px solid $white;
				-webkit-transform: translate(0, -50%) rotate(45deg);
				        transform: translate(0, -50%) rotate(45deg);
			}
		}
	}

	// 共通スタイル
	&-confirmBtn ,
	&-submitBtn {
		color: $white;
		background-color: $mainColor01;
	}

	// 確認ボタン
	&-confirmBtn {
	}

	// 送信ボタン
	&-submitBtn {
	}

	// 戻るボタン
	&-backPrev {
		color: $white;
		background-color: $gray;
		border-color: $gray-light;
		> span {
			&::before {
				right: auto;
				left: 10px;
				-webkit-transform: translate(0, -50%) rotate(-135deg);
				        transform: translate(0, -50%) rotate(-135deg);
			}
		}
	}

	// -------- 個別 btn
	&-search {
		// icon image
			$iconSearch-imgUrl: './../images/_sprite/icon_search.png';
			$iconSearch-width: image-width($iconSearch-imgUrl);
			$iconSearch-height: image-height($iconSearch-imgUrl);
		> span {
			padding-left: calc( #{$iconSearch-width} + 5px );
			&::before {
				@include sprite($icon_search);
			}
		}
	}
}
div.btn ,
p.btn ,
span.btn {
	cursor: default;
}
// -------- 無効ボタン btn
.disabledBtn {
	pointer-events: none;
	cursor: default;
	color: $gray;
	opacity: .4 !important;
	background-color: $gray-light !important;
	border-color: $gray-light !important;
	-webkit-box-shadow: none;
	        box-shadow: none;
	pointer-events: none;
	&:hover {
		opacity: .4 !important;
	}
}
// for IE style
@media all and (-ms-high-contrast: none) {
}
// @media screen and (max-width: $breakpoint-pc) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.btn {
		$this: &;
		// -------- width type
		&-mid {
			width: 90%;
		}
		&-wide {
			width: 100%;
		}

		// -------- personal button type ( 専用ボタン )
		// トップページへ , マイページへ
		&-topPage ,
		&-mypageTop {
		}

		// -------- form画面 btn
		&-confirmBtn ,
		&-submitBtn ,
		&-backPrev {
			padding-top: 14px;
			padding-bottom: 14px;
			width: 100%;
			@include fs(14);
			> span {
				&::before {
					width: 8px;
					height: 8px;
				}
			}
		}
	}
}

/*
moreBtn

moreBtn

Markup:
<a href="xxx" class="moreBtn {$modifiers}"><span><i>一覧を見る</i></span></a>
<button type="button" class="moreBtn {$modifiers}"><span><i>一覧を見る</i></span></button>

Styleguide 3.3.2
============================================================ */
.moreBtn {
	$this: &;
	$gutter-h: 10px;

	position: relative;
	display: inline-block;
	margin: auto;
	margin-right: #{$gutter-h};
	min-width: 280px;
	text-align: center;
	color: $black;
	@include fs(16);
	font-weight: bold;
	background-color: $white;
	border: 1px solid $black;
	text-decoration: none;
	outline: none transparent;
	cursor: pointer;
	-webkit-transition: all .3s cubic-bezier(.25,.74,.54,.93), width 0s;
	        transition: all .3s cubic-bezier(.25,.74,.54,.93), width 0s;
	z-index: 2;
	&::after {
		content: "";
		display: block;
		@include absolute(50%, 0, auto, auto);
		right: -#{$gutter-h};
		width: 14%;
		height: 2px;
		background-color: $black;
		transition: .2s cubic-bezier(0.55, 0.09, 0.68, 0.53);
		-webkit-transform: translateY(-50%);
		        transform: translateY(-50%);
		z-index: 3;
	}
	> span {
		position: relative;
		display: block;
		overflow: hidden;
		padding: 16px 1em;
	}
	i {
		position: relative;
		z-index: 3;
		font-style: normal;
	}
}
// hover style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.moreBtn {
		$this: &;
		$gutter-h: 10px;
		> span {
			&::before {
				content: '';
				@include absolute(0,0,0,0);
				background-color: $black;
				transform: translateX(-100%);
				transition: .2s cubic-bezier(0.55, 0.09, 0.68, 0.53);
			}
		}

		&:hover {
			opacity: 1;
			color: $white;
			&::after {
				width: 10%;
				background-color: #fff;
				-webkit-transition-delay: .1s;
				        transition-delay: .1s;
			}
			> span {
				position: relative;
				&:before {
					transform: translateX(0);
				}
			}
			i {
				color: $white;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.moreBtn {
		$this: &;
		$gutter-h: 15px;

		margin: 0 auto;
		min-width: 0;
		width: 85%;
		@include fs(14);
		&::after {
			right: -#{$gutter-h};
		}
		> span {
			padding: 10px 1em;
		}
	}
}

/*
タグ

tag

Markup:
<span class="tag {$modifiers}">タグ</span>

.tag-mini - mini
.tag-auto - auto
.tag-color01 - color01
.tag-color02 - color02
.tag-new - new

Styleguide 3.4
============================================================ */
.tag {
	$this: &;
	$radius-com: 24px;

	position: relative;
	display: inline-block;
	// padding: 8px 10px 6px;
	padding: 2px 6px;
	text-align: center;
	line-height: 1.2;
	color: $white;
	@include fs(13);
	word-break: break-word;
	font-family: Arial , sans-serif;
	letter-spacing: .05em;
	background-color: $gray;
	border: 1px solid transparent;
	// -webkit-border-radius: #{$radius-com};
	//         border-radius: #{$radius-com};
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	text-decoration: none;
	z-index: 1;
	& > * {
		font-style: normal;
	}
	> i {
		font-style: normal;
	}

	// mini size
	&-mini {
		padding-top: 4px;
		padding-bottom: 3px;
		@include fs(12);
	}

	// -------- width type
	// &-min {
	// }
	// &-mid {
	//	min-width: 120px;
	// }
	&-auto {
		min-width: 0;
		min-height: 0;
	}

	// -------- fontSize type
	&-fontS {
	}
	&-fontL {
	}

	// -------- color type
	&-gray ,
	&-gray02 ,
	&-color01 ,
	&-color02 {
		color: $white;
	}
	&-gray {
		background-color: $gray-dark;
		border-color: $gray-dark;
	}
	&-gray02 {
		color: $gray;
		background-color: $gray-exLight;
		border-color: $gray-exLight;
	}
	&-color01 {
		background-color: $mainColor01;
		border-color: $mainColor01;
	}
	&-color02 {
		background-color: $mainColor02;
		border-color: $mainColor02;
	}

	// -------- border type
	&-br-n {
		border-radius: 0px;
	}

	// -------- personal type ( 特定タグ )
	&-new {
		min-width: 0 !important;
		color: $white;
		border: 1px solid $red;
		background-color: $red;
	}

	&-ad {
		// color: $mainColor01;
		// border-color: $mainColor01;
		// background-color: $white;
	}

	// layout module > position
	&-left {
	}
	&-right {
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.tag {
		@include fs(10);
	}
}

/*
注釈文( 行頭 : ※印 )

note , noteList

Markup:
<p class="note {$modifiers}">注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文</p>
<ul class="noteList {$modifiers}">
	<li>注釈文注釈文注釈文注釈文注釈文</li>
	<li>注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文</li>
</ul><!-- /.noteList -->

.note-gray - gray
.note-red - 赤
.note-blue - 青
.note-ftSS - ftSS
.note-ftS - ftS
.note-noMark - noMark

Styleguide 3.5.1
============================================================ */
.note ,
.noteList {
	$this: &;

	// -------- color type
	&-gray {
		color: $gray;
	}
	&-red {
		color: $red;
	}
	&-blue {
		color: $blue;
	}

	// -------- fontSize type
	&-ftSS {
		font-size: 85%;
	}
	&-ftS {
		font-size: 90%;
	}

	// -------- personal type ( 特定指定 )
	// ※マークなし
	&-noMark {
		padding: 0 .2em;
		color: $gray;
		font-size: 90%;
		&::before {
			display: none !important;
		}
		> li {
			&::before {
				display: none !important;
			}
		}
	}
}
.note {
	$this: &;
	position: relative;
	display: inline-block;
	margin-top: 10px;
	padding-left: 1.5em;
	text-indent: -1em;
	line-height: normal;
	&::before {
		content: "※";
		position: relative;
		left: -.5em;
		// display: inline-block;
		// position: relative;
		// text-indent: -1em;
	}
}
.noteList {
	$this: &;
	margin: 10px 0 0;
	list-style-type: none;
	> li {
		position: relative;
		display: block;
		margin: 0;
		padding-left: 1.5em;
		text-indent: -1em;
		line-height: normal;
		color: inherit;
		&::before {
			content: "※";
			position: relative;
			left: -.5em;
			// display: inline-block;
			// position: relative;
			// text-indent: -1em;
		}
	}
}
// @media screen and (max-width: $breakpoint-pc) {
// }
@media screen and (max-width: $breakpoint-sp) {
}

/*
注釈文( 行頭 : 注x )

note2 , noteList2

Markup:
<p class="note2 {$modifiers}"><em>注1</em>注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文</p>
<p class="note2 {$modifiers}"><em>注2</em>注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文</p>
<ol class="noteList2 {$modifiers}">
	<li><em>注1</em>注釈文注釈文注釈文注釈文注釈文</li>
	<li><em>注2</em>注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文注釈文</li>
</ol><!-- /.noteList2 -->

.note2-gray - gray
.note2-red - 赤
.note2-blue - 青
.note2-ftS - ftS
.note2-ftSS - ftSS

Styleguide 3.5.2
============================================================ */
.note2 ,
.noteList2 {
	$this: &;

	// -------- color type
	&-gray {
		color: $gray;
	}
	&-red {
		color: $red;
	}
	&-blue {
		color: $blue;
	}

	// -------- fontSize type
	&-ftS {
		font-size: 90%;
	}
	&-ftSS {
		font-size: 85%;
	}
}
.note2 {
	position: relative;
	padding-left: 2.8em;
	> em {
		position: absolute;
		top: 0;
		left: 0;
		display: inline-block;
		font-style: normal;
	}
}
.noteList2 {
	$this: &;
	margin: 20px 0 0;
	> li {
		position: relative;
		display: block;
		padding-left: 2.8em;
		color: inherit;
		> em {
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			color: inherit;
			font-style: normal;
		}
	}
}
// @media screen and (max-width: $breakpoint-pc) {
// }
@media screen and (max-width: $breakpoint-sp) {
}


@charset "UTF-8";
@import '_config.scss';

/*
	all base set
------------------------------------------------------------------------ */
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

/*
	html/body base style
------------------------------------------------------------------------ */
html {
	font-size: 62.5%;
	&.is-loaded {
		height: 100%;
		// overflow: hidden;
		body {
			height: 0;
			overflow: hidden;
		}
	}
}
body {
	position: relative;
	margin: 0px auto;
	min-width: calc( #{$baseWidth} );
	color: $baseColor;
	@include fs(14);
	font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W6', "Yu Gothic Medium", "Yu Gothic", 游ゴシック, YuGothic, 游ゴシック体, メイリオ, sans-serif;
	// font-weight: 500;
	line-height: normal;
	-webkit-text-size-adjust: 100%;
	word-break: break-all;
	word-wrap: break-word;
	&.is-show {
		opacity: 1 !important;
	}

	// 利用規約ページ
	&.bodyID-terms {
		margin: 0 !important;
		padding: 0 !important;
		min-width: 0;
		border: none;
		background-color: transparent;
		&::before {
			display: none;
		}
	}

	// 印刷ページ
	&.bodyID-print {
		margin: 0 !important;
		padding: 0 !important;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	html {
		// &.is-fixedArea {
		//	overflow-y: hidden;
		//	-ms-touch-action: none;
		//		touch-action: none;
		// }
	}
	body {
		opacity: 1 !important;
		padding-top: #{$fixedHeight};
		min-width: 0;
		-webkit-text-size-adjust: 100%;

		// ハンバーガメニューオープン時の背景処理
		&::after {
			content: "";
			display: block;
			position: fixed;
			bottom: -50%;
			left: 0;
			width: 100%;
			height: 200%;
			background-color: $spOverlayBgColor;
			-webkit-transition: all .2s ease 0s;
			        transition: all .2s ease 0s;
			will-change: auto;
			pointer-events: none;
			opacity: 0;
			z-index: $zi-bodyOverlay;
		}

		// メニュー開閉時
		&.is-menuOpen {
			width: 100%;
			height: 100%;
			&::after {
				opacity: .95;
				pointer-events: inherit;
			}
		}

		// 利用規約ページ
		&.bodyID-terms {
			@include scrollbar02;
		}
	}
}

/*
	各タグ base style
------------------------------------------------------------------------ */
h1 ,
h2 ,
h3 ,
h4 ,
h5 {
	font-weight: normal;
}
sub ,
sup {
	margin-right: 2px;
	font-size: 11px;
}
sub {
	vertical-align: sub;
}
sup {
	top: 3px;
	vertical-align: super;
}
img {
	vertical-align: bottom;
	max-width: 100%;
	max-height: 100%;
	// width: auto;
	height: auto;
}
img[src$=".svg"] {
    width: 100%;
}
hr {
	margin: 20px 0;
}
p:not([class]) {
	line-height: 1.85;
}
p,ul,ol,table,dl,figure {
	margin-top: 20px;
}
b:not([class]) ,
strong:not([class]) {
	font-weight: bold;
}
i:not([class]) ,
em:not([class]) {
	font-style: italic;
	font-style: oblique;
}
b,strong {
	font-weight: bold;
}
i,em {
	font-style: normal;
}
i:not([class]) ,
em:not([class]) {
	font-style: italic;
	font-style: oblique;
}
li {
	display: block;
}
ol {
	li {
		display: list-item;
	}
}
figcaption {
	margin: 5px 0 0;
	font-size: 12px;
}
iframe {
	max-width: 100%;
	// max-height: 100%;
}
area {
	border: none;
	outline: none;
}
blockquote {
	position: relative;
	display: block;
	margin: 20px;
	padding: 25px 50px 25px;
	line-height: 1.8;
	background-color: $gray-bg;
	&::before ,
	&::after {
		content: "";
		display: block;
		position: absolute;
		color: $gray-dot;
	}
	&::before {
		top: 10px;
		left: 10px;
		@include sprite15x($icon_blockquote-left);
	}
	&::after {
		bottom: 10px;
		right: 10px;
		@include sprite15x($icon_blockquote-right);
	}
}
video {
	width: 100%;
	height: 100%;
}

/* table base style
--------------------------------------*/
table {
	font-size: 100%;
}
th,td {
	word-wrap: break-word;
	white-space: normal;
}
th {
	white-space: nowrap;
	font-weight: normal;
}
table ,
table:not([class]) {
	display: table;
	border-collapse: collapse;
	caption {
		padding: 8px;
		color: $baseColor;
		font-weight: bold;
		background-color: $gray-border;
	}
	th,
	td {
		padding: 14px 14px;
		text-align: left;
		vertical-align: top;
		color: inherit;
		border: 1px solid $gray-border;
		@include wordRap;
		p,dl,ul,ol {
			margin-top: 10px;
			&:first-of-type {
				margin-top: 0;
			}
		}
		& > :first-child {
			margin-top: 0;
		}
	}
	th {
	}
	td {
	}
	thead ,
	tfoot ,
	tbody {
		th {
			font-weight: bold;
			background-color: $mainColor04;
			&.head {
			}
		}
		th:empty ,
		td:empty {
			background-color: transparent;
		}
	}
	thead {
		th {
			text-align: center;
			font-weight: bold;
			color: $mainColor01;
		}
		th:empty ,
		td:empty {
			background-color: transparent;
		}
	}
	.cm {
		text-align: center;
		vertical-align: middle;
	}
}

/* dl/dt/dd base style
--------------------------------------*/
// dl {
dl:not([class]) {
	&:first-of-type {
		margin-top: 0;
	}
	> dt {
		position: relative;
		padding-bottom: .3em;
		font-weight: bold;
		border-bottom: 1px solid $gray;
	}
	> dd {
		padding: .5em 0 0 1em;
	}
}

/* ol/ul base style
--------------------------------------*/
ol:not([class]) ,
ul:not([class]) {
	padding-left: 2em;
	> li {
		display: list-item;
		&:not(:first-child) {
			margin-top: 1em;
		}
	}
}

/* リンク関連 base style
--------------------------------------*/
a {
	color: $linkColor;
	cursor: pointer;
	border: none;
	outline: none;
	&:visited {
	}
	&:active,
	&:hover {
		outline-width: 0;
	}
}

/* ぼやけ防止
--------------------------------------*/
a,
img,
input,
svg,
*:before,
*:after {
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}
a:hover img,
a:hover input,
a:hover {
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}
// hover style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	a,
	button {
		-webkit-transition: all .2s;
		        transition: all .2s;
		&:hover {
			filter: alpha(opacity=70); /*IE*/
			-moz-opacity: 0.70; /*Firefox*/
			opacity: 0.7; /*Opera・Safari*/
			img {
				filter: alpha(opacity=80); /*IE*/
				-moz-opacity: 0.80; /*Firefox*/
				opacity: 0.8; /*Opera・Safari*/
				// display: inline-block;
				zoom: 1;
			}
		}
		&:focus {
			outline: none;
		}
	}
	a {
		img {
			-webkit-transition: all .2s;
			        transition: all .2s;
			backface-visibility: hidden;
		}
	}
}
// // for IE Only style
// // --------------------------------------
// @media all and (-ms-high-contrast: none) {
//	img {
//		width: 100%;
//	}
// }
@media screen and (max-width: $breakpoint-sp) {
	blockquote {
		margin: 10px;
		padding: 30px 10px;
		line-height: 1.6;
		&::before {
			top: 5px;
			left: 5px;
			@include sprite2x($icon_blockquote-left);
		}
		&::after {
			bottom: 5px;
			right: 5px;
			@include sprite2x($icon_blockquote-right);
		}
	}
	iframe {
		width: 100%;
		height: auto;
	}
	/* table base style
	--------------------------------------*/
	table ,
	table:not([class]) {
		width: 100% !important;
		th,
		td {
			padding: 10px 5px;
		}
	}

	/* ol/ul base style
	--------------------------------------*/
	ol:not([class]) ,
	ul:not([class]) {
		padding-left: 1.5em;
	}

	/* リンク関連 base style
	--------------------------------------*/
	a {
		&:hover {
			opacity: 1;
		}
		img {
			&:hover {
				opacity: 1;
			}
		}
	}
	a,
	button {
		-webkit-transition: none;
		        transition: none;
		&:hover {
			opacity: 1;
		}
	}
}

/*
	フォーム関連 base style
------------------------------------------------------------------------ */
// UIリセット
input ,
button ,
textarea {
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	outline: none;
}
button {
	border: none;
	cursor: pointer;
	font-family: inherit;
	background-color: transparent;
	-webkit-transition: all .2s;
	        transition: all .2s;
}

// デフォルト設定
#gsc input[type="text"] ,
input ,
select ,
textarea {
	padding: 10px 12px;
	min-width: 1px;
	width: 100%;
	// min-height: 38px;
	vertical-align: middle;
	color: inherit;
	@include fs(14);
	border: 1px solid $gray-border;
	// border-top-color: darken($gray-border, 20%);
	background-color: $white;
	-webkit-border-radius: 3px;
	        border-radius: 3px;
	-webkit-transition: all .5s;
	        transition: all .5s;
}

// disabled
#gsc input[type="text"] ,
input ,
button ,
select ,
textarea {
	&[disabled] {
		color: $gray !important;
		border-color: $gray-border !important;
		background-color: $gray-bg !important;
		&:hover {
			cursor: default;
			background-color: $gray-bg;
		}
		&:focus {
			background-color: $gray-bg;
		}
	}
}
.disabledCmnt {
	color: $gray !important;
	border-color: $gray-border !important;
	background-color: $gray-exLight !important;
}

// focus
input ,
textarea {
	@include placeholderColor($gray-dot);
	&:focus{
		background-color: lighten($gray-bg, 4%);
		@include placeholderColor($gray-border);
	}
}

// file
input[type="file"] {
	// display: none;
}

// number
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance:textfield;
}

// select
select {
	padding-right: 20px;
	vertical-align: middle !important;
	width: auto;
	max-width: 100%;
	font-family: メイリオ, sans-serif; // webフォントは文字化けするため
}

// textarea
textarea {
	padding: 10px;
	width: 100%;
	min-height: 115px;
	line-height: 1.6;
	resize: vertical;
	-webkit-border-radius: 5px;
	        border-radius: 5px;
}

// radio ・ checkbox （IE8対応用）
input {
	&[type="radio"] + span,
	&[type="checkbox"] + span {
		position: relative;
		cursor: pointer;
	}
}
// radio ・ checkbox
@media (min-width: 1px) {
	input {
		&[type="radio"],
		&[type="checkbox"] {
			display: none;
		}
	}
	// radio ・ checkbox デザインver.
	// <label class="customInput"><input type="radio" name="" id="" checked=""><span class="customInput_inner">radio</span></label>
	// <label class="customInput"><input type="checkbox" name="" id=""><span class="customInput_inner">checkbox</span></label>
	.customInput {
		-webkit-transition: all .2s;
		        transition: all .2s;
		> [type="radio"],
		> [type="checkbox"] {
			display: none;
			margin: 0;
			min-height: 0;
		}
		> [type="radio"] + span,
		> [type="checkbox"] + span {
			position: relative;
			display: inline-block;
			min-width: calc( #{$icon-radio-off-width} / 2 );
			min-height: calc( #{$icon-radio-off-height} / 2 );
			padding-left: calc( #{$icon-radio-off-width} / 2  + 5px );
		}
		//　チェックボックス / ラジオボタン
		> [type="radio"] + span::before ,
		> [type="radio"]:checked + span::after ,
		> [type="checkbox"] + span::before ,
		> [type="checkbox"]:checked + span::after {
			content: "";
			display: inline-block;
			position: absolute;
			// top: 1px;
			top: 50%;
			left: 0;
			margin-top: -1px;
			vertical-align: middle;
			-webkit-transform: translateY(-50%);
			        transform: translateY(-50%);
		}
		> [type="radio"] + span::before {
			@include sprite2x($icon_radio-off);
		}
		> [type="radio"]:checked + span::after {
			@include sprite2x($icon_radio-on);
		}
		> [type="checkbox"] + span::before {
			@include sprite2x($icon_checkbox-off);
		}
		> [type="checkbox"]:checked + span::after {
			@include sprite2x($icon_checkbox-on);
		}

		// 「その他」選択時のinputタグの位置調整
		.enquete_otherInput {
			input[type="text"] {
				margin-top: -2px;
			}
		}
	}
}

// for IE Only style
// --------------------------------------
@media all and (-ms-high-contrast: none) {
	// デフォルト設定
	#gsc input[type="text"] ,
	input ,
	select ,
	textarea {
		min-height: 38px;
	}
	select::-ms-expand {
		display: block;
	}
	select {
		padding: 0 0 0 10px;
	}
}
// IE・Edge 両方表示する場合
select::-ms-expand {
	display: block;
}
@media screen and (max-width: $breakpoint-sp) {
	// デフォルト設定
	input ,
	select ,
	textarea {
		font-size: 16px;
	}
	input ,
	select {
		padding: 5px 8px;
		height: 40px;
	}

	// select
	select {
		height: auto;
		height: 40px;
		line-height: 1.2;
		padding: 4px 8px 4px 2px;
	}

	// radio ・ checkbox
	@media (min-width: 1px) {
		// radio ・ checkbox デザインver.
		.customInput {
			> [type="radio"] + span,
			> [type="checkbox"] + span {
				padding-left: calc( #{$icon-radio-off-width} / 2  + 1px );
			}
			//　チェックボックス / ラジオボタン
			> [type="radio"] + span::before ,
			> [type="radio"]:checked + span::after ,
			> [type="checkbox"] + span::before ,
			> [type="checkbox"]:checked + span::after {
				left: -2px;
			}
			// 「その他」選択時のinputタグの位置調整
			.enquete_otherInput {
				input[type="text"] {
					margin-top: 0;
				}
			}
		}
	}
}

/*
	google map関連 base style
------------------------------------------------------------------------ */
.gm-style img {
	max-width: none;
	max-height: none;
}


/*
	【 WYSIWYGエリア 】
	管理画面内のstyle調整
------------------------------------------------------------------------ */
body.mce-content-body {
	margin: 0;
	padding: 10px;
	min-width: 0;
	background-color: $white;
	border: none;

	// TOP罫線
	&::before ,
	&::after {
		display: none;
	}
}
@media screen and (max-width: $breakpoint-sp) {
}

/*margin-top: -1px;
	Style Guide only style
------------------------------------------------------------------------ */
.sgWrapper {
	.sgGNav_list ,
	.sgSection_mods {
		margin-left: 0;
		list-style-type: none;
		> li {
			margin: 0;
		}
	}
}

/*
	@page style
------------------------------------------------------------------------ */
@page {
	// size: A4;
	margin: 10.7mm 5.7mm 8.7mm;
	// padding: 0;
	// margin: 0;
}
@media print {
	body {
		min-width: 0;
		-webkit-print-color-adjust: exact;
	}
}


// @media screen and (min-width: $breakpoint-sp + 1) {
//	body {
//		background: url('http://data.shift-jp.net/kensetsu-data/design13/18.jpg') top 0 center no-repeat;
//		// -webkit-background-size: contain;
//		//      -o-background-size: contain;
//		//         background-size: contain;
//		* {
//			background: none !important;
//			background-color: transparent !important;
//			// background-color: rgba(0,0,0, .1) !important;
//		}
//		img {
//			opacity: .5;
//		}
//	}
// }

